import { Component, OnDestroy } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { SalesforceSsoService } from 'src/app/core/services/http/salesforce-sso.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { ManageIntegrationsModalComponent } from '../../manage-integrations-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ManageIntegrationResModel } from 'src/app/shared/models/intergrations.models';
import { ManageIntegrationsEnum } from 'src/app/shared/enums/integrations.enum';

@Component({
  selector: 'app-salesforce-integration',
  templateUrl: './salesforce-integration.component.html'
})
export class SalesforceIntegrationComponent implements OnDestroy {
  isSalesforceConnected = this.sessionStore.IsSalesforceUser;
  isSubmitting = false;
  closeTimer$ = new Subject<boolean>();

  constructor(
    private sessionStore: SessionStoreService,
    private salesforceSsoService: SalesforceSsoService,
    private dialogRef: MatDialogRef<ManageIntegrationsModalComponent, ManageIntegrationResModel>

  ){}

  ngOnDestroy(): void {
    this.closeTimer$.next();
  }
  deactivateSalesforce(): void {
    this.isSubmitting = true;
    this.salesforceSsoService.removeAuthentication().subscribe(() => {
      this.isSalesforceConnected = false;
      this.sessionStore.setUserSalesforceStatus(this.isSalesforceConnected);
      this.isSubmitting = false;
      this.dialogRef.close({
        isConnected: false,
        crmIntegration: ManageIntegrationsEnum.Salesforce,
      });
    });
  }

  activateSalesforce(): void {
    this.isSubmitting = true;
    window.open('/api/Salesforce/Sso/', '_blank', 'width=800,height=650');
    this.startPolling();
  }

  startPolling(requestInterval = 1000): void {
    timer(0, requestInterval)
      .pipe(
        switchMap(() => this.salesforceSsoService.isConnected()),
        takeUntil(this.closeTimer$)
      )
      .subscribe((res) => {
        this.isSalesforceConnected = res;
        if (this.isSalesforceConnected) {
          this.sessionStore.setUserSalesforceStatus(this.isSalesforceConnected);
          this.isSubmitting = false;
          this.closeTimer$.next();
          this.dialogRef.close({
            isConnected: true,
            crmIntegration: ManageIntegrationsEnum.Salesforce,
          });
        }
      });
  }
}
