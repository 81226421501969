import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SearchApiService {
  constructor(private http: HttpClient) { }

  search(query: string): Observable<SearchResultModelV2[]> {
    return this.http.get<SearchResultModelV2[]>(`/api/Search/Content?query=${query}`);
  }
}

export interface SearchResultModelV2 {
  Id: string
  Title: string,
  Description: string,
  Category: string,
  Tags: string[],
  Url: string,
  RouteType: 'Internal' | 'External' | 'Action',
  Permissions: string[],
  DenyPermissions: string[]
}