<section class="">
  <h2 class="mb-20 text-xl font-bold text-gray-dark">Resources</h2>
  @for (wistiaResourceGroup of wistiaResourceGroups; track wistiaResourceGroup; let index = $index) {
    <div class="mb-20 md:mb-30">
      <h2 class="mb-10 text-lg font-semibold text-gray-dark" [innerHtml]="wistiaResourceGroup.GroupName"></h2>
      <div class="grid flex-grow-0 grid-cols-2 gap-x-20 gap-y-20 md:grid-cols-3 lg:grid-cols-5">
        @for (resource of wistiaResourceGroup.WistiaResources; track resource) {
          <div class="flex flex-col p-20 overflow-hidden transition duration-150 bg-center bg-no-repeat bg-cover cursor-pointer card hover:shadow-lg md:p-30"
            >
            @if (resource.Type !== 'Video') {
              <a class="flex flex-col items-center justify-center w-full h-full"
                [attr.aria-label]="'Open ' + resource.Name"
                href="https://fig.wistia.com/medias/{{resource.HashedId}}"
                >
                <span class="mb-20 mi-36 material-icons text-gray-light">{{
                resource.Type === 'PdfDocument' ? 'picture_as_pdf' : 'description'}}</span>
                <p class="center text-gray-dark" [innerHtml]="resource.Name"></p>
              </a>
            }@else {
              <div class="relative w-full mx-auto mb-20 overflow-hidden rounded shadow wistia_responsive_padding h-100 min-w-128">
                <div class="wistia_responsive_wrapper"
                  style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                  <span id="resourceVideo{{index}}"
                    class="wistia_embed wistia_async_{{resource.HashedId}} popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only"
                  style="display: inline-block; height: 100%; width: 100%;"> </span>
                </div>
              </div>
            }
            @if (resource.Type === 'Video') {
              <p class="mb-0 center text-gray-dark" [innerHtml]="resource.Name"></p>
            }
          </div>
        }
      </div>
    </div>
  }
  <p class="my-20 text-sm disclaimer md:my-30" [innerHtml]="hegnaResult.Description"></p>
</section>