import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenavContent } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { RiaApiService } from 'src/app/core/services/http/ria-api.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { downloadFile } from 'src/app/shared/helpers/filedownloader.helper';
import { hasAll } from 'src/app/shared/helpers/search.helpers';
import { PolicyList, PolicyListTypeEnum, } from 'src/app/shared/models/policy.models';
import { RiaAccount } from './models/riaAccount.model';

@Component({
  selector: 'app-investment-policy-list',
  templateUrl: './investment-policy-list.component.html',
  animations: [fadeIn]
})
export class InvestmentPolicyListComponent implements OnInit, PolicyList {
  @ViewChild('policySidenav') policySidenav!: MatSidenavContent;
  policyListType = PolicyListTypeEnum.Wealth;
  columnName: string[] = ['DateOpened'];
  isError = false;
  searchText: string | null = null;
  isLoading = true;
  allPolicies: RiaAccount[] = [];
  filteredAccounts: RiaAccount[] = [];
  routeTitle?: string;
  exporting = false;

  constructor(
    private riaApi: RiaApiService,
    private snackbar: MatSnackBar,
    private snackbarService: SnackbarService,
    private sessionStoreService: SessionStoreService,
    private activatedRoute: ActivatedRoute
  ) {
    this.routeTitle = this.activatedRoute.snapshot.data['title'] as string | undefined;
  }

  ngOnInit(): void {
    this.getPolicies();
  }

  getPolicies(): void {
    const crd = this.sessionStoreService.OnBehalfOfUser ? this.sessionStoreService.OnBehalfOfUser.AgentLicenses.Crd : this.sessionStoreService.User.AgentLicenses.Crd;
    if (crd) {
      this.riaApi.getAcmAccountsByAgentCRD(crd).subscribe(accounts => {
        this.allPolicies = accounts;
        this.setTable();
      }, (error) => {
        console.log(error);
        this.isError = true;
        this.openSnackbar('Sorry, an error has occurred. Please try again later.', 'is-error');
      });
    }
  }

  setTable(): void {
    this.isLoading = true;
    this.filteredAccounts = this.getFilteredPolicies();
    this.isLoading = false;
  }

  getFilteredPolicies(): RiaAccount[] {
    if (this.searchText) {
      return this.allPolicies.filter(policy => {
        return this.searchTextFilter(policy);
      });
    } else {
      return this.allPolicies;
    }

  }

  searchTextFilter(policy: RiaAccount): boolean {
    if (!this.searchText) return true;
    return hasAll(policy, this.searchText);
  }

  setSearchText(searchText: string | null): void {
    this.searchText = searchText;
    this.setTable();
  }

  export(): void {
    this.exporting = true;
    if (this.filteredAccounts.length === 0) {
      this.openSnackbar('No policies. Please select different filters.', 'is-default');
    }
    this.snackbarService.openSnackbar('Exporting policies, please wait.', ToastClassEnum.default, 0);
    this.riaApi.exportRiaAccounts(this.filteredAccounts).subscribe({
      next: blob => {
        this.snackbarService.closeSnackbar();
        downloadFile(blob, 'PolicyData.xlsx');
      }
    }).add(() => this.exporting = false);
  }

  openSnackbar(message: string, toastClass: string): void {
    this.snackbar.open(message, 'Close', {
      panelClass: [toastClass]
    });
  }

  // Non used interface methods
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateReportType(): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleSideNavMenu(): void {}

  TableSortedEvent(): void {
    // Scrolling back to the top
    if (this.policySidenav) {
      (this.policySidenav.scrollTo({ top: 0 }));
    }
  }
}