import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import orderBy from 'lodash-es/orderBy';
import { MarketingDollarApiService } from 'src/app/core/services/http/marketingdollar-api.service';
import { downloadFile } from 'src/app/shared/helpers/filedownloader.helper';
import { AgentMarketingDollarsDetail } from 'src/app/shared/models/marketingdollar.models';

@Component({
  selector: 'app-marketing-expense-history',
  templateUrl: './marketing-expense-history.component.html',
})
export class MarketingExpenseHistoryComponent {
  exportingList = false;
  columnName = 'UpdatedOn';
  marketingExpenseHistory: AgentMarketingDollarsDetail[] = this.data.marketingExpenseHistory;
  activeSubCategory = 'test';
  reverseOrder = true;
  headers: TableHeader[] = [{ DisplayName: 'Date', PropertyName: 'UpdatedOn' }, { DisplayName: 'Vendor', PropertyName: 'Vendor' }, { DisplayName: 'Note', PropertyName: 'Notes' }, { DisplayName: 'Amount', PropertyName: 'Amount' }];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {marketingExpenseHistory: AgentMarketingDollarsDetail[]},
    private marketingDollarApiService: MarketingDollarApiService,
    private snackBar: MatSnackBar
  ) { }

  orderRows(columnName: string): void {
    this.reverseOrder = (columnName === this.columnName) ? !this.reverseOrder : false;
    this.columnName = columnName;
    this.marketingExpenseHistory = orderBy(this.marketingExpenseHistory, columnName, this.reverseOrder ? ['desc'] : ['asc']) as AgentMarketingDollarsDetail[];
  }

  exportMarketingExpenseHistory(): void {
    if (this.data.marketingExpenseHistory.length <= 0) {
      const message = 'No expenses to export.';
      const toastClass = 'is-default';

      this.snackBar.open(message, 'Close', {
        panelClass: [toastClass]
      });
    } else {
      this.exportingList = true;
      this.marketingDollarApiService.exportMarketingDollars()
        .subscribe(
          res => {
            downloadFile(res, 'MarketingExpenseHistory.xlsx');
          },
          () => {
            const message = 'Sorry, an error has occurred. Please try again later.';
            const toastClass = 'is-error';
            this.snackBar.open(message, 'Close', {
              panelClass: [toastClass]
            });
            this.exportingList = false;
          });
    }
  }
}

interface TableHeader {
  DisplayName: string,
  PropertyName: string
}
