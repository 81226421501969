<section class="flex items-center justify-start flex-layout">
  <div class="relative flex flex-row items-center justify-start m-20 mr-10 sm:flex-grow-0 sm:w-384">
    <i class="absolute transform -translate-y-1/2 fig-24px fig-search top-1/2 left-5 text-gray"></i>
    <input type="text" class="w-full input is-lg pl-36" placeholder="Search..."
           [(ngModel)]="searchText" />
  </div>
  @if (searchText) {
    <button type="button" class="mr-auto icon-button is-primary is-inverted" aria-label="Clear search box"
            (click)="clearSearch()"><i class="material-icons">close</i></button>
  }
</section>
<!--Main Categories-->
<ul class="flex flex-col">
  @for (category of categories; track category; let last = $last) {
    <li class="flex flex-col flex-grow-0" id="category{{category.Order}}" [ngClass]="{'mb-20': !last}">
      <h3 class="pb-10 m-10 text-lg font-bold uppercase border-b heading text-grey-dark border-gray-lighter">
        {{category.Title}}</h3>
      <ul class="flex flex-row flex-wrap">
        <!--Order priority left > right-->
        @for (subCategory of orderBy(category.SubCategories, ['Order']) ; track subCategory) {
          @if (category.SubCategories.length && filterSubcats(subCategory, searchText)) {
            @if (category.Title === 'Market Updates') {
              <li
                  class="flex flex-row w-1/2 p-5 sm:w-1/3 md:w-1/2 lg:w-1/3">
                <!-- wistia embeds -->
                <div class="flex flex-col p-20 rounded bg-blue-950-75">
                  <h3 class="mb-10 font-semibold capitalize heading text-gray-dark">Betashield Strategy by
                    Tony Parish
                  </h3>
                  <div class="mb-20 wistia_responsive_padding"
                      style="padding: 56.25% 0 0 0; position: relative;">
                    <div class="wistia_responsive_wrapper"
                        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                      <span class="flex flex-row"
                            class="wistia_embed wistia_async_8zlvuz7sfw popover=true popoverAnimateThumbnail=true videoFoam=true videoQuality=hd-only w-full h-full"> </span>
                    </div>
                  </div>
                </div>
              </li>
            }
            <li class="flex flex-row w-1/2 sm:w-1/3 md:w-1/2 lg:w-1/3">
              <div class="flex flex-row p-10 m-10 border rounded-lg border-gray-lighter">
                <!-- text & links -->
                @if (subCategory.ContentType === 'text') {
                  <div class="p-20 rounded bg-blue-950-75" class="flex flex-col">
                    <h3 class="mb-10 font-semibold capitalize heading text-gray-dark">
                      {{subCategory.Title}}&nbsp;
                      <!--<span class="uppercase text-green" *ngIf="categoryRecentUpdates.SubCategories.includes(subcat);">-&nbsp;Updated</span>-->
                    </h3>
                    @if (subCategory.Description) {
                      <p class="mb-20 text-sm">{{subCategory.Description}}
                      </p>
                    }
                    <ul class="flex flex-col m-10">
                      <!--Order priority left > right-->
                      @for (link of orderBy(subCategory.Links, ['Featured', 'Order']); track link) {
                        <li class="flex flex-row flex-grow-0">
                          @if (subCategory.Links.length && filterLinks(link, subCategory, searchText)) {
                            <div class="flex flex-row items-center justify-start w-full h-full"
                                [ngClass]="{'mb-10':!last}">
                              <div class="relative flex flex-row items-center justify-center">
                                <i class="fig-24px {{link.IconClass}} mr-5"
                                  [ngClass]="link.Featured ? 'text-orange':'text-gray'"></i>
                                <!-- <span class="absolute top-0 left-0 w-10 h-10 rounded-full bg-green" *ngIf="categoryRecentUpdates.Links.includes(link);">&nbsp;</span> -->
                              </div>
                              <div>
                                <a class="capitalize link"
                                  [ngClass]="link.Featured ? 'mb-20 is-size-4 text-orange':'is-size-5 is-primary-2'"
                                  [href]="link.Url" target="_blank" rel="noopener"
                                  (click)="logAmplitude(link.Title)" [innerHtml]="link.Title"></a>
                                &nbsp;<span class="uppercase text-green"></span>
                              </div>
                            </div>
                          }
                        </li>
                      }
                    </ul>
                  </div>
                }
                <!-- wistia embeds -->
                @if (subCategory.ContentType === 'wistia') {
                  <div class="p-20 rounded bg-blue-950-75" class="flex flex-col" flex>
                    <h3 class="mb-10 font-semibold capitalize heading text-gray-dark">{{subCategory.Title}}
                      @if (subCategory.Date) {
                        <span>on {{subCategory.Date | date:
                          'shortDate'}}</span>
                      }&nbsp;
                      <!--<span class="uppercase text-green" *ngIf="categoryRecentUpdates.SubCategories.includes(subcat);">-&nbsp;Updated</span>-->
                    </h3>
                    @if (subCategory.Description) {
                      <p class="mb-20 text">{{subCategory.Description}}</p>
                    }
                    <div class="mb-20 wistia_responsive_padding"
                        style="padding: 56.25% 0 0 0; position: relative;">
                      <div class="wistia_responsive_wrapper"
                          style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                        <span class="flex flex-row" [ngClass]="subCategory.Url"
                              class="wistia_embed popover=true popoverAnimateThumbnail=true videoFoam=true videoQuality=hd-only w-full h-full"> </span>
                      </div>
                    </div>
                    <ul class="flex flex-col">
                      @for (link of orderBy(subCategory.Links, ['Featured', 'Order']); track link) {
                        <li class="flex flex-row flex-grow-0" [ngClass]="{'mb-10':!last}">
                          <!-- *ngIf="subCategory.Links.length"-->
                          <div class="relative">
                            <i class="fig-24px {{link.IconClass}} mr-5"
                              [ngClass]="link.Featured ? 'text-orange':'text-gray-light'"></i>
                            <!-- <span class="absolute top-0 left-0 w-10 h-10 rounded-full bg-green" *ngIf="categoryRecentUpdates.Links.includes(link);">&nbsp;</span> -->
                          </div>
                          <div>
                            <a class="text-lg capitalize link is-primary-2" [href]="link.Url"
                              target="_blank" rel="noopener"
                              (click)="logAmplitude(link.Title)">{{link.Title}}</a>
                          </div>
                        </li>
                      }
                    </ul>
                  </div>
                }
              </div>
            </li>
          }
        }
      </ul>
    </li>
  }
</ul>