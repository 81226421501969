@if (!hasSubmittedAnyForm && !enrolled) {
  <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openEnrollmentModal()">
    @if (!hasSubmittedAnyForm) {
      <span>Enroll Today</span>
    }
    @else {
      <span  matTooltip="Creative Services enrollment form already submitted" matTooltipPosition="above">Submitted</span>
    }
  </button>
}
@if (resource.AccessPermissions && enrolled) {
  <button type="button" class="mt-20 mr-20 md:mt-30 button is-primary" (click)="onClick()">Access Creative Services Platform</button>
}
@if (pendingRenewal) {
  <div>
    <p class="mt-10 mb-0"><em>Your enrollment in Elevate will expire soon, please click the button below to view your
    renewal options.</em></p>
    <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openSurveyRenewalDialog()">
      <span>
        View My Renewal
      </span>
    </button>
  </div>
}