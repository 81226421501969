import orderBy from 'lodash-es/orderBy';
import { AdditionalResource, ResourcePage } from 'src/app/shared/models/resource-page.models';
import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RESOURCE_PAGE_CONSTANTS } from 'src/app/portal/features/resources/constants/resourcePages.constants';
import { Title } from '@angular/platform-browser';
import { fadeIn } from 'src/app/animations';
import { MatDialog } from '@angular/material/dialog';
import { EnrollmentFormComponent } from 'src/app/portal/modals/enrollment-form/enrollment-form.component';
import { SsoService } from 'src/app/core/services/sso.service';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { OrderModel } from 'src/app/shared/models/order.models';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { EnrollmentFormApiService } from 'src/app/core/services/http/enrollment-form-api.service';
import { User, UserDelegate } from 'src/app/shared/models/user.models';
import { UserApiService } from 'src/app/core/services/http/user-api.service';

@Component({
  selector: 'app-resource-details',
  templateUrl: './resource-details.component.html',
  animations: [fadeIn]
})
export class ResourceDetailsComponent implements OnInit, AfterViewInit {
  categoryId = '';
  filteredResources: AdditionalResource[] = [];
  hideEnrollment = false;
  loading = false;
  user!: User;
  onBehalfOfUser!: User | null;
  delegates: UserDelegate[] = [];
  delegateId!: string | null;
  orderBy = orderBy;
  resource?: ResourcePage;
  resourceUrl = this.activatedRoute.snapshot.paramMap.get('resource') || '';
  resourceLower = this.resourceUrl.toLowerCase();
  resourcePath?: string;
  searchText = '';
  showAdditional = false;
  showFilteredList = false;
  submitted?: { surveyId: number, isEnrolled: boolean }[] = [];
  purchased?: OrderModel | null;
  @ViewChild('dashboardContainerContent')
  private dashboardContentDiv!: ElementRef<HTMLElement>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private enrollmentFormApiService: EnrollmentFormApiService,
    private permissionService: PermissionService,
    @Inject(RESOURCE_PAGE_CONSTANTS) private resourcePages: ResourcePage[],
    private router: Router,
    private sessionStore: SessionStoreService,
    private userApiService: UserApiService,
    private ssoService: SsoService,
    private titleService: Title,) {
  }

  ngOnInit(): void {
    this.showAdditional = this.showAdditionalResources();
    this.configureUserInfo();
    this.getResourceData();

  }

  ngAfterViewInit(): void {
    this.activatedRoute.params
      .subscribe(params => {
        const resourceUrl = params['resource'] as string;
        const currentUrl = this.router.url;
        if (this.resourceUrl != resourceUrl)
          // Refresh component without refreshing entire browser https://stackoverflow.com/questions/59044612/angular-refresh-component
          // Doing this instead of calling ngOnInit() because Wistia resources would not respond to change detection correctly
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(currentUrl);
          });
      });

    this.activatedRoute.fragment.subscribe({
      next: (fragment: string | null) => {
        if (fragment) this.categoryId = fragment;
      }
    });
  }

  clearSearch(): void {
    this.searchText = '';
    this.showFilteredList = false;
  }

  getResourceData(): void {
    this.resource = this.resourcePages.find(page => page.Url.toLowerCase() === this.resourceUrl?.toLowerCase()) || this.resourcePages[0];
    if (this.resource === this.resourcePages[0]) this.router.navigateByUrl('/Portal/404');
    this.hideEnrollment = this.resource.AccessPermissions
      ? this.permissionService.hasOnePermission(this.resource.AccessPermissions)
      : false;
    this.titleService.setTitle(this.resourceUrl + ' | Agent Portal');

    // redirect to platforms if view permission exists, and user doesn't have permission
    if (!!this.resource.ViewPermissions && !this.permissionService.hasOnePermission(this.resource.ViewPermissions)) window.location.href = '/Portal/Explore/';

    if (this.resource.Url.toLowerCase() === 'Cannex'.toLowerCase()) {
      this.redirectToCannex();
    }
    if (this.resource.Url.toLowerCase() === 'AnnuityRateWatch'.toLowerCase()) {
      this.redirectToAnnuityRateWatch();
    }

    this.resource.EnrollmentSurveyData?.SurveyIds.forEach(surveyId => {
      this.enrollmentFormApiService.isEnrolled(surveyId).subscribe(isEnrolled => {
        if (this.submitted) {
          this.submitted.push({ surveyId: surveyId, isEnrolled: isEnrolled });
        }
      });
    });
    this.submitted = orderBy(this.submitted, ['SurveyId']) as { surveyId: number, isEnrolled: boolean }[];
    // added to fix scroll to top issue
    if (this.dashboardContentDiv) (this.dashboardContentDiv.nativeElement as HTMLElement).scrollTop = 0;
  }

  configureUserInfo(): void {
    this.user = this.sessionStore.User;
    this.onBehalfOfUser = this.sessionStore.OnBehalfOfUser;
    this.userApiService.getUsersForDelegateUser(this.user.Id).subscribe(res => {
      this.delegates = res;
      this.delegateId = this.permissionService.getUserDelegateId();

      if (this.delegateId !== null && this.onBehalfOfUser === null) {
        const delegate = this.delegates.find(d => d.Id === this.delegateId);
        this.onBehalfOfUser = delegate === undefined ? null : delegate.OnBehalfOfUser;
      }
    });
  }

  openEnrollmentModal(surveyId: number): void {
    const dialogRef = this.dialog.open(EnrollmentFormComponent, {
      data: {
        Event: this.resource?.EnrollmentSurveyData?.Event,
        SurveyId: surveyId,
        Disclaimer: this.resource?.EnrollmentSurveyData?.Disclaimer
      }
    });

    dialogRef.afterClosed().subscribe((res: { hasCompletedEnrollment: boolean; }) => {
      const survey = this.submitted?.find(item => item.surveyId === surveyId);
      if (survey && res.hasCompletedEnrollment) survey.isEnrolled = true;
    });
  }

  openExternalInterestForm(): void {
    window.open(this.resource?.ExternalInterestFormUrl);
  }

  openLearnMore(url: string): void {
    window.open(url, '_blank');
  }

  redirectToAnnuityRateWatch(): void {
    this.ssoService.annuityRateWatchSso();
  }

  redirectToCannex(): void {
    this.ssoService.cannexSso();
  }

  redirectToIcomply(): void {
    this.ssoService.iComplySso();
  }

  scrollTo(id: string): void {
    const el = document.getElementById(id);
    el?.scrollIntoView({ behavior: 'smooth' });
  }

  showAdditionalResources(): boolean {
    if (this.resource?.AccessPermissions) {
      return this.permissionService.hasOnePermission(this.resource.AccessPermissions);
    }
    return true;
  }

  redirectToMarketplaceOne(): void {
    this.ssoService.marketPlaceOneSsoV2();
  }
}
