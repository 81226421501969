import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgreementsApiService } from 'src/app/core/services/http/agreements-api.service';

@Component({
  selector: 'app-signature-complete',
  templateUrl: './signature-complete.component.html',
})
export class SignatureCompleteComponent implements OnInit {
  title = '';
  error: string | undefined;

  constructor(private activatedRoute: ActivatedRoute, private agreementsApiService: AgreementsApiService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.title = params['title'] as string;
      this.error = params['event'];
      const envelopeId = params['envelopeId'];
      const isAccepted = params['accepted'];
      this.agreementsApiService.docusignEnvelopeAcceptResponse(envelopeId, isAccepted).subscribe();

      if (this.error)
        console.log(this.error);
    });
  }
}
