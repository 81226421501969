@if (resource) {
  @for (survey of submitted; track survey; let index = $index) {
    <div class="flex flex-row">
      <button type="button" class="mt-20 mr-20 button is-primary md:mt-30"
        (click)="survey && !survey.isEnrolled ? openEnrollmentModal(survey.surveyId) : ''" [disabled]="survey.isEnrolled">
        @if (survey && !survey.isEnrolled) {
          <span>
            Enroll Today
          </span>
        }
        @else if (survey && survey.isEnrolled) {
          <span matTooltip="Surge enrollment form already submitted" matTooltipPosition="above"
          >Submitted</span>
        }
      </button>
      @if (survey && survey.isEnrolled && resource.PurchasePageData) {
        <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openPurchaseDialog()"
          [disabled]="!!purchased && purchased.Complete">
          @if (!purchased) {
            <span>
              Purchase
            </span>
          }
          @else if (purchased && purchased.Complete) {
            <span>
              Purchased On {{purchased.PurchaseDate}}
            </span>
          }
        </button>
      }
      @if (purchased && purchased.Complete && purchased.DocusignUrl) {
        <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openDocusignLink(purchased.DocusignUrl)">
          <span>
            Sign Surge Agreement
          </span>
        </button>
      }
      @if (capstone.DocusignUrl) {
        <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openDocusignLink(capstone.DocusignUrl)"
          *showIfOne="'Capstone:access'">
          <span>
            Sign Capstone Agreement
          </span>
        </button>
      }
    </div>
  }
}