import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { Policy, PolicyDetail, PolicyFunding, PolicyStage, PolicySuitability, PolicyTask } from 'src/app/shared/models/policy.models';
import orderBy from 'lodash-es/orderBy';
import { policyStages } from 'src/app/shared/constants/policyList.constants';
import { environment } from 'src/environments/environment';
import { PastPolicyListState } from 'src/app/shared/models/pastPolicyListState.model';
import { fadeIn } from 'src/app/animations';
import { BusinessRouteParamReportTypeEnum } from '../../enums/policy-v2.enums';
import { LineOfBusinessId } from 'src/app/shared/enums/line-business-id.enum';

@Component({
  animations: [fadeIn],
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
})
export class PolicyDetailsComponent implements OnInit {
  toggleLocal = false;
  agentResponseForm: any;
  agentResponses: any[] = [];
  caseStatus!: PolicyTask;
  componentUrl = '';
  environment = environment;
  filesToUpload: File[] = [];
  isUploading = false;
  isVariable = false;
  latestNote: any = {};
  loading = false;
  orderBy = orderBy;
  policy!: Policy;
  policyDetails!: PolicyDetail;
  policyFundingTotal: FundingModel = { FundingAmountTotal: 0, FundingFullyIssued: false, FundingIssuedTotal: 0, FundingTotal: 0 };
  policyFunding!: PolicyFunding[];
  policyGuid!: string;
  policySuitability!: PolicySuitability;
  resourceUrl = 'Case/Policy Details';
  selectedTab = 'PolicyDetails';
  showAgentResponses = false;
  today: Date = new Date();
  pastPolicyListState!: PastPolicyListState | null;
  currentPolicyStage!: string | null;
  currentPolicyStageDate!: Date | null;
  lobId = LineOfBusinessId;
  /** We can prolly use this to determine which UI to show in the future when this component handles more than just Life/LTC stuff */
  reportType?: BusinessRouteParamReportTypeEnum | null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportsApiService: ReportsApiService,
    private router: Router,
  ) {
    this.pastPolicyListState = this.router.getCurrentNavigation()?.extras?.state?.PastPolicyListState;
  }

  ngOnInit(): void {
    this.getParams();
  }

  getParams(): void {
    this.activatedRoute.paramMap.subscribe({
      next: params => {
        this.reportType = params.get('reportType') as BusinessRouteParamReportTypeEnum;
      }
    });
    this.activatedRoute.queryParamMap
      .subscribe({
        next: () => {
          this.policyGuid = this.activatedRoute.snapshot.paramMap.get('policyGuid');
          this.getPolicyDetails();
          this.componentUrl = this.getComponent('/Portal/Business/');
        }
      });
  }

  filterStageById(stageId: number): PolicyStage | null {
    const stage = this.policyDetails.StageHistory.filter(row => row.PolicyStageId === stageId);
    if (stage.length > 0) return stage[0];
    return null;
  }

  getComponent(homeUrl: string): string {
    const path: string[] = this.router.url.split(homeUrl);
    return path[1];
  }

  getPolicyDetails(): void {
    if (this.policyGuid) {
      this.loading = true;
      this.reportsApiService.getIsVariablePolicy(this.policyGuid)
        .subscribe({
          next: isVariable => {
            this.isVariable = isVariable;
            let policyDetailsHttp: Observable<PolicyDetail>;
            if (isVariable) policyDetailsHttp = this.reportsApiService.getVariablePolicyDetailsByGuid(this.policyGuid);
            else policyDetailsHttp = this.reportsApiService.getPolicyDetailsByGuid(this.policyGuid);

            forkJoin({ policyDetails: policyDetailsHttp, fundings: this.reportsApiService.getPolicyFundingByGuid(this.policyGuid), suitability: this.reportsApiService.getPolicySuitabilityStatusByGuid(this.policyGuid) })
              .subscribe({
                next: (res) => {
                  this.redirectToCorrectDetailsView(res.policyDetails, this.reportType);
                  this.policyDetails = res.policyDetails;
                  this.currentPolicyStage = this.policyDetails.StageHistory.length > 0 ? this.policyDetails.StageHistory[0].PolicyStage : null;
                  this.currentPolicyStageDate = this.policyDetails.StageHistory.length > 0 ? this.policyDetails.StageHistory[0].PolicyStageDate : null;
                  this.policyDetails.StageHistoryObject = this.getStageHistory();
                  this.policyFunding = res.fundings;
                  this.policySuitability = res.suitability;
                  this.policyFunding.forEach(funding => {
                    funding.IssuedPremiumAmount = funding.IssuedPremiumAmount ? funding.IssuedPremiumAmount : 0;
                    funding.IsFullyIssued = funding.Amount ? false : true;
                  });
                  this.loading = false;
                },
                error: () => {
                  this.loading = false;
                }
              });
          }
        });
    } else this.loading = false;
  }

  getStageHistory(): (PolicyStage | null)[] {
    const stages: (PolicyStage | null)[] = [];
    policyStages.forEach(stage => {
      stages.push(this.filterStageById(stage));
    });

    return stages;
  }

  /**
   * Disable on production so users don't get redirected to new view
   */
  redirectToCorrectDetailsView(policy?: PolicyDetail, reportType?: BusinessRouteParamReportTypeEnum | null): void {
    if (reportType && policy && !environment.production) {
      if (!policy?.HasChecklistItem) this.router.navigate(['/Portal/Business/' + reportType + '/PolicyDetails/' + this.policyGuid]);
      else this.router.navigate(['/Portal/Business/' + reportType + '/PolicyDetailsV2/' + this.policyGuid]);
    }
  }

  goBack(): void {
    window.history.back();
  }

  goBack2(): void {
    if (!this.componentUrl) window.history.back();
    else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['Portal/Business/' + this.componentUrl] ));
    }
  }
}

interface FundingModel {
  FundingAmountTotal: number | null,
  FundingIssuedTotal: number | null,
  FundingTotal: number | null,
  FundingFullyIssued: boolean | null
}
