import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export interface SearchFormGroup extends UntypedFormGroup {
  controls: {
    searchText: UntypedFormControl;
  };
  value: {
    searchText: string;
  }
}
export enum ToggleExploreViews {
  gridView,
  listView
}

export interface ExploreTile {
  CreatedTime: Date;
  Fields: ExploreTileFields;
  Id: string;
}


export interface ExploreTileFields {
  AccessPermission: string;
  Active: boolean;
  AngularJSRoute: string;
  AngularRoute: string;
  BackgroundColor: string;
  CapitalTitle?: string;
  CategoriesAPM: string;
  CategoriesLookup: string[];
  CategoryDescription: string;
  CategoryDescriptionLookUp: string[];
  CategoryName: string;
  CategoryNameArray: string[];
  CategoryOrder: string;
  CategoryOrderArray: number[];
  CoverImgUrl: string;
  Description: string;
  ExternalUrl: string;
  ForegroundColor: string;
  ForegroundColorArray: string[];
  Icon: string;
  IconArray: string[];
  Logo: string;
  LogoUrl: string;
  Size: string;
  Subtitle: string;
  Tags: string[];
  TileOrder: number;
  Title: string;
  Type: string;
  ViewPermission: string;
  DenyPermission: string;
}

export interface ExploreCategory {
  CreatedTime: Date;
  Fields: ExploreCategoryFields;
  Id: string;
}

export interface ExploreCategoryFields {
  Active: boolean;
  BackgroundColor: string;
  BackgroundColorArray: string[];
  BackgroundUrl: string;
  CategoryStylingLookup: string[];
  Class: string;
  ClassArray: string[];
  Description: string;
  ForegroundColor: string;
  ForegroundColorArray: string[];
  Icon: string;
  IconArray: string[];
  Name: string;
  Order: number;
  Tags: string[];
  Tiles: string[];
  ExploreV2Tiles?: ExploreTile[];
  TilesLookup: string[];
}

export interface ExploreSortOption {
  propName: string;
  propValue: string[];
}

export interface ExploreCategoryFilter {
  displayName: string;
  checked: boolean;
  foregroundColor: string;
}