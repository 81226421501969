<section class="w-full h-full mr-auto max-w-1280" @fadeIn>
    <section class="flex flex-row flex-wrap flex-grow h-full overflow-hidden text-white bg-center bg-no-repeat bg-cover card"><!--[class]="!getResourceUrl() ? 'md:col-span-3': 'md:col-span-3'"-->
        <div
            class="flex flex-row items-center justify-start w-full p-10 border-b border-solid sm:p-20 lg:p-30 border-gray-lighter">
            <div class="flex flex-col text-gray-dark">
                <span class="mb-5 text-xs tracking-wide uppercase label">Introducing</span>
                <h2 class="mb-5 text-lg font-bold leading-none">Medicare Planning</h2>
                <p class="text-base">Diversify Your Business and Retain Clients by Including Medicare&nbsp;Planning.</p>
            </div>
            <div class="ml-auto">
                <a matButton
                    class="flex flex-row items-center justify-center p-10 px-20 py-5 mr-20 border button is-primary is-inverted border-blue-dark"
                    href="https://www.figmarketing.com/files/resources/Medicare_FIG_Brochure.pdf" target=_blank>
                    <i class="pr-10 material-icons-outlined">menu_book</i><span class="mr-5 text-sm">Learn More</span>
                </a>
            </div>
        </div>
        <div class="flex flex-col justify-start w-full p-10 text-gray-dark sm:p-20 lg:p-30">
            <p>
              FIG is proud to offer full-service Medicare insurance support and access to national and regional Medicare Advantage, Medigap, and Medicare Prescription Plans for your&nbsp;clients.
            </p>
            <p class="pb-5 sm:pb-10 lg:pb-15">
                Learn more here: <a href="https://www.figmarketing.com/files/resources/Medicare_FIG_Brochure.pdf" target="_blank">Medicare Planning PDF</a>
            </p>
            <p class="pb-10 sm:pb-20 lg:pb-30">
                Two ways to access the FIG Medicare Planning team:
            </p>
            <div class="mx-auto md:w-2/3">
                <div class="flex flex-row mb-15">
                    <span
                        class="flex flex-row items-center justify-center w-32 h-32 mr-10 -mt-5 rounded-full bg-gray-dark">
                        <span class="font-bold leading-none text-white">1</span>
                    </span>
                    <p class="text-base font-bold">Add Medicare Products to your Portfolio as the agent of&nbsp;record. </p>
                </div>
                <ul class="mb-20 ml-20 list-disc list-inside sm:mb-30 sm:ml-40">
                    <li class="mb-10">The planning team will help you select best-of-breed Medicare products for your market
                        and&nbsp;clientele.</li>
                    <li>Access to proprietary Medicare quote engine.</li>
                </ul>
                <div class="flex flex-row mb-15">
                    <span class="flex flex-row items-center justify-center w-32 h-32 mr-10 -mt-5 rounded-full bg-gray-dark">
                        <span class="font-bold leading-none text-white">2</span>
                    </span>
                    <p class="font-bold">Refer your clients directly to FIG's Medicare&nbsp;Planning.</p>
                </div>
                <ul class="mb-20 ml-20 list-disc list-inside sm:mb-30 sm:ml-40">
                    <li class="mb-10">
                        Specialize in assisting customers who are approaching Medicare eligibility&nbsp;with:
                        <ul class="mt-10 mb-20 ml-20 list-disc list-inside sm:ml-40">
                            <li class="mb-10">Medicare eligibility&nbsp;process.</li>
                            <li class="mb-10">Medicare&nbsp;benefits. </li>
                            <li class="mb-10">Insurance types and&nbsp;options. </li>
                            <li class="mb-10">Enrollment in the Medicare plan of their&nbsp;choice. </li>
                        </ul>
                    </li>
                    <li class="mb-10">Annual Medicare insurance check-up to review coverage changes for the coming
                        plan&nbsp;year. </li>
                    <li class="mb-10">Full-service broker at no cost to your&nbsp;clients. </li>
                    <li>Provide a needed service to your clients without the requirement of Medicare plan
                        contracting and annual&nbsp;certifications. </li>
                </ul>
            </div>
            <p class="pb-10 sm:pb-20 lg:pb-30">
              To connect with an FIG Medicare Planning team member today please call <a class="text-blue hover:text-blue-dark" target="_blank" rel="noopener" href="tel:855-852-2982" aria-label="Call FIG Medicare Planning">855-852-2982</a> or by email at <a class="text-blue hover:text-blue-dark" target="_blank" href="mailto:FIGmarketing@medicarecompareusa.com" aria-label="Email FIGmarketing@medicarecompareusa.com" rel="noopener">FIGmarketing&#64;medicarecompareusa.com</a>.
            </p>
        </div>
    </section>
</section>
