<nav class="flex h-full text-xs font-bold uppercase md:text-sm text-gray-dark dark:text-gray-light xl:gap-x-10">
  <!-- Main nav -->
  @for (item1 of nav; track item1) {
    <!-- Filter Nav by permissions -->
    @if (item1.visible()) {
      <div class="relative flex flex-col cursor-pointer group">
        @switch (item1.type) {
          <!-- Action link -->
          @case (routeType.action) {
            <button
              type="button"
              class="flex items-center h-full px-10 uppercase transition-colors duration-150 lg:px-15 hover:text-blue text-start"
              (click)="item1.action()"
              routerLinkActive="text-blue-dark dark:text-blue-light"
              [amplitudeEvent]="item1.name" [amplitudeEventOptions]="amplitudeEventOptions">
              <span>{{item1.name}}</span>
              @if (item1.children || item1.children) {
                <i class="ml-5 material-icons mi-18 text-gray">arrow_drop_down</i>
              }
            </button>
          }
          <!-- External link -->
          @case (routeType.external) {
            <a
              class="flex items-center h-full transition-colors duration-150 px-15 hover:text-blue"
              target="_blank"
              [href]="item1.path"
              [amplitudeEvent]="item1.name" [amplitudeEventOptions]="amplitudeEventOptions">
              <span>{{item1.name}}</span>
              @if (item1.children || item1.children) {
                <i class="ml-5 material-icons mi-18 text-gray">arrow_drop_down</i>
              }
            </a>
          }
          <!-- Internal/default link -->
          @default {
            <a
              class="flex items-center h-full transition-colors duration-150 px-15 hover:text-blue dark:hover:text-blue-light"
              [routerLink]="item1.path"
              routerLinkActive="text-blue-dark dark:text-blue-light"
              [amplitudeEvent]="item1.name" [amplitudeEventOptions]="amplitudeEventOptions">
              <span>{{item1.name}}</span>
              @if (item1.children || item1.children) {
                <i class="ml-5 material-icons mi-18 text-gray">arrow_drop_down</i>
              }
            </a>
          }
        }
        @if (item1.children) {
          <div class="absolute bottom-0 left-0 z-20 flex flex-col invisible py-10 text-xs transition duration-150 translate-y-full bg-white border-t rounded-b shadow-lg dark:bg-gray-darkest dark:text-gray-light group-hover:visible border-gray-lighter dark:border-gray-darker min-w-200">
            <!-- Sub-nav -->
            @for (item2 of item1.children; track item2) {
              <!-- Filter Sub-nav by permissions -->
              @if (item2.visible()) {
                <div [amplitudeEvent]="item2.name" [amplitudeEventOptions]="amplitudeEventOptions" class="flex flex-col">
                  @switch (item2.type) {
                    <!-- Action link -->
                    @case (routeType.action) {
                      <button type="button"
                        class="px-20 py-10 uppercase transition-colors duration-150 hover:text-blue text-start"
                        (click)="item2.action()">
                        <span>{{item2.name}}</span>
                      </button>
                    }
                    <!-- External link -->
                    @case (routeType.external) {
                      <a
                        class="px-20 py-10 transition-colors duration-150 hover:text-blue"
                        target="_blank"
                        [href]="item2.path">
                        <span>{{item2.name}}</span>
                      </a>
                    }
                    <!-- Internal/default link -->
                    @default {
                      <a
                        class="px-20 py-10 transition-colors duration-150 hover:text-blue"
                        [routerLink]="item1.path + '/' + item2.path"
                        routerLinkActive="text-blue-dark">
                        <span>{{item2.name}}</span>
                      </a>
                    }
                  }
                </div>
              }
            }
          </div>
        }
      </div>
    }
  }
</nav>