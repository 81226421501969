import { NgModule } from '@angular/core';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { GlobalSearchV2Component } from './components/global-search/global-search-v2.component';
import { SearchResultV2Component } from './components/search-result/search-result-v2.component';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [
    GlobalSearchComponent,
    GlobalSearchV2Component,
    SearchResultComponent,
    SearchResultV2Component
  ],
  imports: [
    SharedModule,
    CoreModule
  ],
  exports: [
    GlobalSearchComponent,
    GlobalSearchV2Component,
  ]
})
export class SearchModule { }
