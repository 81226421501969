@if (config) {
  <section class="flex flex-col w-full h-full overflow-hidden">
    <section class="flex items-center flex-none p-20 border-b gap-x-20 border-gray-lighter"
             [ngClass]="{
               'bg-orange-lightest': config.ActionNeeded && config.MainTaskStatusId !== taskStatusId.Complete
              }">
      <h3 class="text-lg font-bold" [ngClass]="{'text-orange': config.ActionNeeded}">{{config.Title}}</h3>
      <button class="ml-auto icon-button is-primary is-inverted"
        matTooltip="Close Dialog"
        mat-button
        type="button"
        (click)="closeDialog()">
        <i class="material-icons">close</i>
      </button>
    </section>
    <app-agent-message-input (formSubmitEvent)="updateThreadUI($event)"
      [policyTaskId]="config.MainTaskId"
      [policyGuid]="config.PolicyGuid"
    class="z-10 flex-none shadow"></app-agent-message-input>
    <section class="flex flex-col flex-grow py-20 overflow-auto border-t shadow-inner px-30 border-gray-lighter">
      <ul class="flex flex-col gap-y-10">
        @for (note of noteThread; track note) {
          <li
            class="flex gap-x-10">
          <span [ngClass]="{
            'bg-gray-lightest' : note.IsExternalUser,
            'bg-blue-lightest' : !note.IsExternalUser
          }"
              class="flex items-center justify-center flex-none w-48 h-48 my-10 text-sm font-bold rounded-full">
              @if (note.IsExternalUser) {
                <span>ME</span>
              }
              @else {
                <span>FIG</span>
              }
            </span>
            <div [matTooltip]="note.ReceivedByOperations ? receivedTooltip : ''"
               [ngClass]="{
            'bg-gray-lightest' : note.IsExternalUser,
            'bg-blue-lightest' : !note.IsExternalUser
          }"
              class="flex flex-grow p-10 rounded gap-x-20 min-h-58 gap-y-10">
              <div class="flex flex-col flex-grow my-auto gap-y-10">
                <p class="my-auto mr-20 break-words whitespace-pre-line">
                  {{note.Note}}
                </p>
                @if (note.Files.length) {
                  <ul class="flex flex-wrap gap-y-5 gap-x-5">
                    @for (file of note.Files; track file) {
                      <li
                        class="flex">
                        <a [matTooltip]="file.FileName" mat-button [href]="'/api/Policies/' + config.PolicyGuid + '/Files/' + file.Id"
                          target="_blank" class="flex-grow w-full px-5 overflow-hidden text-left normal-case transition duration-150 whitespace-nowrap button is-sm text-blue-dark gap-x-10 hover:bg-blue-lightest" type="button">
                          <span class="flex items-center gap-x-5">
                            <i class="material-icons mi-18">description</i>
                            <span class="overflow-hidden overflow-ellipsis">{{file.FileName}}</span>
                          </span>
                        </a>
                      </li>
                    }
                  </ul>
                }
              </div>
              <div class="flex flex-col flex-none my-10 text-sm text-right text-gray">
                <span>{{note.ModifiedOn | date: 'MM/dd/yy'}}</span>
                <span>{{note.ModifiedOn | date: 'h:mm a'}}</span>
                @if (note.ReceivedByOperations) {
                  <span
                    class="flex items-center mt-5 -mb-10 gap-x-5 text-green">
                    <span>Received</span>
                    <i class="material-icons mi-18">done</i>
                  </span>
                }
              </div>
            </div>
          </li>
        }
        @if (!noteThread.length) {
          <li class="text-gray">No responses to this status update yet.</li>
        }
      </ul>
    </section>
  </section>
}