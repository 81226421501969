import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import orderBy from 'lodash-es/orderBy';
import { fadeIn } from 'src/app/animations';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { MessagePanelConfigModel } from 'src/app/shared/components/message-notification/message-notification.component';
import { LodashSortOrder } from 'src/app/shared/constants/lodash-sort-order.constant';
import { ErrorMessageModel } from 'src/app/shared/models/error-message.model';
import { Note, PolicyTask, PolicyTaskV2 } from 'src/app/shared/models/policy.models';
import { TaskCategories } from '../../constants/policy-v2.constants';
import { TaskStatusEnum } from '../../enums/policy-v2.enums';
import { NewAgentMessageComponent } from '../../modals/new-agent-message/new-agent-message.component';
import { UpdateHistoryConfigModel } from '../../modals/updates-history/models/updates-history-config.model';
import { UpdatesHistoryComponent } from '../../modals/updates-history/updates-history.component';
import { AgentMessageFormValue, PolicyUpdatesV2, TaskNoteV2 } from '../../models/policy-v2.models';

@Component({
  animations: [fadeIn],
  selector: 'app-policy-requirements-v2',
  templateUrl: './policy-requirements-v2.component.html',
})
export class PolicyRequirementsV2Component implements OnInit {
  @Input() policyTasks?: PolicyTask[];
  /** `null | undefined` is caused by route params type definition */
  @Input() policyGuid?: string | null;
  /** Show/hide certain UI elements when viewed in modal vs. inline */
  @Input() modalView = false;
  error: ErrorMessageModel = {
    hasError: false,
    message: 'Sorry, an error has occurred. Please refresh the page again, or contact our support if the issue persists.'
  };
  messageConfig: MessagePanelConfigModel = {
    type: 'error'
  };
  actionNeededTasks: PolicyTaskV2[] = [];
  showCompleted = false;
  policyUpdates: PolicyUpdatesV2[] = [];
  filteredPolicyUpdates: PolicyUpdatesV2[] = [];
  taskCategories = TaskCategories;
  taskStatusId = TaskStatusEnum;
  loading = false;
  private categoryOrderStr = 'categoryOrder';
  private ModifiedOnStr = 'ModifiedOn';

  constructor(
    private reportsApiService: ReportsApiService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getPolicyUpdatesModel();
  }

  /**
   * Get policy updates from either `@Input() policyTasks: PolicyTask[]` from details page, or from backend from list view via `@Input() policyGuid: string`
   */
  getPolicyUpdatesModel(): void {
    if (this.policyTasks) {
      this.createPolicyUpdatesV2(this.policyTasks, false);
    } else if (this.policyGuid) {
      this.getPolicyUpdates();
    } else {
      this.error.hasError = true;
    }
  }

  toggleCompletedTasks(): void {
    if (this.policyTasks) this.createPolicyUpdatesV2(this.policyTasks, this.showCompleted);
  }

  /**
   * Generate UI update list from @param policyTasks
   * @param policyTasks Raw policyTasks list from backend model
   * @param showCompleted Where or not to filter out non-completed items, including Action Needed. Should default to false
   */
  createPolicyUpdatesV2(policyTasks: PolicyTask[], showCompleted: boolean): void {
    this.policyUpdates = [];
    this.filteredPolicyUpdates = [];

    // Modify PolicyTask array to include LatestCaseNote from FIG
    const modifiedSubcats: PolicyTaskV2[] = policyTasks.map(task => {
      const internalNotes = orderBy(task.Notes.filter(note => !note.IsExternalUser), this.ModifiedOnStr, LodashSortOrder.desc) as Note[];
      return {
        ...task,
        LatestCaseNote: internalNotes[0],
      };
    }) as PolicyTaskV2[];
    // By default, create a separate "Action Needed" category to prioritize their visibility
    if (!showCompleted) {
      this.actionNeededTasks = orderBy(modifiedSubcats.filter(task => task.ActionNeeded), this.ModifiedOnStr, LodashSortOrder.desc) as PolicyTaskV2[];
    } else {
      this.actionNeededTasks = [];
    }

    // Filter the remaining categories based on `showCompleted` is true or false
    this.taskCategories.forEach(cat => {

      // Modify PolicyTask array to include LatestCaseNote from FIG
      let filteredSubcats: PolicyTaskV2[] = modifiedSubcats.filter(task => (task.TaskCategoryID === cat.TaskCategoryID) && !task.ActionNeeded)
        .map(task => {
          const latestCaseNote = orderBy(task.Notes.filter(note => !note.IsExternalUser), this.ModifiedOnStr, LodashSortOrder.desc) as TaskNoteV2[];
          return {
            ...task,
            LatestCaseNote: latestCaseNote[0],
          };
        }) as PolicyTaskV2[];
      if (!showCompleted) {
        filteredSubcats = filteredSubcats?.filter(task => task.TaskStatus?.ID !== this.taskStatusId.Complete);
      } else {
        filteredSubcats = filteredSubcats?.filter(task => task.TaskStatus?.ID === this.taskStatusId.Complete);
      }
      filteredSubcats = orderBy(filteredSubcats, this.ModifiedOnStr, LodashSortOrder.desc) as PolicyTaskV2[];

      const policyUpdate: PolicyUpdatesV2 = {
        categoryName: cat.TaskCategory,
        categoryTaskID: cat.TaskCategoryID,
        categoryOrder: cat.DisplayOrder,
        subcategories: filteredSubcats,
      };
      this.policyUpdates.push(policyUpdate);
    });
    // Filter out empty categories and order the list based on category order from backend
    this.filteredPolicyUpdates = orderBy(this.policyUpdates.filter(x => x.subcategories.length), this.categoryOrderStr, LodashSortOrder.desc) as PolicyUpdatesV2[];
  }

  /**
   * Generate req list from backend's model
   */
  getPolicyUpdates(): void {
    if (this.policyGuid) {
      this.loading = true;
      this.reportsApiService.getPolicyRequirementHistoryByGuid(this.policyGuid).subscribe({
        next: res => {
          this.policyTasks = res;
          this.createPolicyUpdatesV2(res, false);
        },
        error: () => {
          this.error.hasError = true;
        }
      }).add(() => {
        this.loading = false;
      });
    }
  }

  openUpdatesHistoryModal(policyTask: PolicyTaskV2): void {
    if (!policyTask) return;
    const dialog = this.dialog.open<UpdatesHistoryComponent, UpdateHistoryConfigModel, TaskNoteV2[]>(UpdatesHistoryComponent, {
      data: {
        PolicyGuid: policyTask.PolicyGuid,
        MainTaskId: policyTask.TaskId,
        ActionNeeded: policyTask.ActionNeeded ?? false,
        MainTaskStatusId: policyTask.TaskStatus?.ID,
        Title: policyTask.TaskName ?? '',
        Notes:policyTask.Notes,
      }
    });

    dialog.afterClosed().subscribe({
      next: res => {
        // If new messages added in modal, get new list from backend's model
        if (res) this.getPolicyUpdates();
      }
    });
  }

  openNewMessageModal(policyGuid?: string | null): void {
    if (!policyGuid) return;
    const dialog = this.dialog.open<NewAgentMessageComponent, string | null | undefined, AgentMessageFormValue>(NewAgentMessageComponent, {
      data: this.policyGuid,
    });

    dialog.afterClosed().subscribe({
      next: res => {
        if (res) this.getPolicyUpdates();
      }
    });
  }
}