<section class="flex flex-col gap-y-30 p-30" @fadeIn>
  <app-explore-header></app-explore-header>
  <div class="flex items-center justify-between gap-x-20">
    <form class="relative flex w-full border border-solid rounded max-w-384 border-gray-lighter" [formGroup]="searchForm">
      <input type="search"
             class="w-full rounded-r-none input is-inverted"
             placeholder="Search..."
             formControlName="searchText" />
      <button class="rounded-l-none icon-button is-primary" type="submit">
        <i class="material-icons">search</i>
      </button>
    </form>
  </div>
  @if (!showFilteredTiles) {
    <ul class="flex flex-col flex-none gap-y-40">
      @for (category of categories; track category) {
        <li class="flex flex-col flex-none gap-y-30">
          <div id="{{category.Id}}" class="flex items-center justify-start flex-none gap-x-20">
            <span class="flex items-center justify-center w-48 h-48 rounded-full bg-gray-lighter">
              <i class="material-icons" [class]="category.Fields.ForegroundColor">{{category.Fields.Icon}}</i>
            </span>
            <div>
              <h3 class="mb-5 text-lg font-bold capitalize heading">{{category.Fields.Name}}</h3>
              <p class="text-base">{{category.Fields.Description}}</p>
            </div>
          </div>
          <ul class="flex flex-row flex-wrap flex-none gap-x-20 gap-y-20">
            @for (tile of category.Fields.ExploreV2Tiles; track tile) {
              <li class="relative overflow-hidden transition-shadow duration-150 card hover:shadow-lg focus:shadow-lg">
                <!--workaround for tiles having ext url or state url-->
                @if (tile.Fields.ExternalUrl) {
                  <a class="absolute top-0 left-0 w-full h-full opacity-0" [href]="tile.Fields.ExternalUrl"
                    target="_blank" [amplitudeEvent]="'Platform Tiles - ' + tile.Fields.Title"></a>
                }
                @if (tile.Fields.AngularRoute) {
                  <a class="absolute top-0 left-0 w-full h-full opacity-0"
                    [routerLink]="tile.Fields.AngularRoute" [fragment]="category.Id"
                    [amplitudeEvent]="'Platform Tiles - ' + tile.Fields.Title"></a>
                }
                @if (tile.Fields.Size === exploreTileSizesEnum.lg) {
                  <div
                      class="flex h-full text-center w-360">
                    <div class="relative flex-none w-96">
                      <div class="z-0 w-full h-full bg-center bg-no-repeat bg-cover"
                          [style]="'background:url('+tile.Fields.CoverImgUrl +')'">
                      </div>
                      <div class="absolute top-0 right-0 z-10 flex-grow-0 w-48 h-full bg-right bg-no-repeat bg-contain"
                          style="background: url('./assets/images/bg/sm-wave-vertical-2.svg')"></div>
                    </div>
                    <div class="flex flex-col items-center justify-center flex-grow p-10 px-20">
                      @if (tile.Fields.Logo) {
                        <img class="object-contain w-auto h-64 m-10 max-w-200" src="./assets/images/icons/{{tile.Fields.Logo}}"
                            [alt]="tile.Fields.Title" />
                      }
                      <h4 class="m-10 mb-0 font-bold">{{tile.Fields.Title}}</h4>
                      <p class="m-10 text-sm text-gray">{{tile.Fields.Description}}</p>
                    </div>
                  </div>
                }
                @if (tile.Fields.Size === exploreTileSizesEnum.md) {
                  <div
                      class="flex flex-col items-center h-full p-10 text-center w-280">
                    @if (tile.Fields.Logo) {
                      <img class="object-contain w-auto m-10 h-60 max-w-200" src="./assets/images/icons/{{tile.Fields.Logo}}"
                          [alt]="tile.Fields.Title" />
                    }
                    @else {
                      <span class="z-10 flex items-center justify-center w-auto m-20 font-bold leading-tight uppercase max-h-64 border-gray-dark text-gray-dark border-3"
                            [class]="tile.Fields.Subtitle.length > 10 ? 'p-5 text-lg max-w-150' : tile.Fields.Subtitle.length > 4 ? 'p-10 text-xl' : 'p-15 text-3xl'"
                            [innerText]="tile.Fields.Subtitle"></span>
                    }
                    <div class="flex flex-col flex-grow-0">
                      <h4 class="m-10 mb-0 font-bold">{{tile.Fields.Title}}</h4>
                      <p class="m-10 text-sm text-gray">{{tile.Fields.Description}}</p>
                    </div>
                  </div>
                }
                @if (tile.Fields.Size === exploreTileSizesEnum.sm) {
                  <div
                      class="flex flex-col items-start h-full p-10 w-256">
                    <span class="z-10 flex flex-row items-center justify-center m-10 rounded-full w-36 h-36 bg-gray-lightest">
                      <i class="material-icons mi-18 {{tile.Fields.ForegroundColor}}">{{tile.Fields.Icon}}</i>
                    </span>
                    <div class="flex flex-col flex-grow-0">
                      <h4 class="m-10 mb-0 font-bold">{{tile.Fields.Title}}</h4>
                      <p class="m-10 text-sm text-gray">{{tile.Fields.Description}}</p>
                    </div>
                  </div>
                }
              </li>
            }
          </ul>
        </li>
      }
    </ul>
    }
    @if (showFilteredTiles) {
      <ul class="flex flex-row flex-wrap flex-none gap-x-20 gap-y-20">
        @for (tile of filteredTiles; track tile) {
          <li class="relative overflow-hidden transition-shadow duration-150 card hover:shadow-lg focus:shadow-lg">
            <!--workaround for tiles having ext url or state url-->
            @if (tile.Fields.ExternalUrl) {
              <a class="absolute top-0 left-0 w-full h-full opacity-0" [href]="tile.Fields.ExternalUrl"
                target="_blank" [amplitudeEvent]="'Platform Tiles - ' + tile.Fields.Title"></a>
            }
            @if (tile.Fields.AngularRoute) {
              <a class="absolute top-0 left-0 w-full h-full opacity-0"
                [routerLink]="tile.Fields.AngularRoute"
                [amplitudeEvent]="'Platform Tiles - ' + tile.Fields.Title"></a>
            }
            @if (tile.Fields.Size === exploreTileSizesEnum.lg) {
              <div
                  class="flex h-full text-center w-360">
                <div class="relative flex-none w-96">
                  <div class="z-0 w-full h-full bg-center bg-no-repeat bg-cover"
                      [style]="'background:url('+tile.Fields.CoverImgUrl+')'">
                  </div>
                  <div class="absolute top-0 right-0 z-10 flex-grow-0 w-48 h-full bg-right bg-no-repeat bg-contain"
                      style="background: url('./assets/images/bg/sm-wave-vertical-2.svg')"></div>
                </div>
                <div class="flex flex-col items-center justify-center flex-grow p-10 px-20">
                  @if (tile.Fields.Logo) {
                    <img class="object-contain w-auto h-64 m-10 max-w-200" src="./assets/images/icons/{{tile.Fields.Logo}}"
                        [alt]="tile.Fields.Title" />
                  }
                  <h4 class="m-10 mb-0 font-bold">{{tile.Fields.Title}}</h4>
                  <p class="m-10 text-sm text-gray">{{tile.Fields.Description}}</p>
                </div>
              </div>
            }
            @if (tile.Fields.Size === exploreTileSizesEnum.md) {
              <div
                  class="flex flex-col items-center h-full p-10 text-center w-280">
                @if (tile.Fields.Logo) {
                  <img class="object-contain w-auto m-10 h-60 max-w-200" src="./assets/images/icons/{{tile.Fields.Logo}}"
                      [alt]="tile.Fields.Title" />
                }
                @if (!tile.Fields.Logo) {
                  <span class="z-10 flex items-center justify-center w-auto m-20 font-bold leading-tight uppercase max-h-64 border-gray-dark text-gray-dark border-3"
                        [class]="tile.Fields.Subtitle.length > 10 ? 'p-5 text-lg max-w-150' : tile.Fields.Subtitle.length > 4 ? 'p-10 text-xl' : 'p-15 text-3xl'"
                        [innerText]="tile.Fields.Subtitle"></span>
                }
                <div class="flex flex-col flex-grow-0">
                  <h4 class="m-10 mb-0 font-bold">{{tile.Fields.Title}}</h4>
                  <p class="m-10 text-sm text-gray">{{tile.Fields.Description}}</p>
                </div>
              </div>
            }
            @if (tile.Fields.Size === exploreTileSizesEnum.sm) {
              <div
                  class="flex flex-col items-start h-full p-10 w-256">
                <span class="z-10 flex flex-row items-center justify-center m-10 rounded-full w-36 h-36 bg-gray-lightest">
                  <i class="material-icons mi-18 {{tile.Fields.ForegroundColor}}">{{tile.Fields.Icon}}</i>
                </span>
                <div class="flex flex-col flex-grow-0">
                  <h4 class="m-10 mb-0 font-bold">{{tile.Fields.Title}}</h4>
                  <p class="m-10 text-sm text-gray">{{tile.Fields.Description}}</p>
                </div>
              </div>
            }
          </li>
        }
      </ul>
    @if (!filteredTiles.length) {
      <p class="text-gray">No results found.</p>
    }
  }
</section>