<section id="dialogToolbar" class="flex-none dialog-toolbar">
  <button class="absolute top-0 right-0 m-20 icon-button is-primary is-inverted" aria-label="Close"
    [mat-dialog-close] type="button">
    <i class="material-icons">close</i>
  </button>
  <h2 class="mb-20 text-lg heading">Impairment Fact-Finder Instructions</h2>
</section>
<section class="flex flex-col p-20 overflow-auto lg:p-20 dialog-content">
  <p class="mb-10 md:mb-20 text-base">
    If you would like to view and/or print the form, please do the following.
  </p>

  <div
    class="flex flex-row p-20 text-sm leading-loose bg-opacity-25 border border-solid rounded border-blue-lighter bg-blue-lighter">
    <ol class="list-decimal list-inside">
      @for (instruction of instructions; track instruction; let last = $last) {
        <li class="leading-8" [class]="last ? '' :'mb-10'" [innerHtml]="instruction"></li>
      }
    </ol>
  </div>
</section>