@if (!isLoading) {
  <section [@fadeIn]>
    @if (resource.EnrollmentSurveyData) {
      <div class="flex flex-row">
        @for (survey of submitted; track survey; let index = $index) {
          <div>
            <ng-container *hideIfOne="'HegnaRHNTierI:Access|HegnaRHNTierII:Access|HegnaRHNTierIII:Access'">
              <button type="button" class="mt-20 mr-20 button is-primary md:mt-30"
                (click)="!!survey && !survey.isEnrolled ? openEnrollmentModal(survey.surveyId) : ''"
                [disabled]="survey.isEnrolled">
                @if (survey && !survey.isEnrolled) {
                  <span>{{ !!resource.EnrollmentSurveyData.Labels
                    &&
                    resource.EnrollmentSurveyData.Labels[index] ?
                  resource.EnrollmentSurveyData.Labels[index] : 'Enroll Today'}}</span>
                }
                @else if (survey && survey.isEnrolled) {
                  <span [matTooltip]="!!resource.EnrollmentSurveyData.Labels && resource.EnrollmentSurveyData.Labels[index] ? resource.EnrollmentSurveyData.Labels[index] + ' form has already been submitted' : 'Enrollment form already submitted'" matTooltipPosition="above"
                  >Submitted</span>
                }
              </button>
            </ng-container>
            <!-- <button class="mt-20 mr-20 button is-primary md:mt-30" (click)="openPurchaseDialog()"
            *ngIf="!!survey && survey.isEnrolled && !!resource.PurchasePageData"
            [disabled]="!!purchased && purchased.Complete">
            <ng-container *hideIfOne="'HegnaRHNTierII:Access|HegnaRHNTierIII:Access'">
              <span *ngIf="!purchased">
                Purchase{{resource.EnrollmentSurveyData.Labels &&
                resource.EnrollmentSurveyData.Labels[index] ? ' ' +
                resource.EnrollmentSurveyData.Labels[index] : ''}}
              </span>
            </ng-container>
            <span *ngIf="!!purchased && purchased.Complete">
              {{resource.EnrollmentSurveyData.Labels &&
              resource.EnrollmentSurveyData.Labels[index] ? ' ' +
              resource.EnrollmentSurveyData.Labels[index] : ''}} Purchased On {{purchased?.PurchaseDate}}
            </span>
          </button> -->
        </div>
      }
    </div>
  }
  <ng-container *hideIfOne="'HegnaRHNTierII:Access|HegnaRHNTierIII:Access'">
    @if (purchased && purchased.DocusignUrl && purchased.MarketingStrategy && (purchased.MarketingStrategy === 'HegnaRHNTierII' || purchased.MarketingStrategy === 'HegnaRHNTierIII')) {
      <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openDocusignLink()"
        >
        <span>
          Sign Agreement
        </span>
      </button>
    }
  </ng-container>
  <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openExternalLoginLink()"
    *showIfOne="'HegnaRHNTierII:Access|HegnaRHNTierIII:Access'">
    <span>
      Log Into Tom Hegna on Demand
    </span>
  </button>
</section>
}
