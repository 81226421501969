@if (informalPolicy) {
  <section
    class="flex flex-col gap-y-30 p-30">
    <section class="flex flex-col gap-y-20">
      <h2 class="text-sm font-bold uppercase text-gray">Informal Details</h2>
      <div class="flex flex-col text-sm font-bold rounded-lg gap-y-15">
        <div class="flex items-center gap-x-10">
          <span class="material-icons mi-18 text-gray">people</span>
          <div class="flex flex-col">
            <span class="font-medium text-gray">Clients</span>
            <span>{{informalPolicy?.Clients ? informalPolicy.Clients : '--'}}</span>
          </div>
        </div>
        <div class="flex items-center gap-x-10">
          <span class="material-icons mi-18 text-gray">location_on</span>
          <div class="flex flex-col">
            <span class="font-medium text-gray">State</span>
            <span>{{informalPolicy.StateName ? informalPolicy.StateName : '--'}}</span>
          </div>
        </div>
        <div class="flex items-center gap-x-10">
          <span class="material-icons mi-18 text-gray">monetization_on</span>
          <div class="flex flex-col">
            <span class="font-medium text-gray">Face Amount</span>
            <span>{{informalPolicy.FaceAmount ? (informalPolicy.FaceAmount | currency) : '--'}}</span>
          </div>
        </div>
      </div>
    </section>
    <!-- TODO: This does not take into account Disability like the old version of this UI -->
    <section class="flex flex-col gap-y-20">
      <h2 class="text-sm font-bold uppercase text-gray">Insights</h2>
      <ul class="flex flex-col gap-y-10">
        <li class="flex items-center gap-x-10">
          <span class="material-icons mi-18"
            [class]="informalPolicy.StageHistory[0] ? 'text-green' : 'text-gray'">
            {{informalPolicy.StageHistory[0] ? 'check_circle' :
            'radio_button_unchecked'}}
          </span>
          <div class="flex flex-col">
            <h3 class="text-sm font-bold capitalize">Received At FIG</h3>
            <span class="text-xs font-semibold label text-gray">{{ informalPolicy.StageHistory[0]
              ? (informalPolicy.StageHistory[0].InformalStageDate | date: 'MM/dd/yy' : 'UTC') :
            '--'}}</span>
          </div>
        </li>
        <li class="flex items-center gap-x-10">
          <span class="material-icons mi-18"
            [class]="informalPolicy.StageHistory[1] ? 'text-green' : 'text-gray'">
            {{informalPolicy.StageHistory[1] ? 'check_circle' :
            'radio_button_unchecked'}}
          </span>
          <div class="flex flex-col">
            <h3 class="text-sm font-bold capitalize">Submitted</h3>
            <span class="text-xs font-semibold label text-gray">{{ informalPolicy.StageHistory[1]
              ? (informalPolicy.StageHistory[1].InformalStageDate | date: 'MM/dd/yy' : 'UTC') :
            '--'}}</span>
          </div>
        </li>
        <li class="flex items-center gap-x-10">
          <span class="material-icons mi-18"
            [class]="informalPolicy.StageHistory[2] ? 'text-green' : 'text-gray'">
            {{informalPolicy.StageHistory[2] ? 'check_circle' :
            'radio_button_unchecked'}}
          </span>
          <div class="flex flex-col">
            <h3 class="text-sm font-bold capitalize">Pending</h3>
            <span class="text-xs font-semibold label text-gray">{{ informalPolicy.StageHistory[2]
              ? (informalPolicy.StageHistory[2].InformalStageDate | date: 'MM/dd/yy' : 'UTC') :
            '--'}}</span>
          </div>
        </li>
        <li class="flex items-center gap-x-10">
          <span class="material-icons mi-18"
            [class]="informalPolicy.StageHistory[3] ? 'text-green' : 'text-gray'">
            {{informalPolicy.StageHistory[3] ? 'check_circle' :
            'radio_button_unchecked'}}
          </span>
          <div class="flex flex-col">
            <h3 class="text-sm font-bold capitalize">Completed</h3>
            <span class="text-xs font-semibold label text-gray">{{ informalPolicy.StageHistory[3]
              ? (informalPolicy.StageHistory[3].InformalStageDate | date: 'MM/dd/yy' : 'UTC') :
            '--'}}</span>
          </div>
        </li>
      </ul>
    </section>
  </section>
}