import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import orderBy from 'lodash-es/orderBy';
import { Subscription } from 'rxjs';
import { fadeIn, fadeInOut } from 'src/app/animations';
import { ExploreTileSizesEnum } from 'src/app/portal/components/explore/enums/explore.enum';
import { ExploreCategory, ExploreCategoryFilter, ExploreSortOption, ExploreTile, SearchFormGroup } from 'src/app/portal/components/explore/models/explore.models';
import { hasAll } from 'src/app/shared/helpers/search.helpers';
import { FigPlatformTile } from 'src/app/shared/models/figplatform.models';

@Component({
  animations: [fadeIn, fadeInOut],
  selector: 'app-explore-list-view',
  templateUrl: './explore-list-view.component.html',
  styles: [
  ]
})

export class ExploreListViewComponent implements OnInit, OnDestroy {
  @Input() categories: ExploreCategory[] = [];
  @Input() tiles: ExploreTile[] = [];
  filteredTiles: ExploreTile[] = [];
  exploreTileSizesEnum = ExploreTileSizesEnum;
  hasActiveFilters = false;
  searchTextSubscription = new Subscription();
  searchForm = this.fb.group({
    searchText: [null],
  }) as SearchFormGroup;
  sortOptions: ExploreSortOption[] = [
    {
      propName: 'Name',
      propValue: ['Fields.CapitalTitle'],
    },
    {
      propName: 'Category',
      propValue: ['Fields.CategoryName', 'Fields.CapitalTitle'],
    }
  ];

  selectedSortOption = this.sortOptions[0];
  sortOrder: 'asc' | 'desc' = 'asc';
  categoryFilters: ExploreCategoryFilter[] = [];

  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.sortTilesV2(this.selectedSortOption, 'asc');
    this.createCatgegoryFilters();
    this.subscribeToSearchTextValueChange();
  }

  ngOnDestroy(): void {
    this.searchTextSubscription.unsubscribe();
  }

  createCatgegoryFilters(): void {
    this.categories.forEach(x => {
      this.categoryFilters.push({
        displayName: x.Fields.Name,
        checked: false,
        foregroundColor: x.Fields.ForegroundColor
      });
    });

    this.categoryFilters = orderBy(this.categoryFilters, 'displayName') as ExploreCategoryFilter[];
  }

  filterTiles(): void {
    const activeCategories: string[] = [];
    this.categoryFilters.forEach(filter => {
      if (filter.checked) activeCategories.push(filter.displayName);
    });
    if (activeCategories.length) {
      this.hasActiveFilters = true;
      this.filteredTiles = this.tiles.filter(tile => activeCategories.includes(tile.Fields.CategoryName));
      this.sortTilesV2(this.selectedSortOption, this.sortOrder);
    } else {
      this.hasActiveFilters = false;
      this.sortTilesV2(this.selectedSortOption, 'asc');
    }
  }

  removeFilter(filter: ExploreCategoryFilter): void {
    const index = this.categoryFilters.indexOf(filter);

    if (index >= 0) {
      this.categoryFilters[index].checked = false;
      this.filterTiles();
    }
  }

  searchTile(): void {
    if (this.searchForm.invalid) return;
    const searchText = this.searchForm.value.searchText;
    let filteredTiles: FigPlatformTile[] = [];
    if (this.hasActiveFilters) {
      filteredTiles = this.filteredTiles.filter(tile => hasAll(tile.Fields, searchText));
    } else {
      filteredTiles = this.tiles.filter(tile => hasAll(tile.Fields, searchText));
    }
    this.filteredTiles = orderBy(filteredTiles, this.selectedSortOption.propValue, this.sortOrder) as ExploreTile[];
  }

  sortTilesV2(selectedSortOption: ExploreSortOption, sortOrder?: 'asc' | 'desc'): void {
    const capitalTiles: ExploreTile[] = this.hasActiveFilters ? this.filteredTiles : this.tiles;
    capitalTiles.forEach(tile => {
      tile.Fields.CapitalTitle = tile.Fields.Title.toLowerCase();
    });
    if (this.selectedSortOption === selectedSortOption) {
      this.sortOrder = sortOrder ? sortOrder : this.sortOrder === 'desc' ? 'asc' : 'desc';
    } else {
      this.selectedSortOption = selectedSortOption;
      this.sortOrder = 'asc';
    }
    this.filteredTiles = orderBy(capitalTiles, selectedSortOption.propValue, this.sortOrder) as ExploreTile[];
  }

  subscribeToSearchTextValueChange(): void {
    this.searchTextSubscription = this.searchForm.controls.searchText.valueChanges.subscribe({
      next: res => {
        if (res) this.searchTile();
        else this.filterTiles();
      }
    });
  }
}