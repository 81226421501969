<section class="flex flex-col gap-y-30 p-30">
  <div class="flex flex-col items-center m-auto gap-y-20">
    <img class="w-auto h-50" src="./assets/images/logos/Salesforce-logo-horizontal.svg" aria-label="Salesforce Image" alt="Salesforce logo">
    <p class="text-lg">Connect to your Salesforce account below to enable searching for your Salesforce clients in e-application forms.</p>
    @if (isSalesforceConnected) {
      <span class="w-full text xs text-green-dark">You are connected.</span>
      <button type="button"
        class="button is-warning"
        [disabled]="isSubmitting"
        (click)="deactivateSalesforce()">
        Disconnect{{isSubmitting ? 'ing' : ''}}
      </button>
    }@else {
      <button type="button"
        class="button is-primary"
        [disabled]="isSubmitting"
        (click)="activateSalesforce()">
        Sign{{isSubmitting ? 'ing' : ''}} In
      </button>
    }
  </div>
</section>