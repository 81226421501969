<div class='flex flex-col flex-grow w-full h-full overflow-hidden'>
  <section class="flex flex-col flex-none">
    <div id="userLogoUploadToolbar"
      class="flex items-center flex-none p-20 border-b border-solid lg:p-30 border-gray-lighter">
      <h1 class="mr-auto text-lg font-bold" appCyData="modalTitle">Manage Site Logo</h1>
      <button class="icon-button is-primary is-inverted" tabindex="-1" aria-label="Close" appCyData="closeIcon"
        mat-button mat-dialog-close type="button"><i class="material-icons">close</i>
      </button>
    </div>
  </section>
  <section class="flex flex-col justify-center flex-auto p-10 overflow-auto">
    @if (!uploadingFile) {
      <div class="flex flex-col items-center m-20 text-center gap-y-20" @fadeIn>
        <p class="text-sm text-gray" appCyData="logoLabel">{{ user.HasUserLogo ? 'Current Logo' : 'Default Logo'}}</p>
        <div class="flex flex-col items-center m-10 gap-y-10">
          @if (user.HasUserLogo) {
            <img class="w-auto h-48" [src]="logo" appCyData="hasLogo" alt="Logo Image">
            <button mat-button class="mx-auto button is-warning is-inverted is-sm" aria-label="Delete file button"
              type="button" appCyData="removeCurrentLogoBtn" (click)="confirmDeleteLogo()">
              Remove Logo Image
            </button>
          }@else {
            <img class="w-auto h-48" [src]="figLogo" appCyData="noLogo" alt="FIG Logo">
          }
        </div>
      </div>
    }
    <div class="flex flex-col items-center m-20 text-center gap-y-20" @fadeIn>
      @if (!uploadingFile) {
        <div
          class="relative flex flex-col items-center justify-center w-full p-20 text-center transition duration-150 border-2 border-dashed rounded cursor-pointer cursror-pointer border-gray-lighter min-h-128 hover:text-blue hover:border-blue text-gray"
          [class]="dragover? 'border-blue text-blue':''">
          <input class="absolute top-0 left-0 w-full h-full opacity-0" accept=".png, .jpg, .jpeg" appCyData="uploadSection"
            (dragover)="dragOverHandler($event)" type="file" id="file" (change)="uploadFiles($event)">
            <span class="text-sm font-semibold label">Click or drag image here to upload a new logo.</span>
            <span class="text-sm font-semibold label">(Accepts PNG, JPG)</span>
          </div>
        } @else if (uploadingFile) {
          <p class="text-sm text-gray">New Logo Preview</p>
          <div class="flex flex-col items-center w-full gap-y-10">
            <div class="flex items-center w-full h-64 shadow px-30">
              <img class="w-auto h-48" [src]="previewUrl" alt="New logo preview">
              <div class="flex items-center ml-auto text-sm font-bold uppercase gap-x-10">
                <span>{{user.FirstName}} {{user.LastName}}</span>
                <span class="flex items-center justify-center rounded-full w-36 h-36 bg-gray-lightest text-blue-dark">
                  <span>{{user.FirstName[0]}}{{user.LastName[0]}}</span>
                </span>
              </div>
            </div>
            <button mat-button class="mx-auto button is-primary is-inverted is-sm" type="button" appCyData="removeImage" (click)="removeFile()">
              Choose a different image
            </button>
          </div>
        }
        <!-- TODO: Would be nice to make this "smarter & more self-contained" when it comes to show/hide behavior-->
        @if (showInlineSnackbar) {
          <app-inline-snackbar class="w-full" [data]="snackbarData" (inlineSnackbarClose)="showInlineSnackbar = false"></app-inline-snackbar>
        }
      </div>
    </section>
    <section class="flex flex-none p-20 mt-auto border-t border-solid lg:p-30 border-gray-lighter">
      <!-- <button type="button" mat-button mat-dialog-close class="mr-auto button is-primary is-outlined"
      appCyData="closeModal">Close
    </button> -->
    <button class="ml-auto button is-primary" type="button" tabindex="-1" [disabled]="!uploadingFile"
      appCyData="uploadLogo" (click)="uploadLogo()">
    Upload</button>
  </section>
</div>