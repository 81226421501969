<mat-sidenav-container class="relative flex flex-grow h-full overflow-hidden bg-white" @fadeIn>
  <mat-sidenav-content class="flex flex-col flex-grow" #policySidenav>
    <section class="flex items-center w-full p-20 border-b border-solid gap-x-10 border-gray-lighter">
      <h1 class="text-lg font-bold" [innerText]="routeTitle"></h1>
      @if (routeTitle) {
        <span class="divider is-vertical"></span>
      }
      <div class="flex mr-auto">
        <div class="relative flex flex-grow sm:w-384">
          <i class="absolute mr-10 transform -translate-y-1/2 left-15 material-icons text-gray top-1/2">search</i>
          <input class="w-full pl-48 border input is-lg" type="text" name="search"
            aria-label="Search policies and cases" [placeholder]="'Search Policies'" (keyup)='setSearchText(searchText)'
            [(ngModel)]="searchText" />
          </div>
        </div>
        <button type="button"
          class="flex items-center justify-center button is-primary is-outlined" mat-button
          [disabled]="isExporting"
          (click)="export()" aria-label="Export Carriers List">
          <span class="flex items-center">
            <span>Export</span>
            <span class="ml-5 material-icons mi-18">
              file_download
            </span>
          </span>
        </button>
      </section>
      <section class="flex flex-col flex-grow">
        @if (!isLoading) {
          <app-informal-policy-table
            [informalPolicies]="filteredPolicies"
            [isError]="isError"
            [reportType]="reportType"
          class="flex flex-col flex-grow"></app-informal-policy-table>
        } @else {
          <img src="/assets/images/loading/table-placeholder.svg" alt="loading" class="w-full animate-pulse">
        }
      </section>
    </mat-sidenav-content>
  </mat-sidenav-container>