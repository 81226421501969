<section class="flex flex-col w-full text-base">
  @if (searchResult?.RouteType === 'External') {
    <a class="flex flex-col" [href]="searchResult?.Url" target="_blank">
      <span [innerHTML]="searchResult?.Title ?? '' | boldMatch:searchText ?? null" class="whitespace-normal"></span>
      @if (searchResult?.Category) {
        <span class="text-sm text-gray" [innerHTML]="searchResult?.Category ?? ''  | boldMatch:searchText ?? null"></span>
      }
    </a>
  } @else if (searchResult?.RouteType === 'Internal') {
    <a class="flex flex-col" routerLink="{{searchResult?.Url}}">
      <span [innerHTML]="searchResult?.Title ?? '' | boldMatch:searchText ?? null" class="whitespace-normal"></span>
      @if (searchResult?.Category) {
        <span class="text-sm text-gray" [innerHTML]="searchResult?.Category ?? ''  | boldMatch:searchText ?? null"></span>
      }
    </a>
  }
  @if (!searchResult?.Url && searchResult?.RouteType === 'Action') {
    <a class="flex flex-col" (click)="contentMap.resourceToFunctionMap.get(searchResult?.Id ?? '')!()">
      <span [innerHTML]="searchResult?.Title ?? '' | boldMatch:searchText ?? null" class="whitespace-normal"></span>
      @if (searchResult?.Category) {
        <span class="text-sm text-gray" [innerHTML]="searchResult?.Category ?? ''  | boldMatch:searchText ?? null"></span>
      }
    </a>
  }
</section>