import { DmaCategory } from 'src/app/shared/models/digital-marketing-assets.models';
import { Injectable } from '@angular/core';
import { SsoService } from './sso.service';

@Injectable({
  providedIn: 'root'
})
export class DigitalMarketingAssetsService {

  constructor(private ssoService: SsoService) { }

  getCategories(): DmaCategory[] {
    return this.dmaCategories;
  }

  readonly dmaCategories: DmaCategory[] = [
    {
      Order: 0,
      Title: 'Marketing',
      SubCategories:
        [{
          Order: 0,
          Title: 'Seminars and Webinars',
          Date: new Date(2020, 3, 22),
          ContentType: 'text',
          Description: 'Below are resources to help create alternatives to live events.',
          Links:
            [
              {
                Order: 0,
                Date: new Date(2020, 3, 9),
                Featured: false,
                IconClass: 'fig-file-text',
                Title: 'Download Mass Media Solutions',
                Url: '/v1/Documents/MMG-Webinar-Marketing.pdf'
              },
              {
                Order: 5,
                Date: new Date(2020, 3, 7),
                Featured: false,
                IconClass: 'fig-file-text',
                Title: 'Download phone script on how to cancel your seminar',
                Url: '/v1/Documents/PROSPECTING_PHONE_Script_Workshop_Cancellation.docx'
              },
              {
                Order: 10,
                Date: new Date(2020, 3, 7),
                Featured: false,
                IconClass: 'fig-file-text',
                Title: 'Download email template on how to cancel your seminar',
                Url: '/v1/Documents/PROSPECTING_EMAIL_Script_Workshop_Cancellation.docx'
              },
              {
                Order: 15,
                Date: new Date(2020, 3, 7),
                Featured: false,
                IconClass: 'fig-file-text',
                Title: 'Learn more about WebinarConnect by Leading Response',
                Url: '/v1/Documents/LATEST_Leading_Response_Webinar_Connect.pdf'
              },
              {
                Order: 20,
                Date: new Date(2020, 3, 7),
                Featured: false,
                IconClass: 'fig-file-text',
                Title: 'Download LeadJig/Acquire Solutions',
                Url: '/v1/Documents/LeadJigDigital.pdf'
              },
              {
                Order: 25,
                Date: new Date(2020, 3, 7),
                Featured: false,
                IconClass: 'fig-file-text',
                Title: 'Download Plum Solutions',
                Url: '/v1/Documents/Plum_Resources.pdf'
              },
              {
                Order: 30,
                Date: new Date(2020, 3, 10),
                Featured: false,
                IconClass: 'fig-file-text',
                Title: 'Download Whiteglove Solutions Here',
                Url: '/v1/Documents/Virtual_Seminars_-_White_Glove_5B1_5D.pdf'
              },
              {
                Order: 35,
                Date: new Date(2020, 3, 22),
                Featured: false,
                IconClass: 'fig-file-text',
                Title: 'Click Here for a Webinar Comparison Sheet',
                Url: '/v1/Documents/Virtual_Offering Comparison_04.2023_FNL.pdf'
              }
            ]
        },
        {
          Order: 10,
          Title: 'Video',
          Date: new Date(2020, 5, 9),
          ContentType: 'text',
          Description: 'Broadcast live on Facebook Live or send a quick Bomb Bomb video to engage your audience with the latest news, relevant financial tips, local events and more.',
          Links:
            [{
              Order: 10,
              Date: new Date(2020, 3, 16),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'download the CARES Act video script',
              Url: '/v1/Documents/Cares_Act_VideoScript.docx'
            },
            {
              Order: 20,
              Date: new Date(2020, 2, 27),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Learn how to go live on Facebook',
              Url: 'https://www.facebook.com/help/1636872026560015'
            },
            {
              Order: 30,
              Date: new Date(2020, 2, 27),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Learn more about Bomb Bomb',
              Url: 'https://bombbomb.com/c/'
            },
            {
              Order: 40,
              Date: new Date(2020, 2, 27),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Leading Response PSA Script Template',
              Url: '/v1/Documents/Advisor_Prospecting_PSA_Script.docx'
            },
            {
              Order: 50,
              Date: new Date(2020, 2, 27),
              Featured: false,
              IconClass: 'fig-play-circle',
              Title: 'Learn How to Look More Professional on Video',
              Url: 'https://www.linkedin.com/learning/executive-presence-on-video-conference-calls/your-video-conference-presence'
            },
            {
              Order: 60,
              Date: new Date(2020, 2, 27),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Coronavirus & Use of Riskalyze Video Script Template',
              Url: '/v1/Documents/VIDEO_SCRIPT_Riskalyze.docx'
            },
            {
              Order: 65,
              Date: new Date(2020, 5, 9),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Phase 2 CARES ACT Video Script',
              Url: '/v1/Documents/Phase2COVID_Cares_Act_VideoScript_(1).docx'
            }]
        },
        {
          Order: 1,
          Title: 'One-on-One Appointment Solutions',
          Date: new Date(2020, 2, 24),
          ContentType: 'text',
          Description: 'Need new appointments but can’t host events? These concierge services allow quality responders to book appointments directly on your calendar through mailers and digital solutions.',
          Links:
            [{
              Order: 10,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Learn more about Leading Response Solutions',
              Url: '/v1/Documents/Leading_Response_Seminar_Marketing_Alternatives.pdf'
            },
            {
              Order: 20,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Learn more about Melissa Graves Solution',
              Url: '/v1/Documents/Melissa_Graves_One-On-One_Appt_Setting.pdf'
            },
            {
              Order: 30,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Learn more about LeadJig/Acquire Solutions',
              Url: '/v1/Documents/LeadJigDigital.pdf'
            },
            {
              Order: 40,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Learn more about Plum Solution',
              Url: '/v1/Documents/Plum_Resources.pdf'
            }]
        },
        {
          Order: 30,
          Title: 'Website',
          Date: new Date(2020, 5, 8),
          ContentType: 'text',
          Description: 'Keep your clients and prospects fully updated on any changes to your business by using these useful tools.',
          Links:
            [{
              Order: 0,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Learn how to update your Google business listing',
              Url: 'https://support.google.com/business/answer/3039617?visit_id=637199748405826764-2352763689&rd=1'
            },
            {
              Order: 10,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download COVID-19 Website Announcement Template',
              Url: '/v1/Documents/WEBSITE_Script_Virtual_Meetings.docx'
            },
            {
              Order: 20,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Website Pop-Up Images',
              Url: '/v1/Documents/WEBSITE_Pop_Ups.docx'
            },
            {
              Order: 20,
              Date: new Date(2020, 5, 9),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Phase 2 Website Announcement Template',
              Url: '/v1/Documents/Phase2Covid_WEBSITE_Script_Virtual_Meetings_(8).docx'
            }]
        },
        {
          Order: 40,
          Title: 'Email',
          Date: new Date(2020, 5, 9),
          ContentType: 'text',
          Description: 'Use an email automation tool to connect with your clients and prospects about state of market updates, request one-on-one appts, weekly updates and more.',
          Links:
            [
              {
                Order: 0,
                Date: new Date(2020, 2, 24),
                Featured: false,
                IconClass: 'fig-web',
                Title: 'Learn more about Constant Contact',
                Url: 'https://www.constantcontact.com/email-marketing'
              },
              {
                Order: 10,
                Date: new Date(2020, 2, 24),
                Featured: false,
                IconClass: 'fig-web',
                Title: 'Sign Up for Constant Contact',
                Url: 'https://www.constantcontact.com/signup.jsp'
              },
              {
                Order: 20,
                Date: new Date(2020, 2, 24),
                Featured: false,
                IconClass: 'fig-file-text',
                Title: 'Download COVID-19 Email Announcement Template',
                Url: '/v1/Documents/WEBSITE_Script_Virtual_Meetings.docx'
              },
              {
                Order: 25,
                Date: new Date(2020, 3, 8),
                Featured: false,
                IconClass: 'fig-file-text',
                Title: 'Download Best Practices for Email Communication During Crisis',
                Url: '/v1/Documents/Email_Marketing_Tips_In_Crisis_Guide.pdf'
              },
              {
                Order: 30,
                Date: new Date(2020, 5, 9),
                Featured: false,
                IconClass: 'fig-file-text',
                Title: 'Download Phase 2 Email Drip Campaign',
                Url: '/v1/Documents/Phase2COVID_CLIENT_NURTURING_EMAIL_Script.docx'
              }
            ]
        },
        {
          Order: 50,
          Title: 'Social Media',
          Date: new Date(2020, 5, 9),
          ContentType: 'text',
          Description: 'Create brand awareness on social media. This allows you to stay connected with your current client base, while also prospecting and getting your brand out there.',
          Links:
            [{
              Order: 0,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Learn more about Facebook business page',
              Url: 'https://www.facebook.com/business/help/461775097570076?id=939256796236247'
            },
            {
              Order: 10,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Learn how to go live on Facebook',
              Url: 'https://www.facebook.com/help/1636872026560015'
            },
            {
              Order: 20,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Learn how to build & increase brand awareness on LinkedIn',
              Url: 'https://business.linkedin.com/marketing-solutions/success/brand-awareness'
            },
            {
              Order: 30,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Website Pop-Up Images',
              Url: '/v1/Documents/WEBSITE_Pop_Ups.docx'
            },
            {
              Order: 30,
              Date: new Date(2020, 5, 9),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Phase 2 Social Media Posts',
              Url: '/v1/Documents/FIG_5.22.20_Phase2COVID-19Response_SocialPosts_FNL.docx'
            }]
        },
        {
          Order: 60,
          Title: 'Reassure Your Clients',
          Date: new Date(2020, 5, 9),
          ContentType: 'text',
          Description: 'Keep your current clients comforted with this email template and phone script. Use this as a good touch base to remind them you are there for them.',
          Links:
            [{
              Order: 0,
              Date: new Date(2020, 3, 16),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Social Posts Templates',
              Url: '/v1/Documents/3._Social_Posts.zip'
            },
            {
              Order: 10,
              Date: new Date(2020, 3, 16),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Drip Emails Templates',
              Url: '/v1/Documents/1._Drip_Emails.zip'
            },
            {
              Order: 10,
              Date: new Date(2020, 3, 16),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Additional Communication Scripts',
              Url: '/v1/Documents/5._Scripts.zip'
            },
            {
              Order: 40,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Email Script',
              Url: '/v1/Documents/CLIENT_NURTURING_EMAIL_Script.docx'
            },
            {
              Order: 60,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Phone Script',
              Url: '/v1/Documents/CLIENT_NURTURING_PHONE_Script_Current_Clients.docx'
            },
            {
              Order: 65,
              Date: new Date(2020, 5, 9),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Phase 2 Connecting With Clients Phone Script',
              Url: '/v1/Documents/Phase2COVID_CLIENT_NURTURING_PHONE_Script_Current_Clients_(2).docx'
            },
            {
              Order: 70,
              Date: new Date(2020, 5, 9),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Phase 2 Outbound Phone Script',
              Url: '/v1/Documents/Phase2COVID-10Response_ClientNurturing_OutboundScript_FNL.docx'
            },
            {
              Order: 75,
              Date: new Date(2020, 5, 9),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Phase 2 Inbound Phone Script',
              Url: '/v1/Documents/Phase2COVID-10Response_ClientNurturing_InboundScript_FNL.docx'
            },
            {
              Order: 80,
              Date: new Date(2020, 5, 9),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download Phase 2 Email Drip Campaign',
              Url: '/v1/Documents/FIG_5.22.20_COVID-19Phase2_DripCampaign_FNL.docx'
            }]
        }]
    },
    {
      Order: 30,
      Title: 'Appointments',
      SubCategories: [{
        Order: 0,
        Title: 'Setting Appointments',
        Date: new Date(2020, 2, 24),
        ContentType: 'text',
        Description: 'Online schedulers provide a link to your available appointment slots. Use the link in emails, email signatures, and all digital prospecting efforts to invite someone to meet with you. Best of all—it can link straight to your calendar.',
        Links:
          [{
            Order: 0,
            Date: new Date(2020, 2, 24),
            Featured: false,
            IconClass: 'fig-web',
            Title: 'Watch the Calendly Tutorial',
            Url: 'https://calendly.com/?wvideo=ldm2s4ep7u'
          },
          {
            Order: 10,
            Date: new Date(2020, 2, 24),
            Featured: false,
            IconClass: 'fig-web',
            Title: 'Sign Up for Calendly for Free',
            Url: 'https://calendly.com/?&utm_campaign=brand-exact&aw_adgroup=&aw_version=c&gclid=EAIaIQobChMImqfxiYii6AIVFXiGCh39pQRyEAAYASAAEgInXPD_BwE'
          },
          {
            Order: 20,
            Date: new Date(2020, 2, 24),
            Featured: false,
            IconClass: 'fig-web',
            Title: 'Learn More on Timetrade',
            Url: 'https://youtu.be/OqLCjcBw-TY'
          },
          {
            Order: 30,
            Date: new Date(2020, 2, 24),
            Featured: false,
            IconClass: 'fig-web',
            Title: 'Schedule a Demo with Timetrade',
            Url: 'https://www.timetrade.com/appointment-router-hp1-demo/'
          }]
      },
      {
        Order: 10,
        Title: 'Phone & Text Solutions',
        Date: new Date(2020, 2, 24),
        ContentType: 'text',
        Description: 'Texting and calling solutions can help you send a message out to your whole database quickly.',
        Links:
          [{
            Order: 0,
            Date: new Date(2020, 2, 24),
            Featured: false,
            IconClass: 'fig-web',
            Title: 'Learn more about Textedly',
            Url: 'https://vimeo.com/157185193'
          },
          {
            Order: 10,
            Date: new Date(2020, 2, 24),
            Featured: false,
            IconClass: 'fig-web',
            Title: 'Sign Up for Textedly',
            Url: 'https://www.textedly.com/create-account?subscription_plan=free'
          },
          {
            Order: 20,
            Date: new Date(2020, 2, 24),
            Featured: false,
            IconClass: 'fig-file-text',
            Title: 'Learn more about CallFire',
            Url: 'https://youtu.be/ANzh_YCo6qY'
          },
          {
            Order: 30,
            Date: new Date(2020, 2, 24),
            Featured: false,
            IconClass: 'fig-web',
            Title: 'View CallFire user guide',
            Url: 'https://www.callfire.com/help/docs/getting-started/welcome-to-callfire'
          }]
      },
      {
        Order: 20,
        Title: 'Virtual Appointments',
        Date: new Date(2020, 2, 31),
        ContentType: 'text',
        Description: 'Use tools such as Zoom or GoToMeeting to have a virtual appointment with your prospect or client. These tools allow you to video conference, share screens, and more.',
        Links:
          [
            {
              Order: 15,
              Date: new Date(2020, 3, 14),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download How to Conduct Virtual Meetings & Appointments Guide',
              Url: '/v1/Documents/C19-Virtual_Meetings_Guide-2.pdf'
            },
            {
              Order: 20,
              Date: new Date(2020, 2, 31),
              Featured: false,
              IconClass: 'fig-play',
              Title: 'Watch Zoom Tutorials',
              Url: 'https://support.zoom.us/hc/en-us/articles/206618765-Zoom-Video-Tutorials'
            },
            {
              Order: 25,
              Date: new Date(2020, 2, 31),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Sign Up For Zoom',
              Url: 'https://zoom.us/signup'
            },
            {
              Order: 30,
              Date: new Date(2020, 2, 31),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Learn About GoTo Meeting',
              Url: 'https://www.youtube.com/watch?v=HKJ-Ei4z8k8'
            },
            {
              Order: 35,
              Date: new Date(2020, 2, 31),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Sign Up for a Free Trial of GoTo Meeting',
              Url: 'https://www.gotomeeting.com/try'
            },
            {
              Order: 40,
              Date: new Date(2020, 2, 31),
              Featured: false,
              IconClass: 'fig-file-text',
              Title: 'Download How to Video Conference Like a Pro Guide',
              Url: '/v1/Documents/How_To_Video_Conference_Like_A_Pro.pdf'
            }
          ]
      },
      {
        Order: 30,
        Title: 'Sharing Files Securely with Clients',
        Date: new Date(2020, 3, 22),
        ContentType: 'text',
        Description: 'Utilize a tool such as Dropbox to allow your clients to upload documents securely without having to meet in-person.',
        Links:
          [
            {
              Order: 0,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Learn More About Dropbox',
              Url: 'https://youtu.be/WRrBE28KTXI'
            },
            {
              Order: 5,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Sign Up for Dropbox',
              Url: 'https://www.dropbox.com/basic'
            },
            {
              Order: 10,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'View Dropbox Tutorials',
              Url: 'https://help.dropbox.com/learn/video-tutorials#save'
            },
            {
              Order: 15,
              Date: new Date(2020, 2, 24),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Learn how to send a secure upload link',
              Url: 'https://help.dropbox.com/files-folders/share/create-file-request'
            },
            {
              Order: 20,
              Date: new Date(2020, 3, 22),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Learn how to Scan Using Android Phone',
              Url: 'https://www.theandroidsoul.com/how-to-scan-documents-and-store-them-online-on-android/'
            },
            {
              Order: 25,
              Date: new Date(2020, 3, 22),
              Featured: false,
              IconClass: 'fig-web',
              Title: 'Learn how to Scan Using iPhone and iPad',
              Url: 'https://www.imore.com/how-use-document-scanner-iphone-and-ipad#marking-up-scanned-documents'
            }
          ]
      }
      ]
    },
    {
      Order: 40,
      Title: 'Applications',
      SubCategories: [
        {
          Order: 0,
          Title: 'e-Applications',
          Date: new Date(2020, 3, 3),
          ContentType: 'text',
          Description: 'E-Applications are a streamlined way to conduct business electronically. Alleviate in-person meeting stress and virtually eliminate NIGOs.',
          Links:
            [
              {
                Order: 0,
                Date: new Date(2020, 3, 3),
                Featured: false,
                IconClass: 'fig-play-circle',
                Title: 'Watch FireLight Tutorial (Annuities/Life)',
                Url: 'https://vimeo.com/252203149/def6d69d30'
              },
              {
                Order: 5,
                Date: new Date(2020, 3, 3),
                Featured: false,
                IconClass: 'fig-play-circle',
                Title: 'Watch iPipeline Tutorial (Life/LTC)',
                Url: 'https://www.customerportal.ipipeline.com/knowledgebase/igo-overview/?print=print'
              },
              {
                Order: 10,
                Date: new Date(2020, 3, 3),
                Featured: false,
                IconClass: 'fig-file-text',
                Title: 'Download FIG\'s Comprehensive eapp Guide Here',
                Url: '/v1/Documents/eAppGuide.pdf'
              }
            ]
        },
        {
          Order: 10,
          Title: 'Virtually Sign an e-App',
          Date: new Date(2020, 2, 24),
          ContentType: 'text',
          Description: 'Utilizing e-applications, you are able to email your client to sign the application if an in-person meeting is not possible.',
          Links:
            [
              {
                Order: 10,
                Date: new Date(2020, 2, 24),
                Featured: false,
                IconClass: 'fig-play-circle',
                Title: 'Learn How with FireLight',
                Url: 'https://vimeo.com/263943487/460ad2f984'
              },
              {
                Order: 20,
                Date: new Date(2020, 2, 24),
                Featured: false,
                IconClass: 'fig-play-circle',
                Title: 'Learn How with iPipeline',
                Url: 'https://fig.wistia.com/medias/fl3k3wzv64'
              }
            ]
        },
        {
          Order: 20,
          Title: 'Life & LTC Paramed Vendor Updates',
          Date: new Date(2020, 2, 24),
          ContentType: 'text',
          Description: 'Below are updates and alerts from our main paramed vendor partners.',
          Links:
            [
              {
                Order: 10,
                Date: new Date(2020, 2, 24),
                Featured: false,
                IconClass: 'fig-web',
                Title: 'View for ExamOne’s Response',
                Url: 'https://www.examone.com/examone-response-to-coronavirus-disease-2019-covid-19/'
              },
              {
                Order: 20,
                Date: new Date(2020, 2, 24),
                Featured: false,
                IconClass: 'fig-web',
                Title: 'View APPS Information',
                Url: 'https://appslive.com/'
              },
              {
                Order: 30,
                Date: new Date(2020, 2, 24),
                Featured: false,
                IconClass: 'fig-web',
                Title: 'View EMSI’s Response',
                Url: 'https://www.emsinet.com/company-alerts/'
              }
            ]
        },
        {
          Order: 0,
          Title: 'Docusign',
          Date: new Date(2020, 2, 26),
          ContentType: 'text',
          Description: 'Getting set up with a Docusign account will allow you to transfer your current paperwork to send for clients for e-signature. Note: Please ensure the carrier or company you are working with will accept a Docusign eSignature.',
          Links:
            [
              {
                Order: 0,
                Date: new Date(2020, 2, 26),
                Featured: false,
                IconClass: 'fig-web',
                Title: 'Sign Up for Docusign',
                Url: 'https://go.docusign.com/trial/productshot/?elqCampaignId=4481&utm_term=sign%20up%20for%20docusign&utm_campaign=branded_secondary&gclid=CjwKCAjwguzzBRBiEiwAgU0FT9WT8rkxafyktXrVGvJ-ilbSAb3pLmugVXuPhVi02Yc3Nh-Q_0NFFRoChhcQAvD_BwE&utm_source=google&utm_medium=cpc&utm_content=domestic_US&rflag=1'
              },
              {
                Order: 5,
                Date: new Date(2020, 2, 26),
                Featured: false,
                IconClass: 'fig-web',
                Title: 'Visit the Docusign Support Center',
                Url: 'https://support.docusign.com/?_ga=2.44526308.1966305337.1585167385-63027882.1585167385&_gac=1.259124088.1585167385.CjwKCAjwguzzBRBiEiwAgU0FT9WT8rkxafyktXrVGvJ-ilbSAb3pLmugVXuPhVi02Yc3Nh-Q_0NFFRoChhcQAvD_BwE'
              }
            ]
        }]
    },
    {
      Order: 50,
      Title: 'Business Process Optimization',
      SubCategories:
        [
          {
            Order: 0,
            Title: 'Engaging with Office Staff',
            Date: new Date(2020, 2, 24),
            ContentType: 'text',
            Description: 'Operating a business 100% virtually is a difficult task. Learn more on best practices for how to handle your business in this new time.',
            Links:
              [
                {
                  Order: 0,
                  Date: new Date(2020, 2, 24),
                  Featured: false,
                  IconClass: 'fig-file-text',
                  Title: 'Learn 9 Tips to Engage and Communicate with Remote Staff Members',
                  Url: '/v1/Documents/9_Tips_to_Engage.pdf'
                },
                {
                  Order: 10,
                  Date: new Date(2020, 2, 24),
                  Featured: false,
                  IconClass: 'fig-play-circle',
                  Title: 'Learn how to use Slack for real time communication with remote teams',
                  Url: 'https://youtu.be/EYqxQGmQkVw'
                }
              ]
          }
        ]
    }
  ];
}
