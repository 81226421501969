export enum CpcPolicyStatusEnum {
  Pending = 'Pending',
  Posted = 'Posted',
}

export enum CpcPolicyPropertyNameEnum {
  Agent = 'Agent',
  StatementDate = 'Statement Date',
  Clients = 'Clients',
  Carrier = 'Carrier',
  Product = 'Policy',
  Premium = 'Premium',
  CPCs = 'CPCs',
}