<section class="flex flex-col w-full h-full bg-center bg-no-repeat bg-cover bg-gray-wave"
         [class]="!isDetailsPage && isAcrBasedReport ? 'p-20 md:p-30' : 'overflow-auto'"
         @fadeInOut>
  <div class="flex flex-col w-full h-full" [class]="!isDetailsPage && isAcrBasedReport ? 'overflow-hidden card max-w-1920 mx-auto' : ''">
    <div class="flex-grow h-0 overflow-auto" [class]="!isDetailsPage ? '' : 'flex justify-center'"
         id="business-container-content" #businessContainerContent>
      <router-outlet (activate)="onActivate($event)">
      </router-outlet>
    </div>
  </div>
</section>