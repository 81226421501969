import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskNoteV2 } from '../../models/policy-v2.models';

@Component({
  selector: 'app-new-agent-message',
  templateUrl: './new-agent-message.component.html',
  styles: [
  ]
})
export class NewAgentMessageComponent {
  constructor(
    private dialogRef: MatDialogRef<NewAgentMessageComponent, TaskNoteV2>,
    @Inject(MAT_DIALOG_DATA) public policyGuid?: string,
  ) { }

  closeDialog(formValue?: TaskNoteV2): void {
    this.dialogRef.close(formValue);
  }
}
