@if (data) {
  <section class="flex flex-col w-full h-full overflow-hidden">
    <section class="flex items-center flex-none border-b p-30 border-gray-lighter">
      <h1 class="text-lg font-bold">Policy Updates</h1>
      <button class="ml-auto icon-button is-primary is-inverted"
        matTooltip="Close Dialog"
        mat-button
        type="button"
        mat-dialog-close>
        <i class="material-icons">close</i>
      </button>
    </section>
    <section class="flex items-center flex-none py-10 border-b px-30 border-gray-lighter">
      <a [routerLink]="data.detailsUrl"
        mat-dialog-close
        class="ml-auto button is-sm is-primary is-inverted"
        type="button"
        mat-button>
        <span class="flex items-center justify-center gap-x-5">
          <span>Go to Policy</span>
          <i class="material-icons mi-18">east</i>
        </span>
      </a>
    </section>
    <section class="flex-grow overflow-auto">
      <app-policy-requirements-v2 class="block h-full"
        [modalView]="true"
      [policyGuid]="data.policyGuid"></app-policy-requirements-v2>
    </section>
  </section>
}