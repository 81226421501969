<form [formGroup]="agentForm"
  (ngSubmit)="onSubmit()"
  class="flex flex-wrap p-20 gap-x-10 gap-y-10">
  <textarea placeholder="Enter your message..."
    class="w-full py-5 bg-opacity-75 border-none textarea bg-gray-lightest"
    formControlName="notes"
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    cdkAutosizeMinRows="2"
  cdkAutosizeMaxRows="8"></textarea>
  <div class="flex items-center w-full gap-x-10">
    <button class="ml-auto button is-sm is-primary is-outlined"
      mat-button
      type="button">
      <input tabindex="-1"
        type="file"
        multiple
        accept=".pdf,.png,.jpg,.jpeg"
        (change)="onFileUpload($event)"
        class="absolute top-0 left-0 w-full h-full opacity-0" />
        <span class="flex items-center justify-center gap-x-5">
          <span>Attachments</span>
          <i class="material-icons mi-18">attach_file</i>
        </span>
      </button>
      <button [disabled]="formSubmitting || agentForm.invalid"
        class="button is-sm is-primary"
        mat-button
        type="submit">
        <span class="flex items-center justify-center gap-x-5">
          <span>Send</span>
          <i class="material-icons mi-18">send</i>
        </span>
      </button>
    </div>
    @if (agentForm.value.files?.length) {
      <ul class="grid w-full grid-cols-2 pt-10 gap-x-10 gap-y-10">
        @for (file of agentForm.value.files; track file; let i = $index) {
          <li
            class="flex items-center w-full p-5 overflow-hidden text-sm font-bold rounded gap-x-5 text-gray bg-gray-lightest whitespace-nowrap">
            <i class="material-icons mi-18">description</i>
            <span class="overflow-hidden overflow-ellipsis">{{file.name}}</span>
            <button class="ml-auto icon-button is-sm is-warning is-inverted"
              mat-button
              matTooltip="Remove file"
              type="button"
              (click)="removeFile(agentForm.value.files, i)">
              <i class="material-icons mi-18">close</i>
            </button>
          </li>
        }
      </ul>
    }
  </form>