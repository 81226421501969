import { TableHeader } from 'src/app/shared/classes/table';
import { InformalTablePolicy } from '../models/informalTablePolicy.model';


export const InformalTableHeadersV2: TableHeader<InformalTablePolicy>[] = [
  {
    DisplayName: 'Clients',
    ColumnName: ['Clients'],
    WidthClass: 'w-1/4',
    CompareFn: (a: InformalTablePolicy, b: InformalTablePolicy, order: 1 | -1): number => {
      const valA = a.RelatedPeople ?? '';
      const valB = b.RelatedPeople ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Face Amount',
    ColumnName: ['FaceAmount'],
    WidthClass: 'w-1/4',
    CompareFn: (a: InformalTablePolicy, b: InformalTablePolicy, order: 1 | -1): number => {
      const valA = a.FaceAmount;
      const valB = b.FaceAmount;
      if (valA != null && valB != null) return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
      if (valA === valB) return 0;
      return (valA === null ? -1 : 1) * order;
    },
  },
  {
    DisplayName: 'State',
    ColumnName: ['State'],
    WidthClass: 'w-1/4',
    CompareFn: (a: InformalTablePolicy, b: InformalTablePolicy, order: 1 | -1): number => {
      const valA = a.State ?? '';
      const valB = b.State ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Latest Status',
    ColumnName: ['Status'],
    WidthClass: 'w-1/4',
    CompareFn: (a: InformalTablePolicy, b: InformalTablePolicy, order: 1 | -1): number => {
      const valA = a.Status ?? '';
      const valB = b.Status ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  }
];