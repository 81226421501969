@if (policyDetails) {
  <section
    class="flex flex-col gap-y-30 p-30">
    <section class="flex flex-col gap-y-20">
      <h2 class="text-sm font-bold uppercase text-gray">Policy Summary</h2>
      <div class="flex flex-col text-sm font-bold rounded-lg gap-y-15">
        <div class="flex items-center gap-x-10">
          <span class="material-icons mi-18 text-gray">work</span>
          <div class="flex flex-col">
            <span class="text-xs font-medium text-gray">Policy Info</span>
            <span>{{policyDetails.Carrier}} - {{policyDetails.Product}}</span>
            @if (policyDetails.PolicyNumber) {
              <span>({{policyDetails.PolicyNumber}})</span>
            }
          </div>
        </div>
        @if (policyDetails.LineOfBusiness) {
          <div class="flex items-center gap-x-10">
            <span class="material-icons mi-18 text-gray">health_and_safety</span>
            <div class="flex flex-col">
              <span class="text-xs font-medium text-gray">Line of Business</span>
              <span>{{policyDetails.LineOfBusiness}}</span>
            </div>
          </div>
        }
        @if (policyDetails.Clients) {
          <div class="flex items-center gap-x-10">
            <span class="material-icons mi-18 text-gray">people</span>
            <div class="flex flex-col">
              <span class="text-xs font-medium text-gray">Clients</span>
              <span>{{policyDetails.Clients}}</span>
            </div>
          </div>
        }
        @if (policyDetails.FaceAmount) {
          <div class="flex items-center gap-x-10">
            <span class="material-icons mi-18 text-gray">monetization_on</span>
            <div class="flex flex-col">
              <span class="text-xs font-medium text-gray">Face Amount</span>
              <span>{{policyDetails.FaceAmount | currency}}</span>
            </div>
          </div>
        }
        @if (policyDetails.IssuedTotalFirstYearPremium) {
          <div class="flex items-center gap-x-10">
            <span class="material-icons mi-18 text-gray">monetization_on</span>
            <div class="flex flex-col">
              <span class="text-xs font-medium text-gray">Total First Year Premium</span>
              <span>{{policyDetails.IssuedTotalFirstYearPremium | currency}}</span>
            </div>
          </div>
        }
      </div>
    </section>
    <!-- TODO: This does not take into account Disability like the old version of this UI -->
    @if (policyDetails.StageHistoryObject) {
      <section class="flex flex-col gap-y-20">
        <h2 class="text-sm font-bold uppercase text-gray">Case Insights</h2>
        <ul class="flex flex-col gap-y-10">
          <li class="flex items-center gap-x-10">
            <span class="material-icons mi-18"
              [class]="policyDetails.StageHistoryObject[0] ? 'text-green' : 'text-gray'">
              {{policyDetails.StageHistoryObject[0] ? 'check_circle' :
              'radio_button_unchecked'}}
            </span>
            <div class="flex flex-col">
              <h3 class="text-sm font-bold capitalize">Application Signed</h3>
              <span class="text-xs font-semibold label text-gray">{{ policyDetails.StageHistoryObject[0]
                ? (policyDetails.StageHistoryObject[0].PolicyStageDate | date: 'MM/dd/yy' : 'UTC') :
              '--'}}</span>
            </div>
          </li>
          <li class="flex items-center gap-x-10">
            <span class="material-icons mi-18"
              [class]="policyDetails.StageHistoryObject[1] ? 'text-green' : 'text-gray'">
              {{policyDetails.StageHistoryObject[1] ? 'check_circle' :
              'radio_button_unchecked'}}
            </span>
            <div class="flex flex-col">
              <h3 class="text-sm font-bold capitalize">Received At FIG</h3>
              <span class="text-xs font-semibold label text-gray">{{ policyDetails.StageHistoryObject[1]
                ? (policyDetails.StageHistoryObject[1].PolicyStageDate | date: 'MM/dd/yy' : 'UTC') :
              '--'}}</span>
            </div>
          </li>
          <li class="flex items-center gap-x-10">
            <span class="material-icons mi-18"
              [class]="policyDetails.StageHistoryObject[2] ? 'text-green' : 'text-gray'">
              {{policyDetails.StageHistoryObject[2] ? 'check_circle' :
              'radio_button_unchecked'}}
            </span>
            <div class="flex flex-col">
              <h3 class="text-sm font-bold capitalize">Submitted</h3>
              <span class="text-xs font-semibold label text-gray">{{ policyDetails.StageHistoryObject[2]
                ? (policyDetails.StageHistoryObject[2].PolicyStageDate | date: 'MM/dd/yy' : 'UTC') :
              '--'}}</span>
            </div>
          </li>
          <li class="flex items-center gap-x-10">
            <span class="material-icons mi-18"
              [class]="policyDetails.StageHistoryObject[3] ? 'text-green' : 'text-gray'">
              {{policyDetails.StageHistoryObject[3] ? 'check_circle' :
              'radio_button_unchecked'}}
            </span>
            <div class="flex flex-col">
              <h3 class="text-sm font-bold capitalize">Pending</h3>
              <span class="text-xs font-semibold label text-gray">{{ policyDetails.StageHistoryObject[3]
                ? (policyDetails.StageHistoryObject[3].PolicyStageDate | date: 'MM/dd/yy' : 'UTC') :
              '--'}}</span>
            </div>
          </li>
          <li class="flex items-center gap-x-10">
            <span class="material-icons mi-18"
              [class]="policyDetails.StageHistoryObject[4] ? 'text-green' : 'text-gray'">
              {{policyDetails.StageHistoryObject[4] ? 'check_circle' :
              'radio_button_unchecked'}}
            </span>
            <div class="flex flex-col">
              <h3 class="text-sm font-bold capitalize">Approved</h3>
              <span class="text-xs font-semibold label text-gray">{{ policyDetails.StageHistoryObject[4]
                ? (policyDetails.StageHistoryObject[4].PolicyStageDate | date: 'MM/dd/yy' : 'UTC') :
              '--'}}</span>
            </div>
          </li>
          <li class="flex items-center gap-x-10">
            <span class="material-icons mi-18"
              [class]="policyDetails.StageHistoryObject[5] ? 'text-green' : 'text-gray'">
              {{policyDetails.StageHistoryObject[5] ? 'check_circle' :
              'radio_button_unchecked'}}
            </span>
            <div class="flex flex-col">
              <h3 class="text-sm font-bold capitalize">Issued</h3>
              <span class="text-xs font-semibold label text-gray">{{ policyDetails.StageHistoryObject[5]
                ? (policyDetails.StageHistoryObject[5].PolicyStageDate | date: 'MM/dd/yy' : 'UTC') :
              '--'}}</span>
            </div>
          </li>
          <li class="flex items-center gap-x-10">
            <span class="material-icons mi-18"
              [class]="policyDetails.StageHistoryObject[6] ? 'text-green' : 'text-gray'">
              {{policyDetails.StageHistoryObject[6] ? 'check_circle' :
              'radio_button_unchecked'}}
            </span>
            <div class="flex flex-col">
              <h3 class="text-sm font-bold capitalize">Inforce</h3>
              <span class="text-xs font-semibold label text-gray">{{ policyDetails.StageHistoryObject[6]
                ? (policyDetails.StageHistoryObject[6].PolicyStageDate | date: 'MM/dd/yy' : 'UTC') :
              '--'}}</span>
            </div>
          </li>
        </ul>
      </section>
    }
    @if (policyFundings) {
      <section class="flex flex-col gap-y-20">
        <h2 class="text-sm font-bold uppercase text-gray">Funding</h2>
        <ul class="flex flex-col gap-y-10">
          @for (fund of policyFundings; track fund) {
            <li class="flex items-center gap-x-10"
              >
              <span class="material-icons mi-18 text-gray">
                {{fund.IssuedPremiumAmount? 'check_circle' : 'radio_button_unchecked'}}
              </span>
              <div class="flex flex-col">
                <h3 class="text-base font-bold capitalize">
                  {{(fund.IssuedPremiumAmount ? fund.IssuedPremiumAmount: fund.Amount) | currency}}
                  @if (fund.FrequencyId !== frequencyId.Single && fund.FrequencyId !== frequencyId.Unknown) {
                    ({{fund.Frequency}})
                  }
                </h3>
                <span class="text-xs font-semibold label text-gray">{{fund.Method}}</span>
              </div>
            </li>
          }
        </ul>
      </section>
    }
  </section>
}