<section class="flex flex-col w-full h-full mx-auto max-w-1440 p-30 gap-y-20">
  <a routerLink="/Portal/Business/Fixed/Informal"
    class="mr-auto text-white button is-sm"
    type="button"
    mat-button>
    <span class="flex items-center justify-center gap-x-5 text-blue-dark">
      <i class="material-icons mi-18">west</i>
      <span>Back to list view</span>
    </span>
  </a>
  <mat-sidenav-container class="h-full card">
    <mat-sidenav-content class="flex flex-col overflow-hidden">
      <section class="flex items-center border-b gap-x-10 p-30 border-gray-lighter">
        <h1 class="mr-auto text-lg font-bold">
          @if (informalPolicy) {
            <span>Informal Application - {{informalPolicy.Clients}}
            </span>
          }
          @else {
            <span>Policy Details</span>
          }
        </h1>
        <button mat-button type="button"
          class="button is-primary is-inverted"
          (click)="policySummary.toggle()">
          <span class="flex items-center justify-center gap-x-5">
            <span>{{policySummary.opened ? 'Hide Summary' : 'Show Summary'}}</span>
            <i class="material-icons mi-18">visibility</i>
          </span>
        </button>
      </section>
      <!-- dynamic http data can be inserted inside this ng-container -->
      @if (!isLoading) {
        <div class="flex flex-col flex-none p-10 border-b border-gray-lighter px-30">
          <div class="flex items-center justify-between gap-x-20">
            <mat-slide-toggle (ngModelOptions)="{debounce: 100}"
              [(ngModel)]="showCompleted"
              (ngModelChange)="filterCategories()">Show Completed Items
            </mat-slide-toggle>
          </div>
        </div>
        <section class="flex flex-col flex-none h-full overflow-hidden">
          @if (!isLoading) {
            <section class="flex-grow overflow-auto bg-gradient-to-b from-gray-lightest to-white">
              <div class="flex flex-col w-full h-full p-20 mx-auto gap-y-30 max-w-640">
                <!-- Requirements/checklist/updates/ view. Includes Action Needed -->
                <mat-accordion multi
                  @fadeIn>
                  <!-- <ng-container *ngIf="informalPolicy?.Notes?.length">
                  <mat-expansion-panel @fadeIn
                    #expansionPanel class="my-0 bg-transparent shadow-none text-gray-dark"
                    hideToggle
                    expanded>
                    <mat-expansion-panel-header class="h-48 px-10">
                      <div class="flex items-center justify-between w-full px-0 gap-x-20">
                        <h2 class="font-bold">Carrier List</h2>
                        <span class="flex items-center justify-center gap-x-5 text-blue-dark">
                          <span class="text-xs font-bold uppercase">{{expansionPanel.expanded ? 'Collapse' : 'Expand'}}</span>
                          <i class="material-icons mi-18">{{expansionPanel.expanded ? 'unfold_less' : 'unfold_more'}}</i>
                        </span>
                      </div>
                    </mat-expansion-panel-header>
                    <ul class="flex flex-col p-10">
                      <button role="button" type="button"
                        @fadeIn
                        *ngFor="let note of informalPolicy?.Notes; let first = first; let last = last; let i = index"
                        class="flex h-auto p-20 font-normal leading-normal text-left normal-case rounded-none card gap-x-10 focus:ring-0 hover:bg-opacity-25"
                        [ngClass]="{'rounded-t' : first, 'rounded-b': last,'border-b border-gray-lighter' : !last}">
                        <div class="flex flex-col flex-grow gap-y-10">
                          <p class="text-sm">{{note.Notes}}</p>
                        </div>
                        <div class="flex flex-col flex-none gap-y-10">
                          <div class="text-sm text-right text-gray">{{note.CreatedOn | date: 'MM/dd/yy'}}</div>
                        </div>
                      </button>
                    </ul>
                  </mat-expansion-panel>
                </ng-container> -->
                @for (sub of subcategories; track sub) {
                  @if (sub.InformalRequirements.length) {
                    <mat-expansion-panel
                      #expansionPanel
                      class="my-0 bg-transparent shadow-none text-gray-dark"
                      hideToggle
                      expanded>
                      <mat-expansion-panel-header class="h-48 px-10">
                        <div class="flex items-center justify-between w-full px-0 gap-x-20">
                          <h2 class="font-bold">{{ sub.Name + ' (' + sub.InformalRequirements.length + ')'}}</h2>
                          <span class="flex items-center justify-center gap-x-5 text-blue-dark">
                            <span class="text-xs font-bold uppercase">{{expansionPanel.expanded ? 'Collapse' : 'Expand'}}</span>
                            <i class="material-icons mi-18">{{expansionPanel.expanded ? 'unfold_less' : 'unfold_more'}}</i>
                          </span>
                        </div>
                      </mat-expansion-panel-header>
                      <ul class="flex flex-col p-10">
                        @for (req of sub.InformalRequirements; track req; let first = $first; let last = $last; let i = $index) {
                          <button role="button" type="button"
                            class="flex h-auto p-20 font-normal leading-normal text-left normal-case rounded-none card gap-x-10 focus:ring-0 hover:bg-opacity-25"
                            [ngClass]="{'rounded-t' : first, 'rounded-b': last, 'border-b border-gray-lighter' : !last}">
                            <div class="flex flex-col flex-grow whitespace-pre-line gap-y-10">
                              <h3 class="text-sm font-bold">{{req.Name}}</h3>
                              <p class="text-sm" [innerHTML]="req.Note"></p>
                              <p class="text-sm" [innerHTML]="req.Description"></p>
                            </div>
                            <div class="flex flex-col flex-none gap-y-10">
                              <div class="text-sm text-right text-gray">{{req.ModifiedOn | date: 'M/dd/yy'}}</div>
                              <!-- <div class="text-sm font-bold text-right text-blue-dark">{{subcat.Notes.length}} Update(s)</div> -->
                            </div>
                          </button>
                        }
                      </ul>
                    </mat-expansion-panel>
                  }
                }
              </mat-accordion>
              <!-- No applicable checklist items/updates -->
              @if (!informalPolicy?.Notes?.length && !subcategories.length) {
                <app-message-notification @fadeIn class="flex items-center my-20"
                >You have no notes at this time.</app-message-notification>
              }
              <!-- Error message box -->
              <!-- <app-message-notification @fadeIn
              *ngIf="!loading && error.hasError"
            [panelConfig]="messageConfig">{{error.message}}</app-message-notification> -->
          </div>
        </section>
      }
    </section>
  } @else {
    <img @fadeIn src="./assets/lottie/static/policy-details-v2.svg" alt="Loading placeholder" class="animate-pulse">
  }
</mat-sidenav-content>
<mat-sidenav class="bg-white w-300"
  #policySummary
  mode="side"
  position="end"
  [opened]="true">
  @if (!isLoading) {
    <app-informal-policy-summary
      @fadeIn
    [informalPolicy]="informalPolicy"></app-informal-policy-summary>
  }
</mat-sidenav>
</mat-sidenav-container>
</section>