import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// Logo displayed in console
// eslint-disable-next-line no-useless-escape
console.log('             #\r\n            ### \r\n           #####\r\n          #######\r\n         #########\/\\\r\n     __ #\/\\######\/  \\  (R)\r\n    \/ _\\#\\\/#####\/ \/\\ \\ \r\n    \\ \\####\/\\##\/ \/##\\ \\ \r\n     \\ \\\/\/#\\ \\#\\ \\##\/  \\\r\n    ##\\ \\###\\ \\#\\ \\\/ \/\\ \\ \r\n   ##\/\/\\ \\###\\ \\#\\  \/##\\ \\\r\n  ######\\ \\###\\\/##\\\/####\\ \\\r\n ########\\ \\#######\\______\/\r\n##########\\ \\##############\r\n          \/_\/');