@if (!isLoading) {
  <section @fadeIn>
    <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openExternalInterestForm()">
      Show Interest
    </button>
    <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openPurchaseDialog()"
      [disabled]="!!purchased && purchased.Complete">
      @if (!purchased) {
        <span>
          Purchase
        </span>
      }
      @else if (purchased && purchased.Complete) {
        <span>
          Purchased On {{purchased.PurchaseDate}}
        </span>
      }
    </button>
    @if (purchased && purchased.Complete) {
      <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openDocusignLink()"
        >
        <span>
          Sign Power of Zero Plus Agreement
        </span>
      </button>
    }
    <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openExternalLoginLink()"
      *showIfOne="'PowerOfZero:Access'">
      <span>
        Log Into Power of Zero
      </span>
    </button>
  </section>
}