import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { fadeIn } from 'src/app/animations';
import { RedtailApiService } from 'src/app/core/services/http/redtail-api.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { ManageIntegrationsEnum } from 'src/app/shared/enums/integrations.enum';
import { ManageIntegrationResModel } from 'src/app/shared/models/intergrations.models';
import { RedtailContact } from 'src/app/shared/models/redtail-contact.models';
import { ManageIntegrationsModalComponent } from '../../manage-integrations-modal.component';

@Component({
  animations: [fadeIn],
  selector: 'app-redtail-integration',
  templateUrl: './redtail-integration.component.html'
})
export class RedtailIntegrationComponent implements OnInit {

  constructor(
    /** return true if user is connected */
    private dialogRef: MatDialogRef<ManageIntegrationsModalComponent, ManageIntegrationResModel>,
    private fb: FormBuilder,
    private redtailApiService: RedtailApiService,
    private snackbarService: SnackbarService,
    private sessionStore: SessionStoreService,
  ) { }

  isRedtailAuthenticated = false;
  isSubmitting = false;
  redtailAuthForm = this.fb.group({
    username: this.fb.nonNullable.control('', Validators.required),
    password: this.fb.nonNullable.control('', Validators.required),
  });
  redtailContacts: RedtailContact[] = [];

  ngOnInit(): void {
    this.setupForm();
  }

  get usernameCtrl(): AbstractControl<string> {
    return this.redtailAuthForm.controls.username;
  }

  get passwordCtrl(): AbstractControl<string> {
    return this.redtailAuthForm.controls.password;
  }

  setupForm(): void {
    this.isSubmitting = true;
    this.redtailApiService.isUserAuthenticated()
      .subscribe({
        next: res => {
          this.isRedtailAuthenticated = res;
          if (res) this.redtailAuthForm.disable();
          this.isSubmitting = false;
        },
        error: () => {
          this.isRedtailAuthenticated = false;
          this.isSubmitting = false;
        }
      });
  }

  authenticateWithRedTail(): void {
    let message = '';
    let toastClass = ToastClassEnum.default;

    if (this.redtailAuthForm.valid) {
      this.isSubmitting = true;
      const username = this.redtailAuthForm.value.username || '';
      const password = this.redtailAuthForm.value.password || '';
      this.redtailApiService.authenticate(username, password)
        .subscribe({
          next: () => {
            this.onFormSubmission(true);
            message = 'You have successfully logged into Redtail.';
            toastClass = ToastClassEnum.success;
          },
          error: () => {
            message = 'There was an error logging into Redtail. Please try again later.';
            toastClass = ToastClassEnum.warning;
          }
        })
        .add(() => {
          this.snackbarService.openSnackbar(message, toastClass);
          this.isSubmitting = false;
        });
    }
  }

  onFormSubmission(isConnected: boolean): void {
    this.isRedtailAuthenticated = isConnected;
    this.sessionStore.setUserRedtailStatus(isConnected);
    if (isConnected) this.redtailAuthForm.disable();
    else this.redtailAuthForm.enable();
    this.dialogRef.close({
      isConnected: isConnected,
      crmIntegration: ManageIntegrationsEnum.Redtail,
    });
  }

  deactivateRedTail(): void {
    this.isSubmitting = true;
    this.redtailApiService.removeAuthentication()
      .subscribe({
        next: () => {
          this.isSubmitting = false;
          this.onFormSubmission(false);
          this.snackbarService.openSnackbar('Deactivated successfully!', ToastClassEnum.success);
        },
        error: () => this.snackbarService.openSnackbar('Error removing authentication.', ToastClassEnum.warning)
      });
  }
}