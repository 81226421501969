<section class="flex flex-col w-full h-full p-20 mx-auto max-w-1440 md:p-30" @fadeIn>
  <a class="mb-10 mr-auto button is-sm is-primary is-inverted"
    mat-button
    (click)="goBack()" (keypress)="goBack()">
    <i class="mr-5 no-underline fig-24px fig-arrow-left"></i>
    <span class="">Back to List View</span>
  </a>
  <section class="grid grid-cols-2 gap-x-20 gap-y-20">
    <div class="flex col-span-2 text-sm font-bold rounded-lg card">
      <div class="flex items-center m-20 md:mx-40" matTooltip="Carriers">
        <span class="mr-10 material-icons text-gray mi-18">work</span>
        <div class="flex flex-col">
          <span class="text-semibold">{{informalPolicy.Carrier}}</span>
        </div>
      </div>
      @if (informalPolicy.RelatedPeople) {
        <div class="flex items-center m-20 md:mx-40"
          matTooltip="Related People">
          <span class="mr-10 material-icons text-gray">people</span>
          <span>{{informalPolicy.RelatedPeople}}</span>
        </div>
      }
      @if (informalPolicy.FaceAmount) {
        <div class="flex items-center m-20 md:mx-40" matTooltip="Face Amount">
          <span class="mr-10 material-icons text-gray">monetization_on</span>
          <span>{{informalPolicy.FaceAmount | currency}}</span>
        </div>
      }
      @if (informalPolicy.State) {
        <div class="flex items-center m-20 md:mx-40" matTooltip="State">
          <span class="mr-10 material-icons text-gray">place</span>
          <span>{{informalPolicy.State}}</span>
        </div>
      }
      @if (informalPolicy.AwaitingOffer) {
        <div class="flex items-center m-20 md:mx-40"
          matTooltip="Awaiting Offer">
          <span class="mr-10 material-icons text-gray">local_offer</span>
          <span>Awaiting Offer</span>
        </div>
      }
    </div>
    <div class="col-span-2 p-10 card">
      @if (!loadingRows) {
        <ul class="flex flex-col">
          @for (d of informalPolicyDetails.CarrierDetails; track d; let last = $last) {
            <li class="flex flex-col p-10" [ngClass]="{'border-b border-gray-lighter': !last}"
              >
              <div class="font-bold text-md">{{d.Carrier}}{{d.PolicyNumber ? ' - ' + d.PolicyNumber : ''}}</div>
              <div class="text-sm"><span class="font-bold">Health Classes:</span>
            {{d.HealthClasses}}{{d.HealthClassReason ? ' - ' + d.HealthClassReason : ''}}</div>
            <div class="text-sm"><span class="font-bold">Subject to:</span> {{d.OfferDetails}}</div>
          </li>
        }
      </ul>
    }
    @else {
      <div class="relative w-full p-20 loader-wrap" [@fadeIn]>
        <div class="loader-sm is-blue"></div>
      </div>
    }
  </div>
  </section>
</section>