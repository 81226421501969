<section class="flex flex-col w-full h-full overflow-hidden">
  <section class="flex items-center flex-none p-20 border-b gap-x-20 border-gray-lighter">
    <h1 class="text-lg font-bold">New Message</h1>
    <button class="ml-auto icon-button is-primary is-inverted"
            mat-button
            type="button"
            matTooltip="Close Dialog"
            (click)="closeDialog(undefined)">
      <i class="material-icons">close</i>
    </button>
  </section>
  <app-agent-message-input (formSubmitEvent)="closeDialog($event)"
                           [policyGuid]="policyGuid"></app-agent-message-input>
</section>