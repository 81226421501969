/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import { fadeIn, fadeInOut } from 'src/app/animations';
import { AgentLicenseApiService } from 'src/app/core/services/http/agent-license-api.service';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { PolicyListStoreService } from 'src/app/core/services/stores/policy-list-store.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { policyLobs } from 'src/app/shared/constants/policyList.constants';
import { areLengthsEqual } from 'src/app/shared/helpers/areLengthsEqual.helper';
import { AgentLicenses, AgentSubAgent, AgentSubEntity } from 'src/app/shared/models/agent.models';
import { PolicyListFilter, PolicyLob } from 'src/app/shared/models/policy.models';
import { User } from 'src/app/shared/models/user.models';

@Component({
  animations: [fadeIn, fadeInOut],
  selector: 'app-policy-list-sidebar',
  templateUrl: './policy-list-sidebar.component.html',
})
export class PolicyListSidebarComponent implements OnInit {
  allAgentsSelected!: boolean;
  allLobsSelected!: boolean;
  agents!: AgentSubAgent[];
  subEntities!: AgentSubEntity[];
  lobs: PolicyLob[] = policyLobs;
  user!: User;
  isClearingPolicies!: boolean;
  selectedAgentIds!: string[];
  selectedSubEntities!: string[];
  selectedLobIds!: number[];
  selectedStartDate!: Date;
  selectedEndDate!: Date;
  queryStartDate!: Date;
  queryEndDate!: Date;
  agentLicenses!: AgentLicenses;
  commisionGroupsFilterOn = false;
  @Output() filterChangeEvent = new EventEmitter<PolicyListFilter>();
  @Input() reportType?: string;
  @Input() showDateFilter = false;


  constructor(private reportsApiService: ReportsApiService, private sessionStore: SessionStoreService,
    private policyListStore: PolicyListStoreService,
    private agentLicenseApiService: AgentLicenseApiService) { }

  ngOnInit(): void {
    if (this.reportType === 'Variable') {
      // Remove Disability Lob if we are in the Variable Business list
      this.lobs = this.lobs.filter(l => l.Name !== 'Disability');
    }
    this.user = this.sessionStore.User;
    this.getFilters();
    this.reportsApiService.getSubAgents()
      .subscribe((subAgents) => {
        this.agentLicenses = this.sessionStore.OnBehalfOfUser ? this.sessionStore.OnBehalfOfUser.AgentLicenses : this.sessionStore.User.AgentLicenses;
        this.setAgentList(subAgents);
        this.setLobList();
        this.sendFilters();
      });
    this.reportsApiService.getSubEntities()
      .subscribe((subEntities) => {
        this.setSubEntityList(subEntities);
      });
  }

  getFilters(): void {
    //Check to see if any current filters have been applied this session
    this.selectedAgentIds = this.policyListStore.getSelectedAgentIds() ? this.policyListStore.getSelectedAgentIds() : [this.user.AgentArcGuid];
    this.selectedSubEntities = this.policyListStore.getselectedSubEntities();
    this.selectedLobIds = this.policyListStore.getSelectedLobIds();
    this.selectedStartDate = this.policyListStore.getStartDate();
    this.selectedEndDate = this.policyListStore.getEndDate();
    this.queryStartDate = this.selectedStartDate;
    this.queryEndDate = this.selectedEndDate;
  }

  setPolicyStore(): void {
    // store current filters in service so they persist if user goes to another page
    this.policyListStore.setSelectedAgentIds(this.selectedAgentIds);
    this.policyListStore.setselectedSubEntities(this.selectedSubEntities);
    this.policyListStore.setSelectedLobIds(this.selectedLobIds);
    this.policyListStore.setStartDate(this.selectedStartDate);
    this.policyListStore.setEndDate(this.selectedEndDate);
  }

  setSubEntityList(subEntities: AgentSubEntity[]): void {
    if (subEntities.length) {
      this.subEntities = subEntities.map(subEntity => {
        return { ...subEntity, Selected: false };
      });
    }
  }

  setAgentList(subAgents: AgentSubAgent[]): void {
    if (subAgents.length) {
      this.agents = subAgents.map(subAgent => {
        return { ...subAgent, ...{ Selected: this.selectedAgentIds.includes(subAgent.ChildAgentGuid) ? true : false } };
      });
      this.agents = orderBy(this.agents, 'Firstname') as AgentSubAgent[];
    } else {
      this.agents = [];
    }

    this.agents.unshift(this.convertUserToAgent());
    this.setSelectAllIfNeeded();
  }

  setLobList(): void {
    this.lobs.forEach(lob => {
      if (this.selectedLobIds.includes(lob.Id)) lob.Selected = true;
    });
    this.setSelectAllIfNeeded();
  }

  setSelectAllIfNeeded(): void {
    this.allAgentsSelected = areLengthsEqual(this.selectedAgentIds, this.agents);
    this.allLobsSelected = areLengthsEqual(this.selectedLobIds, this.lobs);
  }

  /**checks and unchecks items in provide arrays based on id from  PolicyLob | AgentSubEntity | AgentSubAgent
   * 2023-05 Corey: unable to remove the 'item: any', after giving type unknown, as well as explicit types 'PolicyLob | AgentSubEntity | AgentSubAgent' which
   * each gave different compile errors, even when singling out type by properties. Successfully changed 'any[]' to 'unknown[]'.
   */
  toggleCheckbox(array: unknown[], selectedArray: unknown[], item: any): void {
    const id = item.ChildAgentGuid || item.AgentId || item.ChildCommissionGroupId || item.Id;
    const index = selectedArray.indexOf(id);

    if (item.Selected && index === -1) selectedArray.push(id);
    else selectedArray.splice(index, 1);

    if (item.ChildCommissionGroupId) {
      if (this.selectedAgentIds.length > 0) this.isClearingPolicies = true;
      this.selectedAgentIds = [];
      this.allAgentsSelected = false;
      this.agents.map((a) => a.Selected = false);
      this.commisionGroupsFilterOn = true;
    } else {
      if (this.selectedSubEntities.length > 0) this.isClearingPolicies = true;
      if (item.AgentId) {
        // if checkbox is for agent, reset all subEntities
        this.commisionGroupsFilterOn = false;
        this.selectedSubEntities = [];
        if (this.subEntities) {
          this.subEntities.map((se) => se.Selected = false);
        }
      }
    }

    this.setPolicyStore();
    this.setSelectAllIfNeeded();
    this.sendFilters();
  }

  /**2023-05 Corey: Could not change 'any[]' to 'unknown[]' as iteration through arrays calls on properties that may or may not exist */
  toggleAllCheckboxes(array: any[], selectedArray: any[], allSelected: boolean, checkboxType: string): void {
    if (allSelected) {
      if (this.selectedSubEntities.length > 0) {
        this.isClearingPolicies = true;
        this.commisionGroupsFilterOn = true;
      } else {
        this.commisionGroupsFilterOn = false;
      }
      array.forEach(item => {
        const id = item.ChildAgentGuid || item.AgentId || item.Id;
        const isDuplicate = selectedArray.indexOf(id) > -1;
        item.Selected = true;

        if (!isDuplicate) selectedArray.push(id);
      });

      this.selectedSubEntities = [];
      if (this.subEntities) {
        this.subEntities.map((se) => se.Selected = false);
      }
    } else {
      array.forEach(item => {
        item.Selected = false;
      });
      if (checkboxType === 'Agents') {
        this.selectedAgentIds = [];
      } else this.selectedLobIds = [];
      this.setSelectAllIfNeeded();
    }

    this.setPolicyStore();
    this.sendFilters();
  }

  sendFilters(): void {
    const filters: PolicyListFilter = {
      SelectedLobIds: this.selectedLobIds,
      SelectedAgentIds: this.selectedAgentIds,

      SelectedSubEntities: this.selectedSubEntities,
      IsClearingPolicies: this.isClearingPolicies,
      ParentAgentGuid: this.user.AgentArcGuid,

      StartDate: this.selectedStartDate,
      EndDate: this.selectedEndDate,
      AllAgentIds: this.agents.map(x => x.ChildAgentGuid),
      AllLobIds: this.lobs.map(x => x.Id),
      BaseStartDate: this.policyListStore.baseStartDate,
      BaseEndDate: this.policyListStore.baseEndDate,
      AllAgents: this.agents.map(x => {
        x.AgentId = x.ChildAgentGuid;
        return x;
      }),
      CommisionGroupsFilterOn: this.commisionGroupsFilterOn,
    };
    this.filterChangeEvent.emit(filters);

    this.isClearingPolicies = false;
  }

  convertUserToAgent(): AgentSubAgent {
    return {
      FirstName: this.sessionStore.OnBehalfOfUser ? this.sessionStore.OnBehalfOfUser.FirstName : this.user.FirstName,
      LastName: this.sessionStore.OnBehalfOfUser ? this.sessionStore.OnBehalfOfUser.LastName : this.user.LastName,
      AgentId: this.user.AgentArcGuid ?? null,
      CurrentUserTag: '(Me)',
      ChildAgentGuid: this.user.AgentArcGuid ?? null,
      ParentAgentGuid: null,
      Selected: this.selectedAgentIds.includes(this.user.AgentArcGuid) ? true : false,
      Npn: this.agentLicenses ? this.agentLicenses.Npn : null,
      CrdNumber: this.agentLicenses ? this.agentLicenses.Crd : null
    };
  }

  onDateRangeChange(): void {
    if (this.validDate(this.selectedStartDate) && this.validDate(this.selectedEndDate) && this.selectedEndDate > this.selectedStartDate) {
      this.setPolicyStore();
      this.sendFilters();
    }
  }

  validDate(d: Date | null): boolean {
    if (!d) return false;
    // Min/Max Dates for date picker. SQL's min/max dates
    const minDate = new Date(1754, 1, 1);
    const maxDate = new Date(9999, 12, 31);
    const day = (d || new Date());
    return day > minDate && day < maxDate;
  }
}
