import { TaskCategoryIDEnum } from '../enums/policy-v2.enums';
import { TaskCategory } from '../models/policy-v2.models';

export const TaskCategories: TaskCategory[] = [
  {
    TaskCategoryID: TaskCategoryIDEnum.cmNote,
    TaskCategory: 'CM Note',
    DisplayOrder: 1,
  },
  {
    TaskCategoryID: TaskCategoryIDEnum.agentContracting,
    TaskCategory: 'Agent Contracting',
    DisplayOrder: 2,
  },
  {
    TaskCategoryID: TaskCategoryIDEnum.application,
    TaskCategory: 'Application',
    DisplayOrder: 3,
  },
  {
    TaskCategoryID: TaskCategoryIDEnum.phoneHistoryInterview,
    TaskCategory: 'Phone History Interview (PHI)',
    DisplayOrder: 4,
  },
  {
    TaskCategoryID: TaskCategoryIDEnum.paramed,
    TaskCategory: 'Paramed',
    DisplayOrder: 5,
  },
  {
    TaskCategoryID: TaskCategoryIDEnum.attendingPhysicianStatement,
    TaskCategory: 'Attending Physician Statement (APS)',
    DisplayOrder: 6,
  },
  {
    TaskCategoryID: TaskCategoryIDEnum.underwriting,
    TaskCategory: 'Underwriting',
    DisplayOrder: 7,
  },
  {
    TaskCategoryID: TaskCategoryIDEnum.underwritingDecision,
    TaskCategory: 'Underwriting Decision',
    DisplayOrder: 8,
  },
  {
    TaskCategoryID: TaskCategoryIDEnum.issue,
    TaskCategory: 'Issue',
    DisplayOrder: 9,
  },
  {
    TaskCategoryID: TaskCategoryIDEnum.inforce,
    TaskCategory: 'Inforce',
    DisplayOrder: 10,
  },
  {
    TaskCategoryID: TaskCategoryIDEnum.carrierUpdate,
    TaskCategory: 'Carrier Update',
    DisplayOrder: 11,
  },
];