import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salesforce-auth-complete',
  templateUrl: './salesforce-auth-complete.component.html'
})
export class SalesforceAuthCompleteComponent implements OnInit {
  ngOnInit(): void {
    window.close();
  }
}
