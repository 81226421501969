import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntryResponse } from 'src/app/shared/models/generic.models';
import { LeaderboardList } from 'src/app/shared/models/leaderboard.model';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardApiService {

  constructor(private http:HttpClient) { }

  get():Observable<LeaderboardList>{
    return this.http.get<EntryResponse<LeaderboardList>>('/api/Leaderboards')
      .pipe(map((x:EntryResponse<LeaderboardList>) => x.Entry ));
  }

  getCommissionGroupLeaderboard(commissionGroupId: number):Observable<LeaderboardList>{
    return this.http.get<EntryResponse<LeaderboardList>>(`/api/Leaderboards/CommissionGroup/${commissionGroupId}`)
      .pipe(map((x:EntryResponse<LeaderboardList>) => x.Entry));
  }
}
