<mat-sidenav-container class="relative flex flex-grow h-full overflow-hidden bg-white" @fadeIn>
  <mat-sidenav #sidenav [mode]="sidenavConfig.Mode" [opened]="sidenavConfig.IsOpened" class="h-full bg-white shadow-none w-360"
    position="end">
    <div class="grid grid-cols-2 p-20 text-white bg-blue-900 gap-x-10 gap-y-20">
      <div class="flex items-center gap-x-10">
        <span class="flex items-center justify-center flex-none rounded-full w-36 h-36 bg-blue-dark">
          <span class="material-icons mi-18">local_atm</span>
        </span>
        <div class="flex flex-col">
          <span class="text-xs text-gray-light">Average Premium:</span>
          <span class="text-lg font-bold">{{averagePremium | shortNumberSuffix: 2 : '$'}}</span>
        </div>
      </div>
      <div class="flex items-center gap-x-10">
        <span class="flex items-center justify-center flex-none rounded-full w-36 h-36 bg-blue-dark">
          <span class="material-icons mi-18">local_atm</span>
        </span>
        <div class="flex flex-col">
          <span class="text-xs text-gray-light">Total Premium:</span>
          <span class="text-lg font-bold">{{totalPremium | shortNumberSuffix: 2 : '$'}}</span>
        </div>
      </div>
      <div class="flex items-center gap-x-10">
        <span class="flex items-center justify-center flex-none rounded-full w-36 h-36 bg-blue-dark">
          <span class="material-icons mi-18">work</span>
        </span>
        <div class="flex flex-col">
          <span class="text-xs text-gray-light">Policies:</span>
          <span class="text-lg font-bold">{{policyCount}} Policies</span>
        </div>
      </div>
      <div class="z-20 h-48 -m-20 -mt-10 bg-left bg-no-repeat bg-cover col-span-full"
      style="transform: scaleX(-1); background: url('./assets/images/bg/sm-wave-horiz.svg');"></div>
    </div>
    <app-policy-list-sidebar [reportType]="reportType" (filterChangeEvent)="onFilterChange($event)" [showDateFilter]="true">
    </app-policy-list-sidebar>
  </mat-sidenav>
  <mat-sidenav-content class="flex flex-col w-full h-full">
    <section class="flex items-center w-full p-20 border-b border-solid gap-x-10 border-gray-lighter">
      <h1 class="text-lg font-bold" [innerText]="routeTitle"></h1>
      @if (routeTitle) {
        <span class="divider is-vertical"></span>
      }
      <div class="flex mr-auto">
        <div class="relative flex flex-grow sm:w-384">
          <i class="absolute mr-10 transform -translate-y-1/2 left-15 material-icons text-gray top-1/2">search</i>
          <input class="w-full pl-48 border input is-lg" type="text" name="search"
            aria-label="Search policies and cases" [placeholder]="'Search Policies'" (keyup)='setSearchText(searchText)'
            [(ngModel)]="searchText" />
          </div>
        </div>
        <button type="button"
          class="flex items-center justify-center button is-primary is-outlined" mat-button
          [disabled]="isExporting"
          (click)="export()" aria-label="Export Carriers List">
          <span class="flex items-center">
            <span>Export</span>
            <span class="ml-5 material-icons mi-18">
              file_download
            </span>
          </span>
        </button>
        <button type="button"
          class="flex items-center justify-center button is-primary is-outlined"
          aria-label="Open menu" mat-button (click)="toggleSideNavMenu()">
          <span class="flex items-center">
            <span>Filters</span>
            <span class="ml-5 material-icons mi-18">filter_alt</span>
          </span>
        </button>
      </section>
      <section class="flex flex-col flex-grow">
        @if (!isLoading) {
          <app-inforce-policy-table
            [inforcePolicies]="inforcePolicies"
            [isError]="isError"
            [selectedAgentIds]="currentFilters.SelectedAgentIds"
          class="flex flex-col flex-grow"></app-inforce-policy-table>
        } @else {
          <img src="/assets/images/loading/table-placeholder.svg" alt="loading" class="w-full animate-pulse">
        }
      </section>
    </mat-sidenav-content>
  </mat-sidenav-container>