import { Component } from '@angular/core';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { SsoService } from 'src/app/core/services/sso.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { BusinessPermissions, ContractingPermissions, CpcReportingPermissions, DolViewPermissions, EventsPermissions, LumaPermissions, MarketingPermissions, MarketingTrackerPermissions, MarketplaceOnePermissions, StructuredMarketplacePermissions } from 'src/app/shared/constants/permissions-shorthand.constants';
import { ResourcePageRouteId } from 'src/app/shared/enums/route-name.enum';
import { AmplitudeDirectiveObject } from 'src/app/shared/models/amplitude.models';
import { RouteTypeEnum } from 'src/app/shared/models/custom-routes.models';

@Component({
  selector: 'app-main-nav-v2',
  templateUrl: './main-nav-v2.component.html',
  styles: [':host {@apply block h-full;}'],
})
export class MainNavV2Component {
  routeType = RouteTypeEnum;
  amplitudeEventOptions: AmplitudeDirectiveObject = {
    EventType: 'Main Nav Click',
  };
  nav: MainNavV2[] = [
    {
      name: 'Dashboard',
      path: 'Dashboard',
      type: RouteTypeEnum.internal,
      visible: (): boolean => true,
    },
    {
      name: 'Marketing',
      type: RouteTypeEnum.none,
      visible: (): boolean => this.permissionService.hasOnePermission(MarketingPermissions),
      children: [
        {
          name: 'Marketplace One',
          action: (): void => this.ssoService.marketPlaceOneSsoV2(),
          type: RouteTypeEnum.action,
          visible: (): boolean => this.permissionService.hasOnePermission(MarketplaceOnePermissions),
        },
        {
          name: 'Marketing Tracker',
          path: window.location.origin + '/v1/#/Tools/MarketingTrackerNew',
          type: RouteTypeEnum.external,
          visible: (): boolean => this.permissionService.hasOnePermission(MarketingTrackerPermissions),
        },
      ]
    },
    {
      name: 'Contracting',
      path: 'Contracting',
      type: RouteTypeEnum.internal,
      visible: (): boolean => true,
      children: [
        {
          name: 'Status & Writing Number',
          path: 'Status',
          type: RouteTypeEnum.internal,
          visible: (): boolean => this.permissionService.hasOnePermission(ContractingPermissions),
        },
        {
          name: 'Training',
          path: 'https://www.training.figmarketing.com/',
          type: RouteTypeEnum.external,
          visible: (): boolean => true,
        },
      ]
    },
    {
      name: 'Business',
      path: 'Business',
      type: RouteTypeEnum.internal,
      visible: (): boolean => this.permissionService.hasOnePermission(BusinessPermissions.newBusiness),
      children: [
        {
          name: 'E-Applications',
          path: 'EApplications',
          type: RouteTypeEnum.internal,
          visible: (): boolean => this.permissionService.hasOnePermission(BusinessPermissions.eApps),
        },
        {
          name: 'Pending',
          path: 'Fixed/Pending',
          type: RouteTypeEnum.internal,
          visible: (): boolean => true,
        },
        {
          name: 'Inforce',
          path: 'Fixed/Inforce',
          type: RouteTypeEnum.internal,
          visible: (): boolean => true,
        },
        {
          name: 'Lifecycle',
          path: 'Fixed/Lifecycle',
          type: RouteTypeEnum.internal,
          visible: (): boolean => this.permissionService.hasAllPermissions(BusinessPermissions.lifecycleV2),
        },
        {
          name: 'CPC Reporting',
          path: 'Fixed/CPCReporting',
          type: RouteTypeEnum.internal,
          visible: (): boolean => this.permissionService.hasOnePermission(CpcReportingPermissions),
        },
        {
          name: 'Informal',
          path: 'Fixed/Informal',
          type: RouteTypeEnum.internal,
          visible: (): boolean => true,
        },
        {
          name: 'Wealth',
          path: 'Wealth',
          type: RouteTypeEnum.internal,
          visible: (): boolean => !!this.sessionStore.UserAcmAgent,
        },
      ]
    },
    {
      name: 'Resources',
      path: 'Resources',
      type: RouteTypeEnum.internal,
      visible: (): boolean => true,
      children: [
        {
          name: 'Tools & Calculators',
          path: `${ResourcePageRouteId.Tools}`,
          type: RouteTypeEnum.internal,
          visible: (): boolean => true,
        },
        {
          name: 'Forms & Guides',
          path: `${ResourcePageRouteId.Forms}`,
          type: RouteTypeEnum.internal,
          visible: (): boolean => true,
        },
        {
          name: 'Structured Notes',
          path: '/api/LumaSso?destination=Lifecyclemanager',
          type: RouteTypeEnum.external,
          visible: (): boolean => this.permissionService.hasAllPermissions([...StructuredMarketplacePermissions, ...LumaPermissions]),
        },
        {
          name: 'Events',
          path: 'https://web.cvent.com/event/dc3b49aa-6ce2-48e5-bed4-248c36a3ee1b/websitePage:e51868f7-3568-4c22-b819-6d41def7f841',
          type: RouteTypeEnum.external,
          visible: (): boolean => this.permissionService.hasAllPermissions([...EventsPermissions]),
        },
        {
          name: 'Gov & Regulatory Updates',
          path: 'Details/RegulatoryUpdates',
          type: RouteTypeEnum.internal,
          visible: (): boolean => this.permissionService.hasOnePermission(DolViewPermissions),
        },
        // {
        //   name: '',
        //   path: '',
        //   type: ,
        //   visible: (): boolean => true,
        // },
      ]
    },
  ];
  constructor(
    private ssoService: SsoService,
    private permissionService: PermissionService,
    private sessionStore: SessionStoreService
  ) { }
}

export interface MainNavV2 {
  name: string;
  visible: () => boolean;
  type: RouteTypeEnum,
  children?: MainNavV2[];
  path?: string;
  routeParams?: string;
  action?: () => void;
}