import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import orderBy from 'lodash-es/orderBy';
import { forkJoin } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { fadeIn, fadeInOut } from 'src/app/animations';
import { FigPlatformService } from 'src/app/core/services/figplatform.service';
import { MessagePanelConfigModel } from 'src/app/shared/components/message-notification/message-notification.component';
import { scrollToId } from 'src/app/shared/helpers/anchor-scroll.helper';
import { ErrorMessageModel } from 'src/app/shared/models/error-message.model';
import { ExploreCategory, ExploreTile, ToggleExploreViews } from 'src/app/portal/components/explore/models/explore.models';
import { FigPlatformCategory, FigPlatformTile } from 'src/app/shared/models/figplatform.models';
import { ExploreGridViewComponent } from './components/explore-grid-view/explore-grid-view.component';
import { ExploreListViewComponent } from './components/explore-list-view/explore-list-view.component';

@Component({
  animations: [fadeIn, fadeInOut],
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss']
})
export class ExploreComponent implements OnInit {
  error: ErrorMessageModel = {
    hasError: false,
    message: 'There was a problem retrieving data. Please try again later.'
  };
  messagePanelConfig: MessagePanelConfigModel = {
    type: 'error',
  };
  toggleViews = ToggleExploreViews;
  selectedView: ToggleExploreViews = ToggleExploreViews.gridView;
  categories: ExploreCategory[] = [];
  tiles: ExploreTile[] = [];
  loading = false;
  loadingAnimationScriptUrl = 'https://assets7.lottiefiles.com/private_files/lf30_y1vuh7xd.json'; // script was generated by me and not random 3rd-party malware - HN
  showHeader = true;

  constructor(
    private router: Router,
    private figPlatformService: FigPlatformService,
  ) {
  }

  ngOnInit(): void {
    this.showExploreHeaderV2();
    this.getCategoriesAndTiles();
  }

  onViewLoaded(component: ExploreGridViewComponent | ExploreListViewComponent): void {
    component.categories = this.categories;
    component.tiles = this.tiles;
  }

  showExploreHeaderV2(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe({
      next: event => {
        const routerEvent = event as RouterEvent;
        if (routerEvent.url.toLowerCase().includes('/details/') || routerEvent.url.toLowerCase().includes('/v2/')) this.showHeader = false;
        else this.showHeader = true;
      }
    });
  }

  getCategoriesAndTiles(): void {
    this.loading = true;
    const categoriesGet = this.figPlatformService.getCategories();
    const permittedTilesGet = this.figPlatformService.getUserPermittedTiles();
    forkJoin({
      categories: categoriesGet,
      tiles: permittedTilesGet,
    }).pipe(
      delay(1000)
    ).subscribe({
      next: res => {
        this.createCategoriesWithTiles(res.categories, res.tiles);
        this.tiles = res.tiles;
      },
      error: () => {
        this.error.hasError = true;
      }
    }).add(() => {
      this.loading = false;
    });
  }

  createCategoriesWithTiles(categories: FigPlatformCategory[], tiles: FigPlatformTile[]): void {
    const exploreCategories: ExploreCategory[] = categories
      .map((category: ExploreCategory) => {
        const filteredTiles = tiles.filter(tile => tile.Fields.CategoryName === category.Fields.Name);
        category.Fields.ExploreV2Tiles = orderBy(filteredTiles, ['Fields.Size', 'Fields.TileOrder', 'Fields.Title']) as ExploreTile[] | undefined;
        return category;
      })
      .filter(category => category.Fields.ExploreV2Tiles && category.Fields.ExploreV2Tiles.length);
    this.categories = orderBy(exploreCategories, 'Fields.Order') as ExploreCategory[];
  }

  scrollTo(id: string): void {
    scrollToId(id);
  }
}