import { Component, Input } from '@angular/core';
import { InformalPolicyV2 } from 'src/app/shared/models/policy.models';

@Component({
  selector: 'app-informal-policy-summary',
  templateUrl: './informal-policy-summary.component.html',
  styles: [
  ]
})
export class InformalPolicySummaryComponent {
  @Input() informalPolicy?: InformalPolicyV2;
}
