import { Component, Input } from '@angular/core';
import { SearchResultModelV2 } from '../../services/search-api.service';
import { ContentMapService } from 'src/app/core/services/content-map.service';

@Component({
  selector: 'app-search-result-v2',
  templateUrl: './search-result-v2.component.html',
})
export class SearchResultV2Component {
  @Input() searchResult?: SearchResultModelV2;
  @Input() searchText?: string;

  constructor(
    public contentMap: ContentMapService) {}
}
