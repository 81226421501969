<section class="w-full mx-auto"
  [class]="!!componentUrl ? 'p-20 md:p-30 max-w-1440' : 'max-w-1440'">
  @if (!loading) {
    <div class="flex flex-row items-center mb-20" [ngClass]="{'md:mb-30': !!componentUrl }" @fadeIn>
      <a class="mr-auto button is-primary is-inverted is-sm"
        mat-button
        (click)="goBack()" (keypress)="goBack()">
        <i class="mr-5 no-underline fig-24px fig-arrow-left"></i>
        <span class="">Back to List View</span>
      </a>
    </div>
  }
  @if (!loading) {
    <section class="flex flex-col w-full">
      <section class="grid grid-cols-2 gap-x-20 gap-y-20">
        <div class="flex col-span-2 text-sm font-bold rounded-lg card">
          <div class="flex items-center m-20 md:mx-40">
            <span class="mr-10 material-icons text-gray mi-18">work</span>
            <div class="flex flex-col">
              <span class="text-xs font-medium text-gray">Policy</span>
              <span class="text-semibold">{{policyDetails.Carrier}} - {{policyDetails.Product}}</span>
              @if (policyDetails.PolicyNumber) {
                <span>({{policyDetails.PolicyNumber}})</span>
              }
            </div>
          </div>
          @if (policyDetails.LineOfBusiness) {
            <div class="flex items-center m-20 md:mx-40">
              <span class="mr-10 material-icons text-gray">health_and_safety</span>
              <div class="flex flex-col">
                <span class="text-xs font-medium text-gray">LOB</span>
                <span>{{policyDetails.LineOfBusiness}}</span>
              </div>
            </div>
          }
          @if (policyDetails.Clients) {
            <div class="flex items-center m-20 md:mx-40">
              <span class="mr-10 material-icons text-gray">people</span>
              <div class="flex flex-col">
                <span class="text-xs font-medium text-gray">Clients</span>
                <span>{{policyDetails.Clients}}</span>
              </div>
            </div>
          }
          @if (policyDetails.FaceAmount) {
            <div class="flex items-center m-20 md:mx-40">
              <span class="mr-10 material-icons text-gray">monetization_on</span>
              <div class="flex flex-col">
                <span class="text-xs font-medium text-gray">Face Amount</span>
                <span>{{policyDetails.FaceAmount | currency}}</span>
              </div>
            </div>
          }
          @if (policyDetails.LineOfBusinessId === lobId.Annuity && policyDetails.SinglePremium && currentPolicyStage !== 'Inforce') {
            <div class="flex items-center m-20 md:mx-40">
              <span class="mr-10 material-icons text-gray">monetization_on</span>
              <div class="flex flex-col">
                <span class="text-xs font-medium text-gray">Premium Amount</span>
                <span>{{policyDetails.SinglePremium | currency}}</span>
              </div>
            </div>
          }
          @if (policyDetails.LineOfBusinessId === lobId.Disability) {
            <div class="flex items-center m-20 md:mx-40">
              <span class="mr-10 material-icons text-gray">monetization_on</span>
              <div class="flex flex-col">
                <span class="text-xs font-medium text-gray">Premium Amount</span>
                <span>{{policyDetails.ModalPremium | currency}}</span>
              </div>
            </div>
          }
        </div>
        <div class="flex flex-col col-span-2 p-10 card md:col-span-1">
          <app-requirements class="block w-full h-full md:mx-auto" [policyStage]="currentPolicyStage"
            [policyStageDate]="currentPolicyStageDate" [policyGuid]="policyDetails.PolicyGuid"
          [isVariable]="isVariable" [lobId]="policyDetails.LineOfBusinessId" [hideResponseSubmission]="policyDetails.LineOfBusiness === 'Disability'"></app-requirements>
        </div>
        @if (policyDetails.LineOfBusiness !== 'Disability') {
          <div class="flex flex-col col-span-2 rounded-lg p-30 md:col-span-1 card md:p-40">
            <h2 class="mb-20 text-lg font-bold">Enhanced Case Insights
            </h2>
            @if (policyDetails.StageHistoryObject) {
              <div class="flex items-center p-10 mb-20 border rounded border-gray-lighter">
                <div class="flex flex-col items-center p-10 w-256">
                  <span class="flex items-center justify-center p-10 mb-10 text-white rounded-full bg-purple">
                    <span class="material-icons">
                      assignment
                    </span>
                  </span>
                  <span class="text-sm font-bold uppercase label">Pre-application</span>
                </div>
                <ul class="flex flex-col">
                  <li class="flex items-center p-10">
                    <span class="mr-10 material-icons text-gray">
                      {{!!policyDetails.StageHistoryObject[0] ? 'check_circle_outline' :
                      'radio_button_unchecked'}}
                    </span>
                    <div class="flex flex-col">
                      <h3 class="text-sm font-semibold capitalize">Application Signed</h3>
                      <span class="text-xs font-semibold label text-gray">{{ !!policyDetails.StageHistoryObject[0]
                        ? (policyDetails.StageHistoryObject[0].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                      '--'}}</span>
                    </div>
                  </li>
                  <li class="flex items-center p-10">
                    <span class="mr-10 material-icons text-gray">
                      {{!!policyDetails.StageHistoryObject[1] ? 'check_circle_outline' :
                      'radio_button_unchecked'}}
                    </span>
                    <div class="flex flex-col">
                      <h3 class="text-sm font-semibold capitalize">Received At FIG</h3>
                      <span class="text-xs font-semibold label text-gray">{{ !!policyDetails.StageHistoryObject[1]
                        ? (policyDetails.StageHistoryObject[1].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                      '--'}}</span>
                    </div>
                  </li>
                  <li class="flex items-center p-10">
                    <span class="mr-10 material-icons text-gray">
                      {{!!policyDetails.StageHistoryObject[2] ? 'check_circle_outline' :
                      'radio_button_unchecked'}}
                    </span>
                    <div class="flex flex-col">
                      <h3 class="text-sm font-semibold capitalize">Submitted to Carrier</h3>
                      <span class="text-xs font-semibold label text-gray">{{ !!policyDetails.StageHistoryObject[2]
                        ? (policyDetails.StageHistoryObject[2].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                      '--'}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            }
            @if (!!policyFunding) {
              <div class="flex items-center p-10 mb-20 border rounded border-gray-lighter">
                <div class="flex flex-col items-center p-10 w-256">
                  <span class="flex items-center justify-center p-10 mb-10 text-white rounded-full bg-purple">
                    <span class="material-icons">
                      account_balance
                    </span>
                  </span>
                  <span class="text-sm font-bold uppercase label">{{ policyDetails.LineOfBusinessId === 1 ? 'Funding' :
                  'Funding/Premium'}}</span>
                </div>
                <ul class="flex flex-col">
                  @for (fund of policyFunding; track fund) {
                    <li class="flex items-center p-10">
                      <span class="mr-10 material-icons text-gray">
                        {{fund.IssuedPremiumAmount? 'check_circle_outline' : 'radio_button_unchecked'}}
                      </span>
                      <div class="flex flex-col">
                        <h3 class="text-base font-bold capitalize">
                          {{(fund.IssuedPremiumAmount ? fund.IssuedPremiumAmount: fund.Amount) | currency}}
                        </h3>
                        <span class="text-xs font-semibold label text-gray">{{fund.Method}}</span>
                      </div>
                    </li>
                  }
                </ul>
              </div>
            }
            @if (policyDetails.StageHistoryObject) {
              <div class="flex items-center p-10 mb-20 border rounded border-gray-lighter">
                <div class="flex flex-col items-center p-10 w-256">
                  <span class="flex items-center justify-center p-10 mb-10 text-white rounded-full bg-purple">
                    <span class="material-icons">
                      assignment_turned_in
                    </span>
                  </span>
                  <span class="text-sm font-bold uppercase label">Completion</span>
                </div>
                <ul class="flex flex-col">
                  @if (policyDetails.LineOfBusiness === 'Annuity' && !!policySuitability) {
                    <li class="flex items-center p-10"
                      >
                      <span class="mr-10 material-icons text-gray">
                        {{!!policySuitability.IsSuitable ? 'check_circle_outline' : 'radio_button_unchecked'}}
                      </span>
                      <div class="flex flex-col">
                        <h3 class="text-sm font-semibold capitalize">Suitability Approved</h3>
                        <span class="text-xs font-semibold label text-gray">{{ !!policySuitability ?
                        (policySuitability.ModifiedOn | date: 'MMM dd, yyyy' : 'UTC') : '--'}}</span>
                      </div>
                    </li>
                  }
                  @if (policyDetails.LineOfBusiness !== 'Annuity') {
                    <li class="flex items-center p-10">
                      <span class="mr-10 material-icons text-gray">
                        {{!!policyDetails.StageHistoryObject[4] ? 'check_circle_outline' :
                        'radio_button_unchecked'}}
                      </span>
                      <div class="flex flex-col">
                        <h3 class="text-sm font-semibold capitalize">Approved</h3>
                        <span class="text-xs font-semibold label text-gray">{{ !!policyDetails.StageHistoryObject[4]
                          ? (policyDetails.StageHistoryObject[4].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                        ''}}</span>
                      </div>
                    </li>
                  }
                  <li class="flex items-center p-10">
                    <span class="mr-10 material-icons text-gray">
                      {{!!policyDetails.StageHistoryObject[5] ? 'check_circle_outline' :
                      'radio_button_unchecked'}}
                    </span>
                    <div class="flex flex-col">
                      <h3 class="text-sm font-semibold capitalize">Issued</h3>
                      <span class="text-xs font-semibold label text-gray">{{ !!policyDetails.StageHistoryObject[5]
                        ? (policyDetails.StageHistoryObject[5].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                      ''}}</span>
                    </div>
                  </li>
                  <li class="flex items-center p-10">
                    <span class="mr-10 material-icons text-gray">
                      {{!!policyDetails.StageHistoryObject[6] || policyDetails.IsPartial ? 'check_circle_outline' :
                      'radio_button_unchecked'}}
                    </span>
                    <div class="flex flex-col">
                      <h3 class="text-sm font-semibold capitalize">Inforce</h3>
                      <span class="text-xs font-semibold label text-gray">{{ !!policyDetails.StageHistoryObject[6]
                        ? (policyDetails.StageHistoryObject[6].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                      ''}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            }
          </div>
        }
        @if (policyDetails.LineOfBusiness === 'Disability') {
          <div class="flex flex-col col-span-2 rounded-lg p-30 md:col-span-1 card md:p-40">
            <h2 class="mb-20 text-lg font-bold">Enhanced Case Insights
            </h2>
            @if (policyDetails.StageHistoryObject) {
              <div class="flex items-center p-10 mb-20 border rounded border-gray-lighter">
                <ul class="flex flex-col">
                  <li class="flex items-center p-10">
                    <span class="mr-10 material-icons text-gray">
                      {{!!policyDetails.StageHistoryObject[2] ? 'check_circle_outline' :
                      'radio_button_unchecked'}}
                    </span>
                    <div class="flex flex-col">
                      <h3 class="text-sm font-semibold capitalize">Submitted</h3>
                      <span class="text-xs font-semibold label text-gray">{{ !!policyDetails.StageHistoryObject[2]
                        ? (policyDetails.StageHistoryObject[2].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                      '--'}}</span>
                    </div>
                  </li>
                  <li class="flex items-center p-10">
                    <span class="mr-10 material-icons text-gray">
                      {{!!policyDetails.StageHistoryObject[3] ? 'check_circle_outline' :
                      'radio_button_unchecked'}}
                    </span>
                    <div class="flex flex-col">
                      <h3 class="text-sm font-semibold capitalize">Pending</h3>
                      <span class="text-xs font-semibold label text-gray">{{ !!policyDetails.StageHistoryObject[3]
                        ? (policyDetails.StageHistoryObject[3].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                      '--'}}</span>
                    </div>
                  </li>
                  <li class="flex items-center p-10">
                    <span class="mr-10 material-icons text-gray">
                      {{!!policyDetails.StageHistoryObject[4] ? 'check_circle_outline' :
                      'radio_button_unchecked'}}
                    </span>
                    <div class="flex flex-col">
                      <h3 class="text-sm font-semibold capitalize">Approved</h3>
                      <span class="text-xs font-semibold label text-gray">{{ !!policyDetails.StageHistoryObject[4]
                        ? (policyDetails.StageHistoryObject[4].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                      '--'}}</span>
                    </div>
                  </li>
                  <li class="flex items-center p-10">
                    <span class="mr-10 material-icons text-gray">
                      {{!!policyDetails.StageHistoryObject[5] ? 'check_circle_outline' :
                      'radio_button_unchecked'}}
                    </span>
                    <div class="flex flex-col">
                      <h3 class="text-sm font-semibold capitalize">Issued</h3>
                      <span class="text-xs font-semibold label text-gray">{{ !!policyDetails.StageHistoryObject[5]
                        ? (policyDetails.StageHistoryObject[5].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                      '--'}}</span>
                    </div>
                  </li>
                  <li class="flex items-center p-10">
                    <span class="mr-10 material-icons text-gray">
                      {{!!policyDetails.StageHistoryObject[6] ? 'check_circle_outline' :
                      'radio_button_unchecked'}}
                    </span>
                    <div class="flex flex-col">
                      <h3 class="text-sm font-semibold capitalize">Inforce</h3>
                      <span class="text-xs font-semibold label text-gray">{{ !!policyDetails.StageHistoryObject[6]
                        ? (policyDetails.StageHistoryObject[6].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                      '--'}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            }
          </div>
        }
      </section>
    </section>
  }
</section>