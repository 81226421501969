import { ErrorMessageModel } from '../models/errorMessageModel';

export const errorMessageMap = new Map<string, ErrorMessageModel>([
  [
    'exists',
    {ErrorMessage: 'This user registration link has expired. Please contact your sales team.', ShowLogIn: true}
  ],
  [
    'sessionTimeout',
    {ErrorMessage: 'Your session has expired.', ShowLogIn: true}
  ],
  ['remoteTimeout',
    {ErrorMessage: 'Your login session has expired. If you see this message frequently, please make sure you are not bookmarking the Login page.', ShowLogIn: true}
  ],
  ['remoteTimeoutRegistration',
    {ErrorMessage: 'Your login session expired during the registration process. You may need to click the link in your registration email again, or if it has expired, have your administrator send you a new one.', ShowLogIn: false}
  ],
  ['inactive',
    {ErrorMessage: 'Your user account is not active.', ShowLogIn: true}
  ],
  ['nonexistent',
    {ErrorMessage: 'An error occurred while setting up your account. Please contact your administrator.', ShowLogIn: true}
  ],
  ['registrationExpired',
    {ErrorMessage: 'This registration link has expired. Please contact your administrator.', ShowLogIn: false}
  ]
]);

