import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { Table, TableHeader } from 'src/app/shared/classes/table';
import { CpcPolicyTableHeaders } from '../../constants/cpcPolicyTableHeader.constants';
import { CpcPolicyStatusEnum, CpcPolicyPropertyNameEnum } from '../../enums/cpcPolicyEnums.enum';
import { CpcTablePolicy } from '../../models/cpcPolicyTable.model';

@Component({
  animations: [fadeIn],
  selector: 'app-cpc-policy-table',
  templateUrl: './cpc-policy-table.component.html'
})
export class CpcPolicyTableComponent implements OnChanges {
  @ViewChild(CdkVirtualScrollViewport, { static: false }) public virtualScroll?: CdkVirtualScrollViewport;
  @Input() cpcPolicies: CpcTablePolicy[] = [];
  @Input() selectedAgentIds: string[] = [];
  @Output() tableSortedEvent = new EventEmitter<string>();
  CpcPolicyStatusEnums = CpcPolicyStatusEnum;
  CpcPolicyPropertyNameEnum = CpcPolicyPropertyNameEnum;
  table = new Table<CpcTablePolicy>(CpcPolicyTableHeaders, 1, true);
  isLoading = true;
  @Input() isError = false;
  showAgentColumn = true;

  constructor(private sessionStore: SessionStoreService,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cpcPolicies']?.previousValue !== changes['cpcPolicies']?.currentValue) {
      this.table.setBody(this.cpcPolicies);
    }
    this.toggleAgentColumn();
  }

  orderTableBy(col: TableHeader<CpcTablePolicy>): void {
    this.table.orderBy(col);
    this.virtualScroll?.scrollTo({ top: 0 });
  }
  toggleAgentColumn(): void {
    if (this.selectedAgentIds.length === 1 && this.selectedAgentIds.includes(this.sessionStore.User.AgentArcGuid)) {
      this.table.Header = CpcPolicyTableHeaders.filter(h => h.DisplayName !== 'Agent');
      this.showAgentColumn = false;
    } else {
      this.table.Header = CpcPolicyTableHeaders;
      this.showAgentColumn = true;
    }
  }

}
