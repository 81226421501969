import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { fadeIn } from './animations';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AmplitudeEventService } from './core/services/amplitude/amplitude-event.service';

@Component({
  animations: [fadeIn],
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  constructor(
    private amplitudeEventService: AmplitudeEventService,
    private router: Router,
    private titleService: Title,
  ) {
    this.setPageTitle();
  }

  /**
   * Set page title dynamically
   */
  setPageTitle(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Amplitude route tracking
        this.amplitudeEventService.trackNavigationV2();

        // Set page title
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = '';
        while (route?.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) {
          routeTitle = `${route.snapshot.data['title'] as string} | `;
        }
        this.titleService.setTitle(`${routeTitle}Agent Portal`);
      }
    });
  }
}
