import { TableHeader } from 'src/app/shared/classes/table';
import { CpcPolicyStatusEnum, CpcPolicyPropertyNameEnum } from '../enums/cpcPolicyEnums.enum';
import { CpcTablePolicy } from '../models/cpcPolicyTable.model';

export const CpcPolicyTableHeaders: TableHeader<CpcTablePolicy>[] = [
  {
    DisplayName: CpcPolicyPropertyNameEnum.Agent,
    ColumnName: ['Agent'],
    WidthClass: 'min-w-100',
    CompareFn: (a: CpcTablePolicy, b: CpcTablePolicy, order: 1|-1):number => {
      if (a.StatementStatus !== b.StatementStatus) {
        return a.StatementStatus === CpcPolicyStatusEnum.Pending ? -1 : 1;
      }
      const valA = a.AgentName ?? '';
      const valB = b.AgentName ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: CpcPolicyPropertyNameEnum.StatementDate,
    ColumnName: ['StatementDate'],
    WidthClass: '',
    CompareFn: (a: CpcTablePolicy, b: CpcTablePolicy, order: 1|-1):number => {
      if (a.StatementStatus !== b.StatementStatus) {
        return a.StatementStatus === CpcPolicyStatusEnum.Pending ? -1 : 1;
      }
      const valA = a.StatementDate ?? new Date(1900, 1, 1);
      const valB = b.StatementDate ?? new Date(1900, 1, 1);
      return (valA.getTime() === valB.getTime() ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: CpcPolicyPropertyNameEnum.Clients,
    ColumnName: ['Clients'],
    WidthClass: 'min-w-128',
    CompareFn: (a: CpcTablePolicy, b: CpcTablePolicy, order: 1|-1):number => {
      if (a.StatementStatus !== b.StatementStatus) {
        return a.StatementStatus === CpcPolicyStatusEnum.Pending ? -1 : 1;
      }
      const valA = a.ClientName ?? '';
      const valB = b.ClientName ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: CpcPolicyPropertyNameEnum.Carrier,
    ColumnName: ['Carrier'],
    WidthClass: 'min-w-128',
    CompareFn: (a: CpcTablePolicy, b: CpcTablePolicy, order: 1|-1):number => {
      if (a.StatementStatus !== b.StatementStatus) {
        return a.StatementStatus === CpcPolicyStatusEnum.Pending ? -1 : 1;
      }
      const valA = a.Carrier ?? '';
      const valB = b.Carrier ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: CpcPolicyPropertyNameEnum.Product,
    ColumnName: ['Product'],
    WidthClass: 'min-w-128',
    CompareFn: (a: CpcTablePolicy, b: CpcTablePolicy, order: 1|-1):number => {
      if (a.StatementStatus !== b.StatementStatus) {
        return a.StatementStatus === CpcPolicyStatusEnum.Pending ? -1 : 1;
      }
      const valA = a.PolicyNumber ?? '';
      const valB = b.PolicyNumber ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: CpcPolicyPropertyNameEnum.Premium,
    ColumnName: ['Premium'],
    WidthClass: 'min-w-128',
    CompareFn: (a: CpcTablePolicy, b: CpcTablePolicy, order: 1|-1):number => {
      if (a.StatementStatus !== b.StatementStatus) {
        return a.StatementStatus === CpcPolicyStatusEnum.Pending ? -1 : 1;
      }
      const valA = a.Premium;
      const valB = b.Premium;
      if (valA != null && valB != null) return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
      if (valA === valB) return 0;
      return (valA === null ? -1 : 1) * order;
    },
  },
  {
    DisplayName: CpcPolicyPropertyNameEnum.CPCs,
    ColumnName: ['CPCs'],
    WidthClass: 'min-w-128',
    CompareFn: (a: CpcTablePolicy, b: CpcTablePolicy, order: 1|-1):number => {
      if (a.StatementStatus !== b.StatementStatus) {
        return a.StatementStatus === CpcPolicyStatusEnum.Pending ? -1 : 1;
      }
      const valA = a.CPCs;
      const valB = b.CPCs;
      if (valA != null && valB != null) return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
      if (valA === valB) return 0;
      return (valA === null ? -1 : 1) * order;
    },
  }
];