import { Policy } from '../models/policy.models';

export const getPendingCPCSum = (policies:Policy[]): number => {
  if (policies.length < 1) return 0;

  return Math.round(policies
    .map(policy => policy.IssuedCPCs || policy.CPCs)
    .reduce((accumulator, value) => accumulator + value, 0));
};

export const getInforceCPCSum = (policies:Policy[]): number => {
  if (policies.length < 1) return 0;

  return Math.round(policies
    .filter(policy => policy.PaidPremiumCPCs != null)
    .reduce((accumulator, currentValue) => accumulator + currentValue.PaidPremiumCPCs, 0));
};
