import { IndustryEvent } from '../models/industry-event.models';

export const industryEvents: IndustryEvent[] = [
  {
    Title: 'Fig Symposium 2024',
    Url: 'FIGSymposium2024',
    DateStart: new Date('06/10/2024'),
    DateEnd: new Date('06/11/2024'),
    Description: `Immerse yourself as we share cutting-edge strategies, dissecting current trends and forecasts for the future. We reveal the best practices for you, your business, and your family, while striking a mindful balance between work and play. At F.I.G., we bring a lot to the table. We aren't bystanders; we're architects of change, and were here to support you. Check out our session videos to see how we're committed to empowering our financial professionals towards real business growth now and in the future.
    <br><br>
    <small><small>
    <i>The content in these videos are for financial professional use only and not for customer use, nor should the same be treated as financial, tax and/or legal advice. These videos are not for distribution and can only be viewed with access to FIG's agent portal.</i>
    </small></small>`,
    Location: 'Dallas, TX',
    Image: 'FIG_Symposium_2024.png',
    Permissions: 'FigSymposium:View|FIGTripsAndEvents:View',
    IndustryEventGroups: [
      {
        Date: new Date('06/10/2024'),
        Name: 'Day 1',
        Events: [
          {
            Name: '1<sup>st</sup> Time Attendee Meeting-Nicholas Ross',
            WistiaId: 'a8y84lcml4',
          },
          {
            Name: 'Welcome Day 1 - Jim Cooper &amp; Brian Williams',
            WistiaId: 'yk6wdqcb96',
          },
          {
            Name: 'Becoming Superhuman - Dr. Sahar Yousef',
            WistiaId: '9wikzy1sii',
          },
          {
            Name: 'Funnel Marketing Plan: TV, Radio, Live Workshop Best Practices - Mark Gaffney&nbsp;(MEG)',
            WistiaId: 'dwnyr2kh0k',
          },
          {
            Name: 'Industry Update with F&amp;G - Brett Hynes &amp; Tiya Stanley',
            WistiaId: 'ur5d84bss1',
          },
          {
            Name: 'Live Workshop with MEG - Mark Gaffney',
            WistiaId: 'cr5f3nzrw6',
          },
          {
            Name: 'Super-Charge your Tax-Free Planning - David McKnight',
            WistiaId: 'gadvrbvbfe',
          },
          {
            Name: 'Industry Update-Mass Mutual Ascend - Brett Hynes &amp; Joe Maringer',
            WistiaId: 'rqu5y6i546',
          },
          {
            Name: 'Indutry Update: DOL with Brian Williams',
            WistiaId: 'kjaoi04wtt',
          },
          {
            Name: 'Efficient Insurance Solutions-Alecia Barnette, David Henry, &amp; Lily Vittayarukskul',
            WistiaId: 'c2lb7l3w1x',
          },
        ],
      },
      {
        Date: new Date('06/11/2024'),
        Name: 'Day 1',
        Events: [
          {
            Name: 'Welcome Day 2 - Rising Star &amp; William H Cain Lifetime Achievement Award Ceremonies',
            WistiaId: 'o39ttka0hu',
          },
          {
            Name: '5 Funnel Marketing Strategy: Driving Digital Leads - FIG Digital with Anna Shea',
            WistiaId: 'hjtntdza5m',
          },
          {
            Name: 'Scaling Your Marketing Function-Ben &amp; Kaitlin Schrock',
            WistiaId: 'oqtnbklocu',
          },
          {
            Name: 'Scaling Your Review Process - Keith Burger &amp; Shawn Sigler',
            WistiaId: 'd7fruaasyo',
          },
          {
            Name: 'Product Innovation Panel - Brett Hynes(FIG), Adam Politzer(Athene), Aaron Barriage(American Equity), and Jeff Barnes(Equitrust)',
            WistiaId: '7vfk0vug39',
          },
          {
            Name: 'Plugging-In with FIG Strategy - Nicholas Ross',
            WistiaId: 'ax4pfh2sry',
          },
          {
            Name: 'Scaling Your Operations - Matt Monroe &amp; Nicholas Ross',
            WistiaId: 'ubrsw23q7y',
          },
          {
            Name: 'Industry Update with Allianz - Tony Crescenzi',
            WistiaId: 'gbcm1wldsx',
          },
          {
            Name: 'Scaling Your Planning Function- Lily Vittayarukskul &amp; Evan Ehrenburg',
            WistiaId: 'oywkeypaoa',
          },
          {
            Name: 'Key Takeaways &amp; Closing Keynote - Steve Nash',
            WistiaId: '5pjpslp87f',
          },
        ]
      }
    ]
  },
  {
    Title: 'Fig Symposium 2023',
    Url: 'FIGSymposium2023',
    DateStart: new Date('02/22/2023'),
    DateEnd: new Date('02/24/2023'),
    Description: 'Financial Independence Group is committed to bringing engaging keynote speakers, specialized breakout sessions, expert panels, and meaningful fireside chats to the sales symposium. From connecting generations to tomorrow\'s technology, our line-up of experts and educators are sure to impress and bring big value to the financial professional of today.<br><br><small><small><i>The content in these videos are for financial professional use only and not for customer use, nor should the same be treated as financial, tax and/or legal advice. These videos are not for distribution and can only be viewed with access to FIG\'s agent portal.</i></small></small>',
    Location: 'Dallas, TX',
    Image: 'sym23-logo.svg',
    Permissions: 'FigSymposium:View|FIGTripsAndEvents:View',
    IndustryEventGroups: [
      {
        Date: new Date('02/22/2023'),
        Name: 'Day 1',
        Events: [
          {
            Name: 'Welcome + Opening Comments - FIG CEOs and Executives',
            WistiaId: 'hw0d25rx38',
          },
          {
            Name: 'Elevate - New Model Offices and Coaches',
            WistiaId: 's3tl43ov3i',
          },
          {
            Name: 'Keynote Speaker - Sheri Fitts',
            WistiaId: 'jx5uwkooyf',
          },
          {
            Name: 'Marketing Panel',
            WistiaId: 'p64ikomr0j',
          },
          {
            Name: 'Award Recognition - Rising Star, Champion For Charitable Giving, William H. Cain Lifetime Achievement Part 1',
            WistiaId: 'cmhzr73dj4',
          },
          {
            Name: 'Award Recognition - Rising Star, Champion For Charitable Giving, William H. Cain Lifetime Achievement Part 2',
            WistiaId: '8ze8lkxkin',
          },
          {
            Name: 'Keynote Speaker - Michael Finke',
            WistiaId: 'kp8do8ymed',
          },
          {
            Name: 'Advanced Planning Panel',
            WistiaId: 'fz3wemwfpi',
          },
          {
            Name: 'Keynote Speakers - David & Jonah Stillman',
            WistiaId: 'fz3wemwfpi',
          },
          {
            Name: 'Succession Planning Panel',
            WistiaId: 'x1kh0azc7f',
          },
          {
            Name: 'Keynote Speaker - Darren Woodson',
            WistiaId: 'uhdd6h2x9x',
          }
        ]
      }
    ]
  },
  {
    Title: 'Fig Symposium 2022',
    Url: 'FIGSymposium2022',
    DateStart: new Date('02/23/2022'),
    DateEnd: new Date('02/25/2022'),
    Description: 'From inspirational and educational mainstage sessions to targeted mastermind breakouts, the FIG Sales Symposium is committed to delivering solutions and innovation that brings the industry of tomorrow into&nbsp;today!',
    Location: 'Orlando, FL',
    Image: 'sym_2022.png',
    Permissions: 'FigSymposium:View|FIGTripsAndEvents:View',
    IndustryEventGroups: [
      {
        Date: new Date('02/23/2022'),
        Name: 'Day 1',
        Events: [
          {
            Name: 'Welcome + Opening Comments - FIG CEOs',
            WistiaId: 'bh4lx272eb',
          },
          {
            Name: 'New Elevate Model Office Experience - Elevate Coaches + Nic Ross',
            WistiaId: '7nq79ytnge',
          },
          {
            Name: 'Your Growth Strategy, Marketing - Tom Lamendola',
            WistiaId: 'egt0ph0xno',
          },
          {
            Name: 'Building Today\'s FinTech + InsurTech Applications - Andrew Barnett + Josh Lewis',
            WistiaId: '8gt3xlorfh',
          },
          {
            Name: 'Breakout Session, Mainstage - Cornerstone',
            WistiaId: '9cbxx677i2',
          },
          {
            Name: 'Breakout Session, Mainstage - Scott Moore + Gary Reed',
            WistiaId: 'sgtlrleyek',
          },
          {
            Name: 'Breakout Session, Mainstage - Carson Coaching',
            WistiaId: '1l34kwcrhf',
          },
          {
            Name: 'New Product Concepts - Bobby Samuelson',
            WistiaId: '0c370bp76i',
          },
          {
            Name: 'Advanced Strategies Panel - Jeremy Green + Dave Henry',
            WistiaId: 'zyzvyu5dqt',
          },
          {
            Name: 'DOL Regulation Updates - Brian Williams',
            WistiaId: 'c9ou3mu4km',
          },
        ]
      }
    ]
  },
  {
    Title: 'Fig Symposium 2021',
    Url: 'FIGSymposium2021',
    DateStart: new Date('07/14/2021'),
    DateEnd: new Date('07/16/2021'),
    Description: 'The culmination of incredible ideas developed during 2020 and truly transformative models for your continued acceleration!',
    Location: 'Las Vegas, NV',
    Image: 'fig-symposium-2021.png',
    BackgroundImageUrl: './assets/images/symposium-background.png',
    Permissions: 'FigSymposium:View|FIGTripsAndEvents:View',
    IndustryEventGroups: [
      {
        Date: new Date('07/15/2021'),
        Name: 'Day 1',
        Events: [
          {
            Name: 'Welcome from the CEOs',
            WistiaId: '2ktxfqi4ho',
          },
          {
            Name: 'Solving the Tech Gap to Accelerate Growth: FIG&nbsp;+&nbsp;SIMON',
            WistiaId: '61fo1bce7n',
          },
          {
            Name: '5 Industry Trends Reshaping Financial Advice',
            Speakers: 'Michael Kitces',
            WistiaId: '2wm0yet6dr',
          },
          {
            Name: 'Elevate, There is No Summit',
            Speakers: 'Nicholas Ross, Monica Breeding, Jamie&nbsp;Hopkins',
            WistiaId: 'neecouineq',
          },
          {
            Name: 'Product Strategies in Today\'s Economy',
            Speakers: 'Bobby Samuelson',
            WistiaId: 'd6kb1ctz95',
          },
          {
            Name: 'Breakout Session',
            Speakers: 'Carson Coaching',
            WistiaId: 'ww4872rthm',
          },
          {
            Name: 'Breakout Session',
            Speakers: 'The FIG Product Platform',
            WistiaId: '87cx6u5e9u',
          },
          {
            Name: 'Breakout Session',
            Speakers: 'Life Innovators, Deep Dive on FILA',
            WistiaId: 'b167866y7d',
          },
          {
            Name: 'Breakout Session',
            Speakers: 'High Converting Talks, Pat Quinn',
            WistiaId: 'c4g1a3iwow',
          },
          {
            Name: 'The Irresistible Power of Storytelling',
            Speakers: 'Kindra Hall',
            WistiaId: 'r1p9n0xwdb',
          },
          {
            Name: 'Unified, Rising Above Division to Lead',
            Speakers: 'Trey Goudy',
            WistiaId: 'i3ima2vjov',
          },
        ]
      }
    ]
  },
];