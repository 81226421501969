<section class="relative flex flex-col w-full h-full">
  <div id="leaderboardToolbar" class="flex-none p-20 pb-0 border-b border-solid border-gray-lighter">
    <button class="absolute top-0 right-0 m-20 icon-button is-primary is-inverted" tabindex="-1" mat-button
      aria-label="Close" [mat-dialog-close] type="button"><i class="material-icons">close</i>
    </button>
    <div class="flex flex-row items-center justify-center m-10">
      <h1 class="m-auto text-lg font-bold text-center heading text-gray-dark">Leaderboard</h1>
    </div>
    <div class="flex flex-row items-center justify-center mb-10">
      <div class="flex flex-col items-center justify-start m-10">
        <button type="button" class="flex flex-row items-center justify-center mb-10 rounded-full icon-button is-lg"
          [ngClass]="!filterByGroup ? 'is-primary' : 'is-primary is-inverted'"
          (click)="filterLeaderboard(false, activeSubCategory)"
          appCyData="individualBtn">
          <i class="fig-24px fig-account"></i>
        </button>
        <span class="text-xs font-semibold text-center label"
        [ngClass]="!filterByGroup ? 'text-blue-dark' : ''">Individual</span>
      </div>
      <div class="flex flex-col items-center justify-start m-10">
        <button type="button" class="flex flex-row items-center justify-center mb-10 rounded-full icon-button is-lg"
          [ngClass]="filterByGroup ? 'is-primary' : 'is-primary is-inverted'"
          (click)="filterLeaderboard(true, activeSubCategory)"
          appCyData="groupBtn">
          <i class="fig-24px fig-account-multiple"></i>
        </button>
        <span class="text-xs font-semibold text-center label"
        [ngClass]="filterByGroup ? 'text-blue-dark' : ''">Office</span>
      </div>
    </div>
    <ul class="flex flex-row items-center justify-center">
      @for (subCat of subCategories; track subCat) {
        <li class="max-w-full">
          <span class="flex flex-col h-64 text-base font-semibold transition duration-150 cursor-pointer hover:bg-blue-lightest hover:bg-opacity-25 focus:outline-none"
            (click)="filterLeaderboard(filterByGroup, subCat)"
            appCyData="subCat">
            <span class="m-auto mx-20 text-center"
            [ngClass]="activeSubCategory === subCat ? 'text-blue-dark' : ''">{{subCat}}</span>
            <span class="w-full h-2" [ngClass]="activeSubCategory === subCat ? 'bg-blue-dark' : ''"></span>
          </span>
        </li>
      }
    </ul>
  </div>
  <div class="flex flex-col flex-auto h-0 p-10 overflow-auto">
    @if (!loading) {
      <ul>
        @for (l of activeLeaderboard; track l) {
          <li [@fadeIn]>
            <ul>
              @for (r of l.Results; track r) {
                <li
                  class="flex flex-row p-10 border-b border-solid border-gray-lighter">
                  <span class="flex flex-row items-center justify-center w-64 p-10">
                    <span
                      class="flex flex-row items-center justify-center m-auto rounded-full w-36 h-36 bg-gray-lighter">
                      <span class="text-lg font-semibold">{{r.Index}}</span>
                    </span>
                  </span>
                  <span class="flex flex-row items-center justify-start flex-grow p-10">
                    <span class="text-base font-semibold">{{r.Name}}</span>
                  </span>
                  <span class="flex flex-row items-center justify-end p-10 w-256">
                    <span class="text-base font-semibold text-right text-blue-dark">{{r.Amount | number}}
                    </span>
                  </span>
                </li>
              }
            </ul>
          </li>
        }
      </ul>
    }@else {
      <div class="relative p-20 loader-wrap">
        <div class="loader-sm is-blue"></div>
      </div>
    }
  </div>
  @if (!loading && disclaimer) {
    <p class="flex-none p-20 text-xs text-opacity-50 border-t border-solid border-gray-lighter">{{disclaimer}}</p>
  }
</section>