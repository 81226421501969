import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OnboardingTermsOfUse } from 'src/app/shared/models/onboarding.models';
import { OnboardingApiService } from '../http/onboarding-api.service';

@Injectable({
  providedIn: 'root'
})
export class TermsOfUseCompletedResolver implements Resolve<OnboardingTermsOfUse | null> {

  constructor(private onboardingApiService: OnboardingApiService) {
  }

  resolve(): Observable<OnboardingTermsOfUse | null> {
    return this.onboardingApiService.getTermsOfUse().pipe(catchError(() => of(null)));
  }
}