/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const getAverage = (data:any[], key:string):number => {
  if (typeof (data) === 'undefined' || typeof (key) === 'undefined' || !data.length) {
    return 0;
  }

  let sum = 0;
  let total = 0;
  for (let i = data.length - 1; i >= 0; i--) {
    if (!isNaN(parseInt(data[i][key]))) {
      sum += data[i][key];
      total++;
    }
  }

  return sum / total;
};