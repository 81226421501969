<div class="flex flex-col items-center m-auto mt-40 p-30 gap-y-40 sm:mt-72 text-gray-darker">
  @if (logoUrl) {
    <img class="h-48 sm:h-64" [src]="logoUrl" alt="Group Logo">
  }
  <div class="flex flex-col items-center justify-center w-full p-20 sm:h-512 sm:w-400 h-384 card gap-y-20">
    <span class="flex flex-none p-10 rounded-full bg-gray-lighter">
      <span class="m-auto material-icons text-gray">
        power_settings_new
      </span>
    </span>
    <p class="text-lg text-center">{{errorMessageModel?.ErrorMessage}}</p>
    @if (errorMessageModel?.ShowLogIn) {
      <button class="button is-primary" mat-button type="button" (click)="goToLogIn()">Click here to log in again</button>
    }
  </div>
  <span class="text-sm text-gray">Copyright &copy; <span>{{currentYear}}</span> Financial Independence Group, Inc. All Rights Reserved.</span>
</div>