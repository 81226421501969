@if (!isLoading) {
  <section @fadeIn>
    @for (survey of submitted; track survey; let index = $index) {
      <div class="flex flex-row">
        <button type="button" class="mt-20 mr-20 button is-primary md:mt-30"
          (click)="!!survey && !survey.isEnrolled ? openEnrollmentModal(survey.surveyId) : ''" [disabled]="survey.isEnrolled">
          @if (!!survey && !survey.isEnrolled) {
            <span>
              Enroll Today
            </span>
          }
          @else if (survey && survey.isEnrolled) {
            <span matTooltip="Force CRM enrollment form already submitted" matTooltipPosition="above"
            >Submitted</span>
          }
        </button>
        <!-- Disable if purchased and expiration duration has not elapsed yet -->
        @if (survey && survey.isEnrolled && resource.PurchasePageData) {
          <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openPurchaseDialog()"
            [disabled]="!purchased?.Expired && purchased?.Complete">
            @if (!purchased || purchased?.Expired) {
              <span>
                Purchase
              </span>
            }
            @else if (purchased && purchased.Complete && !purchased.Expired) {
              <span>
                Purchased On {{purchased.PurchaseDate}}
              </span>
            }
          </button>
        }
      </div>
    }
  </section>
}