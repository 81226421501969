<div class="flex flex-row flex-wrap items-baseline justify-around">
    <div class="flex flex-col items-center justify-end w-full md:w-1/4">
        <img class="w-4/5 h-auto mx-auto" src="./assets/images/icons/marketing-strategist.png" alt="Marketing Strategist Image">
        <h3 class="my-20 text-base font-semibold text-center">Marketing Strategist</h3>
        <p class="text-base text-center">Your marketing strategist works 1-on-1
            to support your business, develop a
            marketing plan, and hold you
            accountable to your goals.</p>
    </div>
    <div class="flex flex-col w-full md:w-1/4">
        <img class="w-4/5 h-auto mx-auto" src="./assets/images/icons/elevate-u-logo.png" alt="Training and Networking Events Image">
        <h3 class="my-20 text-base font-semibold text-center">Training + Networking Events</h3>
        <p class="text-base text-center">ElevateU attendees will hear from some of
            the most successful influencers in the
            industry with access to private breakout
            sessions and networking gatherings for
            more intimate best practice discussions</p>
    </div>
    <div class="flex flex-col w-full md:w-1/4">
        <img class="w-4/5 h-auto mx-auto" src="./assets/images/icons/elevate-masterclass-logo.png" alt="Training and Networking Webinar Image">
        <h3 class="my-20 text-base font-semibold text-center">Training + Networking Webinar</h3>
        <p class="text-base text-center">Masterclass is a virtual learning and networking
            program designed for independent financial
            professionals to increase their knowledge of the
            financial services industry resulting in overall
            growth of their enterprise value.</p>    
    </div>
</div>
