<section class="flex flex-col gap-y-30 p-30">
  <div class="flex flex-col items-center m-auto gap-y-20">
    <img class="w-auto h-50" src="./assets/images/logos/wealthbox-logo-blue.svg" aria-label="Wealthbox Image" alt="Wealthbox logo">
    <p class="text-lg">Connect to your Wealthbox account below to enable searching for your Wealthbox clients in e-application forms.</p>
    @if (isWealthboxConnected) {
      <span class="w-full text xs text-green-dark">You are connected.</span>
      <button type="button"
        class="button is-warning"
        [disabled]="isSubmitting"
        (click)="deactivateWealthbox()">
        Disconnect{{isSubmitting ? 'ing' : ''}}
      </button>
    }@else {
      <button type="button"
        class="button is-primary"
        [disabled]="isSubmitting"
        (click)="activateWealthbox()">
        Sign{{isSubmitting ? 'ing' : ''}} In
      </button>
    }
  </div>
</section>