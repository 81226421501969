import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import orderBy from 'lodash-es/orderBy';
import { forkJoin, Observable } from 'rxjs';
import { fadeIn, fadeInOut } from 'src/app/animations';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { getAverage } from 'src/app/shared/helpers/average.helper';
import { getSum } from 'src/app/shared/helpers/sum.helper';
import { AgentCommissionGroupList, AgentGuidList } from 'src/app/shared/models/agent.models';
import { Policy, PolicyList, PolicyListFilter, PolicyListTypeEnum } from 'src/app/shared/models/policy.models';
import { sidenavConfig } from 'src/app/shared/models/sidenav-config.model';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { InforceTablePolicy } from './models/inforceTablePolicy.model';
import { LineOfBusinessId } from 'src/app/shared/enums/line-business-id.enum';
import { hasAll } from 'src/app/shared/helpers/search.helpers';
import { downloadFile } from 'src/app/shared/helpers/filedownloader.helper';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { BusinessRouteParamReportTypeEnum } from '../../enums/policy-v2.enums';

@Component({
  animations: [fadeIn, fadeInOut],
  selector: 'app-inforce-policy-list',
  templateUrl: './inforce-policy-list.component.html',
})
export class InforcePolicyListComponent implements OnInit, PolicyList {
  @ViewChild(MatSidenav) sidenav?: MatSidenav;
  policyListType = PolicyListTypeEnum.InforceLifecycle;
  allPolicies!: Policy[];
  columnName = ['PolicyStatusDate'];
  currentFilters: PolicyListFilter = new PolicyListFilter();
  getSum = getSum;
  getAverage = getAverage;
  hasSimonOptIn = false;
  isError = false;
  isLoading = true;
  loadingModal!: boolean;
  orderBy = orderBy;
  reportType?: BusinessRouteParamReportTypeEnum | 'undefined';
  reverseOrder = false;
  searchText: string | null = '';
  sidenavConfig: sidenavConfig;
  queryStartDate!: Date;
  queryEndDate!: Date;
  needToQueryAgentPolicies = true;
  averagePremium = 0;
  totalPremium = 0;
  averageFaceAmount = 0;
  totalFaceAmount = 0;
  averageAnnualPremium = 0;
  totalAnnualPremium = 0;
  policyCount = 0;
  isExporting = false;
  filteredPolicies: Policy[] = [];
  inforcePolicies: InforceTablePolicy[] = [];
  routeTitle?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackbar: SnackbarService,
    private reportsApiService: ReportsApiService,
    private amplitudeEventService: AmplitudeEventService,
  ) {
    this.sidenavConfig = {
      IsOpened: true,
      Mode: 'side',
    };
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as BusinessRouteParamReportTypeEnum;
    this.routeTitle = this.activatedRoute.snapshot.data['title'] as string | undefined;
  }

  ngOnInit(): void {
    this.queryStartDate = this.currentFilters.StartDate;
    this.queryEndDate = this.currentFilters.EndDate;
  }

  agentFilter(policy: Policy): boolean {
    const agentIds = this.currentFilters.SelectedAgentIds;
    let match = false;
    if (agentIds && agentIds.length > 0) {
      match = agentIds.some(function (a) {
        return (a.replace(/[{}]/g, '').toLowerCase() === policy.AgentGuid.replace(/[{}]/g, '').toLowerCase())
          || (policy.AssociatedPortalAgentGUIDS != null ? policy.AssociatedPortalAgentGUIDS.split(',').some(function (aa: string) {
            return a.replace(/[{}]/g, '').toLowerCase() === aa.replace(/[{}]/g, '').toLowerCase();
          }) : false);
      });
    }
    return match;

  }

  inforceDateFilter(value: Policy): boolean {
    if (value.InforceDate === null) return true;
    return (new Date(value.InforceDate) >= new Date(this.currentFilters.StartDate) && (new Date(value.InforceDate)) <= new Date(this.currentFilters.EndDate));
  }
  modifiedOnDateFilter(value: Policy): boolean {
    return (new Date(value.ModifiedOn) >= new Date(this.currentFilters.StartDate) && (new Date(value.ModifiedOn)) <= new Date(this.currentFilters.EndDate));
  }

  export(): void {
    if (this.filteredPolicies.length === 0) {
      this.snackbar.openSnackbar('No policies. Please select different filters.', ToastClassEnum.default);
    } else {
      this.snackbar.openSnackbar('Exporting policies, please wait.', ToastClassEnum.default);
      const totalsPolicies = this.getTotalsPolicies();
      const model = {
        IsInforceReport: true,
        AveragePremium: this.getAverage(totalsPolicies, 'SinglePremium'),
        TotalPremium: this.getSum(totalsPolicies, 'SinglePremium'),
        AverageFaceAmount: this.getAverage(totalsPolicies, 'FaceAmount'),
        TotalFaceAmount: this.getSum(totalsPolicies, 'FaceAmount'),
        AverageAnnualPremium: this.getAverage(totalsPolicies, 'AnnualizedPremium'),
        TotalAnnualPremium: this.getSum(totalsPolicies, 'AnnualizedPremium'),
        Policies: this.filteredPolicies.filter(t => !t.IsPartial),
      };
      this.isExporting = true;
      this.reportsApiService.exportPolicies(model).subscribe({
        next: blob => {
          downloadFile(blob, 'PolicyData.xlsx');
          this.snackbar.closeSnackbar();
        },
        error: () => {
          this.snackbar.openSnackbar('Sorry, an error has occurred. Please try again later.', ToastClassEnum.warning);
        }
      }).add(() => {
        this.isExporting = false;
      });
    }
  }


  getAgentGuidList(): AgentGuidList {
    // All agent and lob id's are being used since we are filtering on the front end
    return {
      AgentIds: this.currentFilters.AllAgentIds.join(','),
      LobIds: this.currentFilters.AllLobIds,
      StartDate: this.currentFilters.StartDate,
      EndDate: this.currentFilters.EndDate,
      IncludeUser: false,
      CanSeeAgentCommissions: false
    };
  }

  getCommissionGroupList(): AgentCommissionGroupList {
    return {
      ParentAgentGuid: this.currentFilters.ParentAgentGuid,
      CommissionGroupIds: this.currentFilters.SelectedSubEntities.join(','),
      LobIds: this.currentFilters.AllLobIds,
      StartDate: this.currentFilters.StartDate,
      EndDate: this.currentFilters.EndDate,
      IncludeUser: false,
      CanSeeAgentCommissions: false
    };
  }

  getFilteredPolicies(): Policy[] {
    return this.allPolicies.filter(policy => {
      return this.lobFilter(policy) && (this.currentFilters.SelectedSubEntities.length > 0 || this.agentFilter(policy)) && this.searchTextFilter(policy) && (this.inforceDateFilter(policy)|| this.modifiedOnDateFilter(policy));
    });
  }
  getTotalsPolicies(): Policy[] {
    return this.filteredPolicies.filter(t => !t.IsPartial && !!t.InforceDate && this.inforceDateFilter(t));
  }

  getPolicies(isSubEntities: boolean): void {
    const httpCalls: Observable<Policy[]>[] = [];

    if (isSubEntities) {
      // SubEntities
      const commissionGroupList: AgentCommissionGroupList = this.getCommissionGroupList();
      this.isLoading = true;
      if (this.reportType === 'Fixed') {
        httpCalls.push(this.reportsApiService.getCommissionGroupInforcePolicies(commissionGroupList));
      } else httpCalls.push(this.reportsApiService.getCommissionGroupInforceVariablePolicies(commissionGroupList));
    } else {
      // Agents
      this.isLoading = true;
      const agentGuidList = this.getAgentGuidList();
      if (this.reportType === 'Fixed') {
        httpCalls.push(this.reportsApiService.getInforcePolicies(agentGuidList));
      } else httpCalls.push(this.reportsApiService.getInforceVariablePolicies(agentGuidList));
    }

    forkJoin(httpCalls).subscribe({
      next: res => {
        this.allPolicies = res[0];
        this.setTable();
        this.amplitudeEventService.logBusinessReporting(this.reportType || 'undefined', this.policyListType, this.allPolicies.length);
        this.isError = false;
        this.isLoading = false;
        if (this.currentFilters.CommisionGroupsFilterOn) {
          this.needToQueryAgentPolicies = true;
        } else {
          this.needToQueryAgentPolicies = false;
        }
      },
      error: () => {
        this.isError = true;
        this.snackbar.openSnackbar('Sorry, an error has occurred. Please try again later.', ToastClassEnum.warning);
      }
    }).add(() => {
      this.isLoading = false;
    });
  }

  lobFilter(policy: Policy): boolean {
    let match = false;
    if (this.currentFilters.SelectedLobIds && this.currentFilters.SelectedLobIds.length > 0) {
      match = this.currentFilters.SelectedLobIds.some((lobId) => lobId === policy.LineOfBusinessId);
    }
    return match;
  }

  needsExpandedDateQuery(): boolean {
    return (this.currentFilters.StartDate < this.queryStartDate
      || this.currentFilters.StartDate > this.queryEndDate
      || this.currentFilters.EndDate > this.queryEndDate
      || this.currentFilters.EndDate < this.queryStartDate);
  }

  onFilterChange(filters: PolicyListFilter): void {
    this.currentFilters = filters;

    if (this.currentFilters.SelectedSubEntities.length > 0) {
      this.getPolicies(true);
    } else if (this.needToQueryAgentPolicies || !this.allPolicies || this.currentFilters.IsClearingPolicies || this.needsExpandedDateQuery()) {
      //if policies haven't been loaded make api call OR if changing from subEntities to subAgents
      this.queryStartDate = this.currentFilters.StartDate;
      this.queryEndDate = this.currentFilters.EndDate;
      this.getPolicies(false);
    } else {
      // else just filter existing policies
      this.setTable();
    }
  }

  searchTextFilter(policy: Policy): boolean {
    if (!this.searchText) return true;
    return hasAll(policy, this.searchText);
  }

  setSearchText(searchText: string | null): void {
    this.searchText = searchText;
    this.setTable();
  }

  setTable(): void {
    this.isLoading = true;

    this.filteredPolicies = this.getFilteredPolicies();
    this.inforcePolicies = this.filteredPolicies.map(p => {
      let premium: number | null = null;
      if (p.IsPaidPolicy && p.PolicyGuid) {
        premium = p.IssuedPremium;
      } else {
        if (p.LineOfBusinessId === LineOfBusinessId.Annuity) {
          premium = p.IssuedPremium;
        } else if (p.TotalIssuedPremium) {
          // For All non Annuities, we will show the Totaled Issued Premium = (ModalIssued Premium + 1035Amount + IssuedLumpSum)
          premium = p.TotalIssuedPremium;
        } else if (!p.TotalIssuedPremium) {
          // For All non Annuities, If the Totaled Issued Premium is null or 0, then use the total Modal Premium = (Modal Premium + 1035Amount + IssuedLumpSum)
          premium = p.ModalPremium || null;
        }
      }
      return {
        Agent: p.FirstName + ' ' + p.LastName,
        ActionNeeded: p.ActionNeeded,
        Carrier: p.Carrier,
        Clients: p.Clients,
        FaceAmount: p.FaceAmount,
        Target: p.Target,
        LineOfBusinessId: p.LineOfBusinessId,
        PolicyGuid: p.PolicyGuid,
        PolicyNumber: p.PolicyNumber,
        PolicyStatus: p.PolicyStatus,
        PolicyStatusDate: p.PolicyStatusDate,
        Premium: premium,
        Product: p.Product,
        IsVariable: p.IsVariable,
        InforceDate: p.InforceDate,
        IsPaidPolicy: p.IsPaidPolicy,
      };
    });
    const totalsPolicies = this.getTotalsPolicies();
    this.averagePremium = this.getAverage(totalsPolicies, 'SinglePremium');
    this.totalPremium = this.getSum(totalsPolicies, 'SinglePremium');
    this.averageFaceAmount = this.getAverage(totalsPolicies, 'FaceAmount');
    this.totalFaceAmount = this.getSum(totalsPolicies, 'FaceAmount');
    this.averageAnnualPremium = this.getAverage(totalsPolicies, 'AnnualizedPremium');
    this.totalAnnualPremium = this.getSum(totalsPolicies, 'AnnualizedPremium');
    this.policyCount = totalsPolicies.length;

    this.isLoading = false;
  }

  toggleSideNavMenu(): void {
    this.sidenav?.toggle();
  }

  updateReportType(): void {
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as BusinessRouteParamReportTypeEnum;
    if (this.currentFilters && this.currentFilters.AllAgents && this.currentFilters.AllAgents.length > 0) {
      this.getPolicies(false);
    }
  }
}
