import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './auth/components/error/error.component';
import { LogOutComponent } from './auth/components/log-out/log-out.component';
import { SsoComponent } from './auth/components/sso/sso.component';
import { WealthboxAuthCompleteComponent } from './externals/wealthbox-auth-complete/wealthbox-auth-complete.component';
import { WealthboxAuthRedirectComponent } from './externals/wealthbox-auth-redirect/wealthbox-auth-redirect.component';
import { WealthboxAuthErrorComponent } from './externals/wealthbox-auth-error/wealthbox-auth-error.component';
import { SalesforceAuthCompleteComponent } from './externals/salesforce-auth/salesforce-auth-complete/salesforce-auth-complete.component';
import { SalesforceAuthRedirectComponent } from './externals/salesforce-auth/salesforce-auth-redirect/salesforce-auth-redirect.component';
import { SalesforceAuthErrorComponent } from './externals/salesforce-auth/salesforce-auth-error/salesforce-auth-error.component';

export const AppRoutes: Routes = [
  {
    path: 'Onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
    data: {
      title: 'Onboarding',
      state: 'Onboarding'
    }
  },
  { path: 'Sso', component: SsoComponent },
  { path: 'NewPortal/Sso', redirectTo: 'Sso' },
  {
    path: 'Logout', component: LogOutComponent, data: {
      title: 'Log Out'
    }
  },
  { path: 'Error', component: ErrorComponent },
  {
    path: 'Externals/WealthboxAuth',
    children: [
      { path: 'Complete', component: WealthboxAuthCompleteComponent },
      { path: 'Redirect', component: WealthboxAuthRedirectComponent },
      { path: 'Error', component: WealthboxAuthErrorComponent },
    ]
  },
  {
    path: 'Externals/SalesforceAuth',
    children: [
      { path: 'Complete', component: SalesforceAuthCompleteComponent },
      { path: 'Redirect', component: SalesforceAuthRedirectComponent },
      { path: 'Error', component: SalesforceAuthErrorComponent },
    ]
  },
  {
    path: '',
    redirectTo: 'Portal',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'Portal',
    pathMatch: 'full',
  },
  { path: 'NewPortal/Portal', redirectTo: 'Portal', pathMatch: 'prefix' },
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
