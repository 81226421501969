export enum RequirementStatusEnum {
  Open = 1,
  Complete = 2,
}

export enum FundingFrequencyIdEnum {
  Single = 1,
  Daily = 2,
  Weekly = 3,
  Monthly = 4,
  Annually = 5,
  Unknown = 6,
  Quarterly = 7,
  SemiAnnually = 8,
}