<section class="grid grid-cols-1 gap-x-20 gap-y-20 md:grid-cols-3 max-w-1280" @fadeIn>
  <section class="flex flex-col col-span-1 md:col-span-2 card">
    <div class="flex flex-col p-20 border-b border-solid md:p-30 border-gray-lighter">
      <h2 class="text-lg font-bold">Disability</h2>
      <p>Contact us to find out more about Disability.</p>
    </div>
    <div class="flex p-10">
      <a mat-button
        class="flex flex-row items-center justify-center px-20 m-10 button is-lg is-primary is-inverted"
        href="tel:8888413045" target=_self>
        <i class="mr-10 mi-18 material-icons">smartphone</i>
        <span>(888) 841-3045</span>
      </a>
      <a mat-button
        class="flex flex-row items-center justify-center px-20 m-10 button is-lg is-primary is-inverted"
        href="mailTo:di@mgaprt.com" target=_blank>
        <i class="mr-10 mi-18 material-icons">email</i>
        <span>di&#64;mgaprt.com</span>
      </a>
    </div>
  </section>
  <section class="flex flex-col flex-grow-0 col-span-1 bg-center bg-no-repeat bg-cover md:row-span-2 card">
    <div class="flex items-center p-20 border-b border-solid md:p-30 border-gray-lighter">
      <i class="pr-10 material-icons-outlined">local_shipping</i>
      <h2 class="text-lg font-bold leading-none">Carriers</h2>
    </div>
    <section class="flex flex-row flex-wrap w-full p-10 md:p-20">
      @for (product of carriers; track product; let last = $last) {
        <div class="relative w-full p-10 overflow-hidden rounded sm:w-1/2" [class]="last ? '' : 'mb-10 sm:mb-20'">
          @if (product.Url) {
            <a class="absolute top-0 left-0 w-full h-full transition-colors duration-150 bg-opacity-0 hover:bg-blue-lighter hover:bg-opacity-25"
              [href]="product.Url" matTooltip="Visit {{product.Title}}" matTooltipPosition="below" target="_blank"
            rel="noopener" attr.aria-label="{{product.Title}} link"></a>
          }
          <img class="w-full h-auto mx-auto max-w-128" [src]="product.ImgUrl" [alt]="product.Title + ' logo'" />
        </div>
      }
    </section>
  </section>
  <section class="flex flex-col col-span-1 md:col-span-2 card">
    <div class="flex p-20 border-b border-solid md:p-30 border-gray-lighter">
      <i class="mr-10 material-icons-outlined">menu_book</i>
      <h2 class="text-lg font-bold">News &amp; Updates</h2>
    </div>
    <div class="flex flex-col m-10 md:m-20">
      @for (block of disabilityBlocks; track block; let last = $last) {
        <div class="flex flex-row items-center flex-none p-10 overflow-hidden rounded cursor-pointer hover:bg-blue-lighter hover:bg-opacity-25"
          [class]="last ? '' : 'mb-20 md:mb-30'" matTooltip="View pdf" matTooltipPosition="above" rel="noopener"
          attr.aria-label="{{block.Title}} link"
          (click)="openUrl(block.Url)" (keypress)="openUrl(block.Url)">
          <img class="h-auto mr-20 rounded w-192" src="./assets/images/{{block.Img}}" [alt]="block.Title + ' logo'" />
          <div class="flex flex-col w-full md:w-4/5">
            <h3 class="mb-5 font-bold leading-tight text-gray-dark" [innerHtml]="block.Title"></h3>
            <!-- <img class="w-auto h-48 mb-10 mr-auto" [src]="block.Icon"> -->
            <p class="text-sm text-left text-gray-dark" [innerHtml]="block.Description"></p>
          </div>
        </div>
      }
    </div>
  </section>
</section>