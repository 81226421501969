<div class="flex flex-col items-center justify-center w-1/5 card h-2/4">
  <h2 class="mb-5 text-lg text-gray-light">Redirecting to Agent Portal. Please wait...</h2>
  @if (isError) {
    <h2 class="mb-5 text-lg text-red">An error occurred, please try again.</h2>
  }
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>