export const ChangeLogoCyIdConst = {
  hasLogo: 'hasLogo',
  noLogo: 'noLogo',
  openLogoModalBtn: 'openUserLogoModal',
  modalTitle: 'modalTitle',
  logoLabel: 'logoLabel',
  confirmationAcceptBtn: 'confirmationAcceptBtn',
  confirmationTitle: 'confirmationTitle',
  confirmationDeclineBtn: 'confirmationDeclineBtn',
  chooseNewLogo: 'chooseNewLogo',
  uploadSection: 'uploadSection',
  removeCurrentLogoBtn: 'removeCurrentLogoBtn',
  uploadLogoSubmitBtn: 'uploadLogo',
  closeModalBtn: 'closeIcon',
};