import { Injectable } from '@angular/core';
import { onboardingSteps } from 'src/app/shared/constants/onboarding.constants';
import { MasterAdvisorAgreement, MasterAdvisorAgreementRequiredModel } from 'src/app/shared/models/agreement.models';
import { OnboardingAgentLicensesOptOut, OnboardingContracting, OnboardingTermsOfUse } from 'src/app/shared/models/onboarding.models';
import { SessionStoreService } from './stores/session-store.service';
import { PermissionService } from './auth/permission.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  constructor(
    private sessionStore: SessionStoreService,
    private permissionService: PermissionService,
  ) {
  }

  getRequiredOnboardingSteps(onboardingModel: OnboardingModel): OnboardingStepModel {
    const hasSureLcAccess = this.permissionService.hasAllPermissions('SureLc:Access');
    const hasCompletedMAA = !!onboardingModel.userMasterAdvisorAgreement || !onboardingModel.userMasterAdvisorAgreementRequired;
    const hasCompletedContracting = onboardingModel.userContracting === null || (onboardingModel.userContracting !== null && onboardingModel.userContracting.IsCompleted === true);
    const hasCompletedNpnCrd = (onboardingModel.userAgentLicensesOptOut !== null && onboardingModel.userAgentLicensesOptOut.HasOptedOut === true) || (!!this.sessionStore.User.AgentLicenses.Crd || !!this.sessionStore.User.AgentLicenses.Npn);
    const hasAgreedToTermsOfUse = onboardingModel.termsOfUse !== null && onboardingModel.termsOfUse.HasAgreedToTermsOfUse;
    const onboardingStepModel: OnboardingStepModel = {
      RequiredSteps: [],
      ShowAlternateView: false
    };

    // If they haven't completed anything and have SureLC access, give them all the steps
    if (!hasCompletedMAA && !hasCompletedContracting && hasSureLcAccess) {
      onboardingStepModel.RequiredSteps = onboardingSteps.map(step => step.Id);
      return onboardingStepModel;
    }

    // If they haven't completed anything and do not have SureLC access, give them all steps except contracting
    if (!hasCompletedMAA && !hasCompletedContracting && !hasSureLcAccess) {
      onboardingStepModel.RequiredSteps = onboardingSteps.filter(step => step.Id !== 4).map(step => step.Id);
      return onboardingStepModel;
    }

    if (!hasAgreedToTermsOfUse) {
      onboardingStepModel.RequiredSteps.push(1);
    }

    if (!hasCompletedMAA) {
      onboardingStepModel.RequiredSteps.push(2); onboardingStepModel.ShowAlternateView = true;
    }

    if (!hasCompletedNpnCrd && !!this.sessionStore.User.AgentArcGuid) {
      onboardingStepModel.RequiredSteps.push(3); onboardingStepModel.ShowAlternateView = true;
    }

    // Not completing contracting is a sign that this is their first onboarding, so add portal preview pages
    if (!hasCompletedContracting && hasSureLcAccess) {
      onboardingStepModel.RequiredSteps.push(4, 5); onboardingStepModel.ShowAlternateView = false;
    }

    // Remove possible duplicate steps
    onboardingStepModel.RequiredSteps = [...new Set(onboardingStepModel.RequiredSteps.sort())];

    return onboardingStepModel;
  }
}

export interface OnboardingStepModel {
  RequiredSteps: number[],
  ShowAlternateView: boolean
}

export interface OnboardingModel {
  userMasterAdvisorAgreement: MasterAdvisorAgreement,
  userMasterAdvisorAgreementRequired: MasterAdvisorAgreementRequiredModel,
  userAgentLicensesOptOut: OnboardingAgentLicensesOptOut,
  userContracting: OnboardingContracting,
  termsOfUse: OnboardingTermsOfUse
}