<cdk-virtual-scroll-viewport itemSize="51" class="w-full h-full min-h-0 px-30" #virtualScroll @fadeIn>
  @if (!isLoading) {
    <table @fadeIn class="text-sm table-auto custom-table">
      <thead>
        <tr>
          @for (cell of table.Header; track cell) {
            <td  class="{{cell.WidthClass}}">
              <span (click)="orderTableBy(cell)" (keypress)="orderTableBy(cell)"
                class="flex items-center leading-6 cursor-pointer min-h-24 focus:outline-none"
                attr.aria-label="Sort by {{cell.DisplayName}}">
                <span class="mr-5">{{cell.DisplayName}}</span>
                @if (cell === table.OrderByColumn) {
                  <span class="material-icons text-blue">
                    {{table.AscendingOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                  </span>
                }
              </span>
            </td>
          }
        </tr>
      </thead>
      <tbody>
        @for (row of table.Body; track row) {
          <tr id="policyRow" class="bg-opacity-25 group" aria-label="Go to policy details"
            >
            <td class="p-10 group-hover:bg-transparent">
              {{row.Clients}}
            </td>
            <td class="p-10 group-hover:bg-transparent">
              {{row.AccountType}}
            </td>
            <td class="p-10 group-hover:bg-transparent">
              <div class="flex flex-col w-full">
                <span class="font-bold">{{row.Custodian}}</span>
                <span>{{row.AccountNumber}}</span>
              </div>
            </td>
            <td class="p-10 group-hover:bg-transparent">
              {{row.Model ? row.Model : '---'}}
            </td>
            <td class="p-10 group-hover:bg-transparent">
              {{row.CurrentValue ? (row.CurrentValue | currency) : '---'}}
            </td>
            <td class="p-10 group-hover:bg-transparent">
              {{row.AccountStatus ? row.AccountStatus : '---'}}
            </td>
            <td class="p-10 group-hover:bg-transparent">
              {{row.DateOpened ? (row.DateOpened | date: 'MM/dd/yyyy' : 'UTC') : '---'}}
            </td>
            <td class="p-10 group-hover:bg-transparent">
              {{row.ClientFee ? (row.ClientFee | percent:'1.0-2') : '---'}}
            </td>
          </tr>
        }
        @if (isError) {
          <tr id="policyErrorRow" class="" aria-label="Error getting policies">
            <td class="p-10 group-hover:bg-transparent"></td>
            <td class="p-10 group-hover:bg-transparent" colspan="6"><span class="text-red-dark">Sorry, an error has occurred. Please try again later.</span></td>
          </tr>
        }
        @if (!isError && table.Body.length===0) {
          <tr id="policyEmptyRow" class="" aria-label="No policies found">
            <td class="p-10 group-hover:bg-transparent"></td>
            <td class="p-10 group-hover:bg-transparent" colspan="6">No policies found.</td>
          </tr>
        }
      </tbody>
    </table>
  } @else {
    <img @fadeIn src="./assets/lottie/static/policy-details-v2.svg" alt="Loading placeholder" class="animate-pulse">
  }
</cdk-virtual-scroll-viewport>
