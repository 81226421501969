import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { Table, TableHeader } from 'src/app/shared/classes/table';
import { BusinessRouteParamReportTypeEnum } from '../../../../enums/policy-v2.enums';
import { InformalTableHeadersV2 } from '../../constants/informalPolicyTableHeaders.constant';
import { InformalTablePolicy } from '../../models/informalTablePolicy.model';

@Component({
  animations: [fadeIn],
  selector: 'app-informal-policy-table',
  templateUrl: './informal-policy-table.component.html'
})
export class InformalPolicyTableComponent implements OnChanges {
  @ViewChild(CdkVirtualScrollViewport) virtualScroll?: CdkVirtualScrollViewport;
  @Input() informalPolicies: InformalTablePolicy[] = [];
  @Input() reportType?: BusinessRouteParamReportTypeEnum;
  table: Table<InformalTablePolicy> = new Table<InformalTablePolicy>(InformalTableHeadersV2, 1, true);
  @Input() isError = false;

  constructor(private router: Router,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['informalPolicies']?.previousValue !== changes['informalPolicies']?.currentValue) {
      this.table.setBody(this.informalPolicies);
    }
  }

  openPolicyDetails(row: InformalTablePolicy): void {
    if (this.reportType) {
      const rowType = row.NewWorkflow ? 'V2' : '';
      const routerUrl = `/Portal/Business/${this.reportType}/InformalPolicyDetails${rowType + '/' + row.InformalGuid}`;
      this.router.navigate([routerUrl],
        {
          state: {
            InformalPolicy: row,
            PastPolicyListState: {
              Url: '/Portal/Business/' + this.reportType + '/Informal',
              FilterOptions: {} // For future use to maintain filtered params.
            }
          }
        });
    }
  }

  orderTableBy(col: TableHeader<InformalTablePolicy>): void {
    this.table.orderBy(col);
    this.virtualScroll?.scrollTo({ top: 0 });
  }
}
