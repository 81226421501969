import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UserGroupApiService } from 'src/app/core/services/http/user-group-api.service';
import { LogOutReason } from 'src/app/shared/models/logout-reason.model';

@Component({
  selector: 'app-log-out',
  templateUrl: './log-out.component.html',
})
export class LogOutComponent implements OnInit {
  userGroupId = this.activatedRoute.snapshot.queryParamMap.get('userGroupId');
  logoutReason = this.activatedRoute.snapshot.queryParamMap.get('reason') as LogOutReason;
  logoutText = '';
  copyYear = this.getYear();
  logoUrl = './assets/images/fig-logo.svg';
  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService, private userGroupApiService: UserGroupApiService) { }

  ngOnInit(): void {
    this.logoutText = this.generateLogoutText();
    this.getUserLogo();
  }

  getUserLogo(): void {
    if (this.userGroupId) {
      this.userGroupApiService.getLogo(this.userGroupId).subscribe({
        next: () => {
          this.logoUrl = `/api/UserGroups/${this.userGroupId}/Logo`;
        }
      });
    }
  }

  /**
   * Reason inactive is passed from the auth.service timer that auto-logout after the .timer.potal cookie has expired
   */
  generateLogoutText(): string {
    if (this.logoutReason === 'inactive') {
      return 'You have been logged out due to inactivity';
    } else {
      return 'You have been successfully logged out';
    }
  }

  goToLogIn(): void {
    this.authService.logIn();
  }

  getYear(): number {
    return new Date().getFullYear();
  }
}
