<div class="flex flex-col w-full h-full">
  <section class="justify-center dialog-content gap-y-30" @fadeIn>
    <div class="flex items-center justify-center mx-10">
      <img class="w-auto h-60" src="./assets/images/logos/redtail-logo.svg" alt="Redtail Logo">
    </div>
    <span class="m-10 text-lg">
      Welcome to Redtail. Enter your <strong>Username</strong> and <strong>Password</strong> below to continue.
    </span>
    <form [formGroup]="redtailAuthForm" class="flex-col w-full mx-auto max-w-400">
      <div class="flex flex-col flex-shrink-0 mx-10 mb-20 gap-y-5">
        <label for="username" class="text-sm label text-gray" for="username">Username</label>
        <input class="input" type="text" placeholder="Username" name="Username" formControlName="username" appCyData="redtailUsername" />
        @if (usernameCtrl.touched && usernameCtrl.errors) {
          <span class="w-full text-xs text-red"
            >
            @if (usernameCtrl.hasError('required')) {
              <span>This field is required.</span>
            }
          </span>
        }
      </div>
      <div class="flex flex-col flex-shrink-0 mx-10 gap-y-5">
        <label for="password" class="text-sm label text-gray" for="password">Password</label>
        <input class="input" type="password" placeholder="Password" formControlName="password" appCyData="redtailPassword" />
        @if (passwordCtrl.touched && passwordCtrl.errors) {
          <span class="w-full text-xs text-red">
            @if (passwordCtrl.hasError('required')) {
              <span>This field is required.</span>
            }
          </span>
        }
      </div>
      <div class="flex items-center justify-center mx-10 mt-20 md:mt-30 gap-y-10">
        @if (isRedtailAuthenticated) {
          <span class="w-full text xs text-green-dark">You are connected.</span>
          <button mat-button type="button" class="ml-auto button is-warning"
            (click)="deactivateRedTail()"
            [disabled]="isSubmitting"
            appCyData="redtailDisconnectButton">
            Disconnect{{isSubmitting ? 'ing' : ''}}
          </button>
        }@else {
          <button mat-button class="ml-auto button is-primary" type="submit"
            (click)="authenticateWithRedTail()"
            [disabled]="isSubmitting"
            appCyData="redtailConnectButton">
            Connect{{isSubmitting ? 'ing' : ''}}
          </button>
        }
      </div>
    </form>
  </section>
</div>