import { Injectable } from '@angular/core';
import { PermissionApiService } from '../http/permission-api.service';
import { PermissionService } from '../auth/permission.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private permissionApiService:PermissionApiService,
    private permissionService:PermissionService,
    private authService:AuthService) { }

  async setPermissions(): Promise<void>{
    const permissions = await this.permissionApiService.getPolicyStatements().toPromise().catch(() => console.error('Unauthenticated'));
    if (permissions) this.permissionService.setPermissions(permissions);
    return;
  }
}
