import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OldPortalRouterService {

  constructor(private authService:AuthService,) { }

  handleOldPortalLinks(): void {
    if (!window.location.hash) {
      return;
    }
    const hash = window.location.hash;
    const params = new URL(window.location.href.replace(/#/g,'')).searchParams;
    const theme = params.get('theme');
    window.location.hash = '';

    if (theme) {
      localStorage.setItem('UserGroupId', theme);
    }
    if (hash.startsWith('#/Tools/MarketingTrackerNew')) {
      window.location.href = '/v1/' + hash;
    }
  }
}
