import { Component, Input, OnInit } from '@angular/core';
import { ResourcePage, PurchasePageData } from 'src/app/shared/models/resource-page.models';
import { CompleteEnrollmentCallbackModel, OrderModel, PurchaseCallbackModel } from 'src/app/shared/models/order.models';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { PurchaseModalComponent } from 'src/app/portal/modals/purchase-modal/purchase-modal.component';
import { PurchaseApiService } from 'src/app/core/services/http/purchase-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/portal/modals/confirmation/confirmation.component';
import { EnrollmentFormComponent } from 'src/app/portal/modals/enrollment-form/enrollment-form.component';
import { SurveyMarketingStrategyModel } from 'src/app/shared/models/surveyMarketingStrategy.models';
@Component({
  selector: 'app-surge-workflow',
  templateUrl: './surge-workflow.component.html'
})
export class SurgeWorkflowComponent implements OnInit {
  @Input() resource?: ResourcePage;
  purchased!: OrderModel | null;
  capstone!: SurveyMarketingStrategyModel;
  intervals: any[] = [];  // TODO: This needs to be rewritten to avoid using any
  @Input() submitted?: { surveyId: number, isEnrolled: boolean }[];

  constructor(
    private sessionStore: SessionStoreService,
    private purchaseApiService: PurchaseApiService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.purchaseApiService.getMarketingStrategyByName('Capstone').subscribe(strategey => {
      this.capstone = strategey;
    });
    this.verifyPurchased();
  }

  openEnrollmentModal(surveyId: number): void {
    if (this.resource) {
      const dialogRef = this.dialog.open(EnrollmentFormComponent, {
        data: {
          Event: this.resource.EnrollmentSurveyData?.Event,
          SurveyId: surveyId,
          Disclaimer: this.resource.EnrollmentSurveyData?.Disclaimer
        }
      });

      dialogRef.afterClosed().subscribe((res: CompleteEnrollmentCallbackModel) => {
        const survey = this.submitted?.find(item => item.surveyId === surveyId);
        if (survey && res.hasCompletedEnrollment) survey.isEnrolled = true;
      });
    }
  }

  openDocusignLink(url: string): void {
    window.open(url);
  }

  verifyPurchased(): void {
    if (this.resource) {
      this.purchased = null;
      let o: OrderModel | null = null;
      this.purchaseApiService.getUserOrders(this.sessionStore.User.Id).subscribe(orders => {
        orders.forEach(order => {
          if (order.MarketingStrategy === this.resource?.PurchasePageData?.PurchaseModel.MarketingStrategy) {
            const d = new Date(order.CompletedDate as Date);
            const n = d.toLocaleDateString();
            order.PurchaseDate = n;
            if (order.Id) {
              this.purchaseApiService.getMarketingStrategyOfOrder(order.Id).subscribe(strategy => {
                if (strategy.DocusignUrl && strategy.DocusignUrl !== null && strategy.DocusignUrl !== '') {
                  order.DocusignUrl = strategy.DocusignUrl;
                }
              });

              o = order;
            }
          }
        });

        if (o !== null) {
          this.intervals?.forEach((interval: any) => {
            clearInterval(interval);
          });
          this.purchased = o;
        }
      });
    }
  }

  pollVerifyPurchase(paymentAmount: number): void {
    if (paymentAmount > 0) {
      this.intervals.push(setInterval(() => {
        this.verifyPurchased();
      }, 3000));
    } else {
      this.verifyPurchased();
    }
  }

  openPurchaseDialog(): void {
    const data = this.resource?.PurchasePageData;
    if (data) {
      if (data?.ConfirmationMessage) {
        // Confirmation required
        const confirmation = this.dialog.open(ConfirmationComponent, {
          data: {
            title: data.PurchaseModel.Title,
            message: data.ConfirmationMessage
          }
        });

        confirmation.afterClosed().subscribe(result => {
          if (result) {
            // confirmation accepted
            this.purchaseRsource(data);
          }
        });
      } else {
        // confirmation not requried
        this.purchaseRsource(data);
      }
    }
  }

  purchaseRsource(data: PurchasePageData): void {

    this.purchaseApiService.getAllOrders().subscribe(orders => {

      data.PurchaseModel.Orders = orders;
      this.purchaseApiService.getMarketingDollars().subscribe(agentMarketingDollars => {
        data.PurchaseModel.AgentMarketingDollars = agentMarketingDollars.Entry.MarketingDollarsTotal;
      }, error => {
        console.log(error);
        data.PurchaseModel.AgentMarketingDollars = 0;
      }, () => {
        const ref = this.dialog.open(PurchaseModalComponent, {
          data: {
            purchaseModel: data.PurchaseModel,
          }
        });

        ref.afterClosed().subscribe((result: PurchaseCallbackModel) => {
          if (result) {
            this.pollVerifyPurchase(result.paymentAmount);
          }
        });
      });
    });
  }
}

