import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PolicyListStoreService {
  private today = new Date();
  private ytd = new Date(this.today.getUTCFullYear(), 0, 1);
  public baseStartDate: Date = this.ytd;
  /** We're setting the end date to be today + 1 since ARC reporting counts it as less than tomorrow */
  public baseEndDate: Date = new Date(this.today.getUTCFullYear(), this.today.getUTCMonth(), this.today.getUTCDate() + 1);

  private selectedAgentIds!: string[];
  getSelectedAgentIds(): string[] {
    return this.selectedAgentIds;
  }
  setSelectedAgentIds(agentIds: string[]): void {
    this.selectedAgentIds = agentIds;
  }

  private selectedSubEntities: string[] = [];
  getselectedSubEntities(): string[] {
    return this.selectedSubEntities;
  }
  setselectedSubEntities(subEntities: string[]): void {
    this.selectedSubEntities = subEntities;
  }

  private selectedReportType = 'Pending';
  getSelectedReportType(): string {
    return this.selectedReportType;
  }

  setSelectedReportType(reportType: string): void {
    this.selectedReportType = reportType;
  }

  private selectedLobIds: number[] = [1, 2, 4, 3];
  getSelectedLobIds(): number[] {
    return this.selectedLobIds;
  }
  setSelectedLobIds(lobIds: number[]): void {
    this.selectedLobIds = lobIds;
  }

  private startDate: Date = this.baseStartDate;
  getStartDate(): Date {
    return this.startDate;
  }
  setStartDate(date: Date): void {
    this.startDate = date;
  }

  private endDate: Date = this.baseEndDate;
  /** We're setting the end date to be today + 1 since ARC reporting counts it as less than tomorrow */
  getEndDate(): Date {
    return this.endDate;
  }
  setEndDate(date: Date): void {
    this.endDate = date;
  }
}
