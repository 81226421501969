import { NgModule } from '@angular/core';

import { SsoComponent } from './components/sso/sso.component';
import { LogOutComponent } from './components/log-out/log-out.component';
import { SharedModule } from '../shared/shared.module';
import { ErrorComponent } from './components/error/error.component';


@NgModule({
  declarations: [
    SsoComponent,
    LogOutComponent,
    ErrorComponent
  ],
  imports: [
    SharedModule
  ]
})
export class AuthModule { }
