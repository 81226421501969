/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const getSum = (data:any[], key:string):number => {
  if (typeof (data) === 'undefined' || typeof (key) === 'undefined') {
    return 0;
  }

  let sum = 0;
  for (let i = data.length - 1; i >= 0; i--) {
    if (!isNaN(parseInt(data[i][key])))
      sum += data[i][key];
  }

  return sum;
};
