<section class="relative flex p-10 xl:w-384 w-300">
  <i class="absolute mr-10 transform -translate-y-1/2 left-15 material-icons text-blue top-1/2">search</i>
  <input #searchInput class="w-full border-2 input is-lg border-blue-light is-inverted pl-36 bg-gray-lightest dark:bg-gray-darker dark:border-gray-darker" type="text" name="search"
    aria-label="Search Portal Resources" placeholder="Search Portal Resources"
    (blur)="trackClickAway()"
    (keyup)="searchSubject.next(searchText)"
    [matAutocomplete]="searchAuto" [(ngModel)]="searchText" />
    <mat-autocomplete #searchAuto="matAutocomplete"
      class="flex flex-col p-10 bg-white gap-y-5 select min-h-256 is-inverted md-no-underline wrap-shadow-none"
      [displayWith]="displayFn"
      (optionSelected)="resultSelected($event)">
      @if (isSearching && (searchText && searchText.length > 1) && !searchResultsList.length) {
        <mat-option class="h-4">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-option>
      }
      @for (r of searchResultsList; track r) {
        <mat-option (mousedown)=$event.preventDefault() class="flex w-full p-5 overflow-visible custom-mat-option" [value]="r">
          <app-search-result-v2 class="flex w-full" [searchText]="searchText" [searchResult]="r"></app-search-result-v2>
        </mat-option>
      }
      @if (!isSearching && (searchText && searchText.length > 1) && !searchResultsList.length) {
        <mat-option disabled
          class="flex w-full h-full p-10 overflow-visible text-base rounded custom-mat-option">
          <span>No results found</span>
        </mat-option>
      }
    </mat-autocomplete>
  </section>