<section class="flex flex-col flex-none w-full h-full">
  <div id="leaderboardToolbar" class="flex items-center flex-none p-20 border-b border-solid lg:p-30 border-gray-lighter">
    <h1 class="mr-auto text-lg font-bold">Marketing Expense History</h1>
    <button class="icon-button is-primary is-inverted" tabindex="-1" aria-label="Close" [mat-dialog-close]
      type="button"><i class="material-icons">close</i>
    </button>
  </div>
  <section class="relative w-full h-full flex-grow overflow-auto">
    <table class="w-full table-auto">
      <thead>
        <tr class="sticky top-0">
          @for (header of headers; track header) {
            <td (click)="orderRows(header.PropertyName)"
              attr.aria-label="Sort by {{header.DisplayName}}"
              class="sticky top-0 z-10 p-10 px-20 text-xs font-semibold uppercase bg-white border-b border-solid cursor-pointer border-gray-lighter text-gray hover:bg-blue-lightest">
              <span class="flex items-center focus:outline-none">
                <span class="flex items-center mr-5 focus:outline-none">{{header.DisplayName}}</span>
                @if (columnName === header.PropertyName && !reverseOrder) {
                  <span
                  class="material-icons">arrow_drop_down</span>
                }@else if (columnName === header.PropertyName && reverseOrder) {
                  <span
                  class="material-icons">arrow_drop_up</span>
                }
              </span>
            </td>
          }
        </tr>
      </thead>
      <tbody>
        @for (expense of marketingExpenseHistory; track expense) {
          <tr class="h-full hover:bg-blue-lightest">
            <td class="p-10 px-20 text-sm border-b border-solid border-gray-lighter">
              {{expense.UpdatedOn | date:"shortDate"}}
            </td>
            <td class="p-10 px-20 text-sm border-b border-solid border-gray-lighter">
              {{expense.Vendor}}
            </td>
            <td class="p-10 px-20 text-sm border-b border-solid border-gray-lighter">
              {{expense.Notes}}
            </td>
            <td class="p-10 px-20 text-sm border-b border-solid text-g border-gray-lighter">
              <span
              [ngClass]="{'text-red' : expense.Amount < 0, 'text-green' : expense.Amount > 0}">{{expense.Amount | currency}}</span>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </section>
  <section class="flex flex-none p-20 border-t border-solid lg:p-30 border-gray-lighter">
    <button (click)="exportMarketingExpenseHistory()" [disabled]="exportingList" tabindex="-1"
      class="ml-auto button is-primary" type="button">
    {{exportingList ? 'Exporting' : 'Export'}}</button>
  </section>
</section>