export enum TaskCategoryIDEnum {
  agentContracting = 1,
  application = 2,
  phoneHistoryInterview = 3,
  paramed = 4,
  attendingPhysicianStatement = 5,
  underwriting = 6,
  underwritingDecision = 7,
  issue = 8,
  inforce = 9,
  cmNote = 10,
  carrierUpdate = 11,
}

export enum TaskStatusEnum {
  Open = 1,
  Complete = 2,
}

export enum InformalTaskStatusEnum {
  Open = 'Open',
  Complete = 'Complete',
}

/**
 * Due to new structure changes in ARC, each task subcategory/checklist item will have their unique ID, the 2 below are reserved for policy-level tasks
 * @param filesAndNotes will always be 5. Used when agent sends a file upload with or w/o notes on the policy level
 * @param notes will always be 8. Used when agent sends only note on the policy level
 */
export enum AgentMessageTaskId {
  filesAndNotes = 5,
  notes = 8,
}

export enum BusinessRouteParamReportTypeEnum {
  fixed = 'Fixed',
  variable = 'Variable',
  structured = 'Structured',
  wealth = 'Wealth',
  lifecycle = 'Lifecycle',
  policyDetails = 'PolicyDetails',
}