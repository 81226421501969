
<button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openEnrollmentModal()"
  [disabled]="hasSubmittedAnyForm">
  @if (!hasSubmittedAnyForm) {
    <span>
      Enroll Today
    </span>
  }
  @else {
    <span matTooltip="Elevate enrollment form already submitted" matTooltipPosition="above">Submitted</span>
  }
</button>

@if (pendingRenewal) {
  <div>
    <p class="mt-10 mb-0"><em>Your enrollment in Elevate will expire soon, please click the button below to view your renewal options.</em></p>
    <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="openSurveyRenewalDialog()">
      <span>
        View My Renewal
      </span>
    </button>
  </div>
}