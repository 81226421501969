<div class="flex flex-col">
  <div class="relative block w-4/5 max-w-full m-20 mx-auto">
    <img class="w-full h-auto" src="./assets/images/12-Step_Process.png" alt="12 Step Process Image">
  </div>
  <div class="flex flex-row flex-wrap">
    @for (step of nextSteps; track step) {
      <div class="flex flex-row flex-grow-0 w-1/2 p-20 lg:p-30">
        <span
          class="flex flex-row items-center justify-center w-32 h-32 mr-10 -mt-5 rounded-full min-w-32 min-h-32 bg-blue-lighter">
          <span class="font-bold leading-none text-blue-dark" [innerText]="step.Id">1</span>
        </span>
        <div class="w-auto">
          <p class="mb-10 font-semibold" [innerHtml]="step.Title"></p>
          @for (paragraph of step.Text; track paragraph) {
            <p class="mb-10" [innerHtml]="paragraph"></p>
          }
        </div>
      </div>
    }
  </div>
</div>