import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from 'src/app/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-medicare',
  templateUrl: './medicare.component.html'
})
export class MedicareComponent {

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  getResourceUrl(): string | null {
    return this.activatedRoute.snapshot.paramMap.get('resource');
  }
}
