<section class="flex flex-col gap-y-30 p-30" @fadeIn>
  <app-explore-header></app-explore-header>
  <div class="flex flex-col flex-none gap-y-20">
    <div class="flex items-center gap-x-10">
      <form class="relative flex flex-grow border border-solid rounded max-w-384 border-gray-lighter" [formGroup]="searchForm">
        <input type="search"
          class="w-full rounded-r-none input is-inverted"
          placeholder="Search..."
          formControlName="searchText" />
          <button class="rounded-l-none icon-button is-primary" type="submit">
            <i class="material-icons">search</i>
          </button>
        </form>
        <span class="mx-10 divider h-36 is-vertical"></span>
        <ng-container>
          <button type="button" class="button is-primary is-inverted min-w-96" mat-button [matMenuTriggerFor]="sortMenu">
            <i class="mr-5 material-icons mi-18">sort</i>
            <span>Sort</span>
            @if (selectedSortOption) {
              <span>: {{selectedSortOption.propName}} {{sortOrder === 'asc' ? 'A-Z' : 'Z-A'}}</span>
            }
          </button>
          <mat-menu #sortMenu="matMenu">
            @for (item of sortOptions; track item) {
              <button type="button" mat-menu-item (click)="sortTilesV2(item)">
                @if (item === selectedSortOption) {
                  <span>Sort by {{item.propName}} {{sortOrder === 'asc' ? 'Z-A' : 'A-Z'}}</span>
                }
                @if (item !== selectedSortOption) {
                  <span>Sort by {{item.propName}} A-Z</span>
                }
              </button>
            }
          </mat-menu>
        </ng-container>
        <ng-container>
          <button type="button" class="relative button is-primary is-inverted min-w-96" mat-button [matMenuTriggerFor]="filterMenu">
            @if (hasActiveFilters) {
              <span class="absolute top-0 right-0 w-10 h-10 m-4 rounded-full bg-blue"></span>
            }
            <i class="mr-5 material-icons mi-18">filter_alt</i>
            <span>Filter</span>
          </button>
          <mat-menu #filterMenu="matMenu">
            <div class="flex flex-col p-10 gap-y-10" (click)="$event.stopPropagation()">
              <span class="text-sm font-semibold label text-gray">Categories:</span>
              @for (category of categoryFilters; track category) {
                <mat-checkbox
                  [(ngModel)]="category.checked"
                  (ngModelChange)="filterTiles()"
                class="text-base checkbox text-gray-dark">{{category.displayName}}</mat-checkbox>
              }
            </div>
          </mat-menu>
        </ng-container>
      </div>
      @if (hasActiveFilters) {
        <div class="flex flex-col gap-y-10">
          <mat-chip-list aria-label="Active filters" [selectable]="false">
            <span class="mx-5 text-sm font-semibold text-gray">Categories:</span>
            @for (cat of categoryFilters; track cat) {
              @if (cat.checked) {
                <mat-chip class="text-sm font-semibold bg-gray-lighter text-gray-dark" (removed)="removeFilter(cat)">
                  <span class="flex items-center justify-center">
                    <span>{{cat.displayName}}</span>
                    <button type="button" matChipRemove class="flex items-center justify-center w-24 h-24 rounded-full min-w-24 text-red">
                      <i class="material-icons mi-18">cancel</i>
                    </button>
                  </span>
                </mat-chip>
              }
            }
          </mat-chip-list>
        </div>
      }
    </div>
    <table class="-my-20 table-auto custom-table-card">
      <tbody>
        @if (filteredTiles.length) {
          @for (tile of filteredTiles; track tile) {
            <tr class="relative">
              <td class="text-center bg-right bg-no-repeat bg-cover bg-explore-list-wave w-100">
                <span class="flex rounded-full bg-gray-lighter w-36 h-36">
                  <i class="m-auto material-icons mi-18"
                  [class]="tile.Fields.ForegroundColor">{{tile.Fields.Icon}}</i>
                </span>
              </td>
              <td class="pl-20 text-sm font-bold w-280">{{tile.Fields.Title}}</td>
              <td class="hidden text-sm sm:table-cell text-gray w-360">{{tile.Fields.Description}}</td>
              <td class="text-center">
                <span class="px-10 py-5 text-sm font-semibold rounded-full whitespace-nowrap bg-gray-lightest" [class]="tile.Fields.ForegroundColor">{{tile.Fields.CategoryName}}</span>
              </td>
              <td class="text-center">
                @if (tile.Fields.ExternalUrl) {
                  <a mat-button class="button is-primary is-outlined"
                    [href]="tile.Fields.ExternalUrl"
                    target="_blank" [amplitudeEvent]="'Platform Tiles - ' + tile.Fields.Title"
                  >Learn More</a>
                }
                @if (tile.Fields.AngularRoute) {
                  <a mat-button class="button is-primary is-outlined"
                    [routerLink]="tile.Fields.AngularRoute"
                    [amplitudeEvent]="'Platform Tiles - ' + tile.Fields.Title"
                  >Learn More</a>
                }
              </td>
            </tr>
          }
        }
        @if (!filteredTiles.length) {
          <p class="text-gray">No results found.</p>
        }
      </tbody>
    </table>
  </section>