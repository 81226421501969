
import { Component, Input, OnInit } from '@angular/core';
import { ResourcePage } from 'src/app/shared/models/resource-page.models';
import { OrderModel } from 'src/app/shared/models/order.models';
import { MatDialog } from '@angular/material/dialog';
import { EnrollmentFormComponent } from 'src/app/portal/modals/enrollment-form/enrollment-form.component';
import { SurveyRenewalModel } from 'src/app/shared/models/surveyRenewal.models';
import { EnrollmentFormApiService } from 'src/app/core/services/http/enrollment-form-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RenewalFormComponent } from 'src/app/portal/modals/renewal-form/renewal-form.component';

@Component({
  selector: 'app-elevate-workflow',
  templateUrl: './elevate-workflow.component.html'
})
export class ElevateWorkflowComponent implements OnInit {

  @Input() resource!: ResourcePage;
  purchased!: OrderModel | undefined;
  intervals: any[] = [];
  @Input() submitted!: { surveyId: number, isEnrolled: boolean }[] | undefined;
  hasPastRenewals = false;
  surveyId!: number | undefined;
  pendingRenewal = false;
  surveyRenewals!: SurveyRenewalModel[];
  hasSubmittedAnyForm = false;

  constructor(
    private dialog: MatDialog,
    private enrollmentFormApiService: EnrollmentFormApiService,
  ) { }

  ngOnInit(): void {
    this.surveyId = this.resource.EnrollmentSurveyData?.SurveyIds[0]; // Uses ElevateId = 2027 and not the Elevate (PreMarch 2019) Id = 2
    this.getAndRefreshSurveyRenewals().subscribe(() => {
      if (this.pendingRenewal) {
        this.openSurveyRenewalDialog();
      }
    });

    if (this.submitted) {
      this.hasSubmittedAnyForm = this.submitted.some(f => f.isEnrolled);
    }
  }

  getAndRefreshSurveyRenewals(): Observable<any> {
    return this.enrollmentFormApiService.getSurveyRenewals(this.surveyId).pipe(map(results => {
      this.surveyRenewals = results;
      const renewal = results.find(r => r.SurveyId === this.surveyId);
      if (renewal) {
        if (renewal.SurveyRenewalStatusId === 1) {
          this.pendingRenewal = true;
        } else {
          this.pendingRenewal = false;
          this.hasPastRenewals = true;
        }
      }
    }));
  }

  openSurveyRenewalDialog(): void {
    const ref = this.dialog.open(RenewalFormComponent, {
      data: {
        SurveyRenewals: this.surveyRenewals,
        FilterBySurveyId: this.surveyId
      }
    });

    ref.afterClosed().subscribe(result => {
      if (result) {
        this.getAndRefreshSurveyRenewals();
      }
    });
  }

  openEnrollmentModal(): void {
    const dialogRef = this.dialog.open(EnrollmentFormComponent, {
      data: {
        Event: this.resource.EnrollmentSurveyData?.Event,
        SurveyId: this.surveyId,
        Disclaimer: this.resource.EnrollmentSurveyData?.Disclaimer
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      this.hasSubmittedAnyForm = res.hasCompletedEnrollment;
    });
  }

  openDocusignLink(url: string): void {
    window.open(url);
  }
}

