<cdk-virtual-scroll-viewport itemSize="58" class="w-full h-full" #virtualScroll @fadeIn>
  <table class="custom-table">
    <thead>
      <tr>
        <td *cdkVirtualFor="let cell of table.Header" class="{{cell.WidthClass}}">
          <span (click)="orderTableBy(cell)" (keypress)="orderTableBy(cell)"
            class="flex items-center cursor-pointer min-h-24 focus:outline-none"
            attr.aria-label="Sort by {{cell.DisplayName}}">
            <span class="mr-5">{{cell.DisplayName}}</span>
            @if (cell === table.OrderByColumn) {
              <span class="material-icons text-blue-dark">
                {{table.AscendingOrder ? 'expand_less' : 'expand_more'}}
              </span>
            }
          </span>
        </td>
      </tr>
    </thead>
    <tbody>
      @for (row of table.Body; track row) {
        <tr id="policyRow" class="z-0 cursor-pointer" aria-label="Go to policy details"
          (click)="openPolicyDetails(row)" (keypress)="openPolicyDetails(row)">
          @if (showAgentColumn) {
            <td>
              @if (row.ActionNeeded) {
                <button mat-button type="button"
                  (click)="openRequirementModal(row)"
                  matTooltip="Action Needed. Click to Resolve"
                  aria-label="Action needed. Click to resolve" class="mr-5 -mt-5 icon-button is-alert is-inverted">
                  <span class="material-icons mi-18 text-orange">
                    warning_amber
                  </span>
                </button>
              }
              {{row.Agent}}
            </td>
          }
          <td>
            @if (row.ActionNeeded && !showAgentColumn) {
              <button mat-button type="button"
                (click)="openRequirementModal(row)"
                matTooltip="Action Needed. Click to Resolve"
                aria-label="Action needed. Click to resolve" class="mr-5 -mt-5 icon-button is-alert is-inverted">
                <span class="material-icons mi-18">
                  warning_amber
                </span>
              </button>
            }
            {{row.Clients}}
          </td>
          <td>
          {{row.Carrier}}</td>
          <td>
            <span class="flex flex-col w-full">
              <span class="font-bold">{{row.Product}}</span>
              <span>{{ row.PolicyNumber ? '(' + row.PolicyNumber + ')' : ''}} {{row.FaceAmount ? (row.FaceAmount | currency) + ' (Face Amount)' : ''}} </span>
            </span>
          </td>
          <td>
            <div class="flex flex-col w-full">
              <span class="font-bold">{{row.Premium | currency}}</span>
            </div>
            <span>
              {{row.Target ? (row.Target | currency) + ' (TargetPrem.)' : ''}}
            </span>
          </td>
          <td>
            @if (!row.ActionNeeded) {
              <div class="flex flex-row">
                <div class="flex flex-col w-full">
                  <span class="font-bold">{{row.PolicyStatus}}</span>
                  <span>{{row.PolicyStatusDate | date: 'MM/dd/yyyy' : 'UTC'}}</span>
                </div>
              </div>
            } @else {
              <div class="flex flex-row items-center font-bold text-orange hover:text-orange-dark"
                (click)="openRequirementModal(row)">
                <span>{{row.PolicyStatus}}</span>
                <i class="ml-10 material-icons mi-18">open_in_new</i>
              </div>
            }
          </td>
        </tr>
      }
      @if (isError) {
        <tr id="policyErrorRow" class="text-red" aria-label="Error getting policies">
          <td colspan="6">Sorry, an error has occurred. Please try again later.</td>
        </tr>
      }
      @else if (!isError && table.Body.length===0) {
        <tr id="policyNoPoliciesRow" class="" aria-label="No policies found"
          >
          <td colspan="6">No policies found.</td>
        </tr>
      }
    </tbody>
  </table>
</cdk-virtual-scroll-viewport>