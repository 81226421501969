export class Table<T> {
  Header: TableHeader<T>[];
  Body: T[] = [];
  OrderByColumn: TableHeader<T>;
  AscendingOrder: boolean;

  constructor(public headers: TableHeader<T>[], public defaultOrderedByColumnIndex: number = 0, public ascendingOrder: boolean = false) {
    this.Header = headers;
    this.OrderByColumn = headers[defaultOrderedByColumnIndex];
    this.AscendingOrder = ascendingOrder;
  }

  orderBody(): T[] {
    const order = this.AscendingOrder ? 1 : -1;
    this.Body = this.Body.sort((a: T, b: T) => this.OrderByColumn.CompareFn ? this.OrderByColumn.CompareFn(a, b, order)
      : this.defaultCompare(this.OrderByColumn.ColumnName[0] as keyof T, a, b, order));
    return this.Body;
  }

  defaultCompare(property: keyof T, a: T, b: T, order: 1|-1): number {
    const valA = a[property] ?? '';
    const valB = b[property] ?? '';
    return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
  }

  setOrderByColumn(column: TableHeader<T>) : void {
    this.OrderByColumn = column;
    this.orderBody();
  }

  setAscendingOrder(direction: boolean): void {
    this.AscendingOrder = direction;
    this.orderBody();
  }
  setBody(rows: T[]):void {
    this.Body = rows;
    this.orderBody();
  }

  switchAscendingOrder(): void {
    this.AscendingOrder = !this.AscendingOrder;
    this.orderBody();
  }

  orderBy(column: TableHeader<T>) : void {
    if (this.OrderByColumn === column) {
      this.switchAscendingOrder();
    } else {
      this.setOrderByColumn(column);
    }
  }
}

export interface TableHeader<T>{
  DisplayName: string;
  ColumnName: string[];
  WidthClass: string;
  CompareFn?: (a: T, b: T, order: 1 | -1) => number;
}