<cdk-virtual-scroll-viewport itemSize="51" class="w-full h-full min-h-0 px-30" #virtualScroll @fadeIn>
  <table class="table-auto custom-table">
    <thead>
      <tr>
        @for (cell of table.Header; track cell) {
          <td
            class="{{cell.WidthClass}}">
            <span (click)="orderTableBy(cell)" (keypress)="orderTableBy(cell)"
              class="flex items-center leading-6 cursor-pointer min-h-24 focus:outline-none"
              attr.aria-label="Sort by {{cell.DisplayName}}">
              <span class="mr-5">{{cell.DisplayName}}</span>
              @if (cell === table.OrderByColumn) {
                <span class="material-icons text-blue-dark">
                  {{table.AscendingOrder ? 'expand_less' : 'expand_more'}}
                </span>
              }
            </span>
          </td>
        }
      </tr>
    </thead>
    <tbody>
      @for (row of table.Body; track row) {
        <tr id="policyRow" class="bg-opacity-25 cursor-pointer" aria-label="Go to policy details"
          (click)="openPolicyDetails(row)">
          <td>
            {{(row.RelatedPeople) | stringToCapitalized}}
          </td>
          <td>
            {{row.FaceAmount | currency: 'USD' : 'symbol' : '1.0-0'}}
          </td>
          <td>
            {{row.State}}
          </td>
          <td class="">
            <span class="font-semibold">{{row.Status}}</span>
          </td>
        </tr>
      }
      @if (isError) {
        <tr id="policyErrorRow" class="" aria-label="Error getting policies">
          <td colspan="6"><span class="text-red">Sorry, an error has occurred. Please try again later.</span></td>
        </tr>
      }
      @if (!isError && table.Body.length===0) {
        <tr id="policyEmptyRow" class="" aria-label="No policies found">
          <td colspan="6">No policies found.</td>
        </tr>
      }
    </tbody>
  </table>
</cdk-virtual-scroll-viewport>