<section class="flex flex-col w-full h-full mx-auto max-w-1280 p-30 gap-y-20">
  <a (click)="goBack()"
    class="mr-auto is-primary is-inverted button is-sm"
    mat-button>
    <i class="material-icons mi-18">west</i>
    <span>Back to list view</span>
  </a>
  <mat-sidenav-container class="h-full card">
    <mat-sidenav-content class="flex flex-col overflow-hidden">
      <section class="flex items-center border-b gap-x-10 p-30 border-gray-lighter">
        <h1 class="mr-auto text-lg font-bold">
          @if (policyDetails) {
            <span>{{policyDetails.Carrier}} - {{policyDetails.Product}}@if (policyDetails.PolicyNumber) {
              <span> - {{policyDetails.PolicyNumber}}</span>
            }</span>
          }
          @else {
            <span>Policy Details</span>
          }
        </h1>
        <button mat-button type="button"
          class="button is-primary is-inverted"
          (click)="policySummary.toggle()">
          <span class="flex items-center justify-center gap-x-5">
            <span>{{policySummary.opened ? 'Hide Summary' : 'Show Summary'}}</span>
            <i class="material-icons mi-18">visibility</i>
          </span>
        </button>
      </section>
      <!-- dynamic http data can be inserted inside this ng-container -->
      @if (!loading) {
        <app-policy-requirements-v2 @fadeIn
          [policyTasks]="policyTasks"
          [policyGuid]="policyGuid"
        class="flex flex-col flex-grow overflow-hidden"></app-policy-requirements-v2>
      }
      @else {
        <img
          @fadeIn
          src="./assets/lottie/static/policy-details-v2.svg" alt="Loading placeholder" class="animate-pulse">
        }
      </mat-sidenav-content>
      <mat-sidenav class="bg-white w-300"
        #policySummary
        mode="side"
        position="end"
        [opened]="true">
        @if (!loading) {
          <app-policy-details-summary
            @fadeIn
            [policyFundings]="policyFundings"
          [policyDetails]="policyDetails"></app-policy-details-summary>
        }
      </mat-sidenav>
    </mat-sidenav-container>
  </section>