<section class="flex flex-col flex-none h-full overflow-hidden">
  @if (!modalView) {
    <div class="flex flex-col flex-none p-10 border-b border-gray-lighter px-30">
      <div class="flex items-center justify-between gap-x-20">
        <mat-slide-toggle (ngModelOptions)="{debounce: 100}"
          [(ngModel)]="showCompleted"
        (ngModelChange)="toggleCompletedTasks()">Show Completed Items</mat-slide-toggle>
        <button (click)="openNewMessageModal(policyGuid)"
          class="button is-sm is-primary"
          type="button"
          mat-button>
          <span class="flex items-center justify-center gap-x-5">
            <span>New Message</span>
            <i class="material-icons mi-18">send</i>
          </span>
        </button>
      </div>
    </div>
  }
  <section class="flex-grow overflow-auto bg-gradient-to-b from-gray-lightest to-white">
    <div class="flex flex-col w-full h-full p-20 mx-auto gap-y-30 max-w-640">
      <!-- Requirements/checklist/updates/ view. Includes Action Needed -->
      @if (!loading && !error.hasError) {
        <mat-accordion multi
          @fadeIn
          >
          @if (actionNeededTasks.length) {
            <mat-expansion-panel
              @fadeIn
              #expansionPanel
              class="my-0 bg-transparent shadow-none text-gray-dark"
              hideToggle
              expanded>
              <mat-expansion-panel-header class="h-48 px-10">
                <div class="flex items-center justify-between w-full px-0 gap-x-20">
                  <h2 class="font-bold">Action Needed ({{actionNeededTasks.length}})</h2>
                  <span class="flex items-center justify-center gap-x-5 text-blue-dark">
                    <span class="text-xs font-bold uppercase">{{expansionPanel.expanded ? 'Collapse' : 'Expand'}}</span>
                    <i class="material-icons mi-18">{{expansionPanel.expanded ? 'unfold_less' : 'unfold_more'}}</i>
                  </span>
                </div>
              </mat-expansion-panel-header>
              <ul class="flex flex-col p-10">
                @for (subcat of actionNeededTasks; track subcat; let first = $first; let last = $last; let i = $index) {
                  <button role="button" type="button"
                    matTooltip="View history"
                    (click)="openUpdatesHistoryModal(subcat)"
                    @fadeIn
                    class="flex h-auto p-20 font-normal leading-normal text-left normal-case rounded-none card gap-x-10 focus:ring-0 hover:bg-opacity-25"
                    [ngClass]="{'rounded-t' : first, 'rounded-b': last, 'bg-orange-lightest hover:bg-orange-lighter': subcat.ActionNeeded, 'border-b border-gray-lighter' : !last}">
                    <div class="flex flex-col flex-grow gap-y-10">
                      <h3 class="text-sm font-bold" [ngClass]="{'text-orange': subcat.ActionNeeded}">{{subcat.TaskName}}</h3>
                      <p class="text-sm">{{subcat.LatestCaseNote.Note}}</p>
                    </div>
                    <div class="flex flex-col flex-none gap-y-10">
                      <div class="text-sm text-right text-gray">{{subcat.ModifiedOn | date: 'MM/dd/yy'}}</div>
                      <div class="text-sm font-bold text-right text-blue-dark">{{subcat.Notes.length}} Update(s)</div>
                    </div>
                  </button>
                }
              </ul>
            </mat-expansion-panel>
          }
          @if (filteredPolicyUpdates.length) {
            @for (cat of filteredPolicyUpdates; track cat) {
              <mat-expansion-panel @fadeIn
                #expansionPanel class="my-0 bg-transparent shadow-none text-gray-dark"
                hideToggle
                expanded>
                <mat-expansion-panel-header class="h-48 px-10">
                  <div class="flex items-center justify-between w-full px-0 gap-x-20">
                    <h2 class="font-bold">{{cat.categoryName}}</h2>
                    <span class="flex items-center justify-center gap-x-5 text-blue-dark">
                      <span class="text-xs font-bold uppercase">{{expansionPanel.expanded ? 'Collapse' : 'Expand'}}</span>
                      <i class="material-icons mi-18">{{expansionPanel.expanded ? 'unfold_less' : 'unfold_more'}}</i>
                    </span>
                  </div>
                </mat-expansion-panel-header>
                <ul class="flex flex-col p-10">
                  @for (subcat of cat.subcategories; track subcat; let first = $first; let last = $last; let i = $index) {
                    <button role="button" type="button"
                      matTooltip="View history"
                      (click)="openUpdatesHistoryModal(subcat)"
                      @fadeIn
                      class="flex h-auto p-20 font-normal leading-normal text-left normal-case rounded-none card gap-x-10 focus:ring-0 hover:bg-opacity-25"
                      [ngClass]="{'rounded-t' : first, 'rounded-b': last, 'bg-orange-lightest hover:bg-orange-lighter': subcat.ActionNeeded, 'border-b border-gray-lighter' : !last}">
                      <div class="flex flex-col flex-grow gap-y-10">
                        <h3 class="text-sm font-bold" [ngClass]="{'text-orange': subcat.ActionNeeded}">{{subcat.TaskName}}</h3>
                        <p class="text-sm">{{subcat.LatestCaseNote.Note}}</p>
                      </div>
                      <div class="flex flex-col flex-none gap-y-10">
                        <div class="text-sm text-right text-gray">{{subcat.ModifiedOn | date: 'MM/dd/yy'}}</div>
                        <div class="text-sm font-bold text-right text-blue-dark">{{subcat.Notes.length}} Update(s)</div>
                      </div>
                    </button>
                  }
                </ul>
              </mat-expansion-panel>
            }
          }
        </mat-accordion>
      }

      <!-- No applicable checklist items/updates -->
      @if (!loading && !filteredPolicyUpdates.length && !actionNeededTasks.length && !error.hasError) {
        <app-message-notification @fadeIn
        >You have no {{showCompleted ? 'completed items' : 'case updates'}} at this time.</app-message-notification>
      }

      <!-- Error message box -->
      @if (!loading && error.hasError) {
        <app-message-notification @fadeIn
        [panelConfig]="messageConfig">{{error.message}}</app-message-notification>
      }
      <!-- Loading -->
      @if (loading) {
        <div class="relative p-20 m-auto loader-wrap">
          <div class="loader-sm is-blue"></div>
        </div>
      }
    </div>
  </section>
</section>