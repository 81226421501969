<section class="flex flex-col w-full h-full">
  <section class="flex flex-col m-auto card p-30 xs:w-460">
    @if (!error) {
      <div class="flex flex-col items-center justify-center m-20 text-green">
        <span class="m-10 material-icons mi-36 text-green">check_circle</span>
        <h2 class="m-10 text-lg font-bold text-center">
          Thank you for signing the {{title}} agreement.
        </h2>
      </div>
    }
    @else {
      <div class="flex flex-col items-center justify-center m-20">
        <span class="m-10 material-icons mi-36 text-orange">warning</span>
        <div class="m-10 text-lg text-center">
          <p>Please be advised that the signature has not been completed for the {{title}} agreement.</p>
          <br>
          <p>If you feel this is in error, please contact your marketing consultant. Thank you.</p>
        </div>
      </div>
    }
    <p class="m-10 text-center">You may close this window and return to the Portal.</p>
  </section>
</section>