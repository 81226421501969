import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wealthbox-auth-complete',
  templateUrl: './wealthbox-auth-complete.component.html'
})

export class WealthboxAuthCompleteComponent implements OnInit {
  ngOnInit(): void {
    window.close();
  }

}
