import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { WealthboxApiService } from 'src/app/core/services/http/wealthbox-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { ManageIntegrationsEnum } from 'src/app/shared/enums/integrations.enum';
import { ManageIntegrationResModel } from 'src/app/shared/models/intergrations.models';
import { ManageIntegrationsModalComponent } from '../../manage-integrations-modal.component';

@Component({
  selector: 'app-wealthbox-integration',
  templateUrl: './wealthbox-integration.template.html',
})
export class WealthboxIntegrationComponent implements OnDestroy {
  isWealthboxConnected = this.sessionStore.IsWealthboxUser;
  isSubmitting = false;
  closeTimer$ = new Subject<boolean>();

  constructor(
    private wealthboxApiService: WealthboxApiService,
    private sessionStore: SessionStoreService,
    private dialogRef: MatDialogRef<ManageIntegrationsModalComponent, ManageIntegrationResModel>
  ) { }

  ngOnDestroy(): void {
    this.closeTimer$.next();
  }

  deactivateWealthbox(): void {
    this.isSubmitting = true;
    this.wealthboxApiService.removeAuthentication().subscribe(() => {
      this.isWealthboxConnected = false;
      this.sessionStore.setUserWealthboxStatus(this.isWealthboxConnected);
      this.isSubmitting = false;
      this.dialogRef.close({
        isConnected: false,
        crmIntegration: ManageIntegrationsEnum.Wealthbox,
      });
    });
  }

  activateWealthbox(): void {
    this.isSubmitting = true;
    window.open('/api/Wealthbox/Sso/', '_blank', 'width=800,height=650');
    this.startPolling();
  }

  startPolling(requestInterval = 1000): void {
    timer(0, requestInterval)
      .pipe(
        switchMap(() => this.wealthboxApiService.isConnected()),
        takeUntil(this.closeTimer$)
      )
      .subscribe((res) => {
        this.isWealthboxConnected = res;
        if (this.isWealthboxConnected) {
          this.sessionStore.setUserWealthboxStatus(this.isWealthboxConnected);
          this.isSubmitting = false;
          this.closeTimer$.next();
          this.dialogRef.close({
            isConnected: true,
            crmIntegration: ManageIntegrationsEnum.Wealthbox,
          });
        }
      });
  }
}
