<!-- Modal view -->
<!-- Upload Form If has Case Status -->
@if (isModalView && !loading && (showCaseStatusView || showPolicyStageView)) {
  <form class="flex flex-col h-full"
        [formGroup]="agentResponseForm" (ngSubmit)="resolveRequirement(caseStatus ? caseStatus.TaskId : undefined)" @fadeIn>
    <section class="flex items-center flex-none p-20 border-b border-solid border-gray-lighter">
      @if (requirementModalHeader) {
        <h2 class="m-10 text-lg font-bold">{{requirementModalHeader!.Carrier}} -
          {{requirementModalHeader!.Product}}</h2>
      }
      @else {
      <h2 class="m-10 text-lg font-bold">Outstanding Requirement</h2>
      }
      <button mat-button tabindex="-1" type="button" mat-dialog-close
              class="m-10 ml-auto icon-button is-primary is-inverted"><i class="material-icons">close</i></button>
    </section>
    @if (loading) {
      <div class="relative flex items-center justify-center h-full p-20 m-auto loader-wrap">
        <div class="loader-sm is-blue"></div>
      </div>
    }
    @else if (!loading && (caseStatus || policyStage)) {
    <section class="flex flex-col flex-auto p-20 overflow-auto">
      <div class="flex flex-col items-center m-10 text-center" [ngClass]="{'text-orange': caseStatus && caseStatus.ActionNeeded}">
        @if (caseStatus && caseStatus.ActionNeeded) {
          <i class="mb-10 fig-48px fig-alert-triangle"></i>
        }
        <h2 class="mb-10 text-xl font-bold">{{caseStatus ? caseStatus.TaskName : policyStage}}</h2>
        <span class="mb-5 text-sm font-bold uppercase label text-gray-dark">{{latestNote ? (latestNote.CreatedOn | date:
          'MMM dd,yyyy | hh:mma') : (policyStageDate | date: 'MMM dd,yyyy | hh:mma' : 'UTC') }}</span>
        @if (caseStatus && latestNote && latestNote.CreatedBy) {
          <span class="text-xs font-bold uppercase label">By {{latestNote?.CreatedBy}}</span>
        }
        <button type="button" mat-button class="mx-auto mt-20 button is-sm is-primary is-inverted" mat-dialog-close
                [routerLink]="[detailsUrl]">
          View policy details
        </button>
      </div>
      <div class="flex flex-col items-center flex-shrink-0 p-20 m-10 rounded min-h-256 bg-blue-lightest">
        @if (latestNote || policyStage) {
          <p class="my-auto break-words whitespace-pre-line">
            {{latestNote ? latestNote.Note : 'Policy is ' + policyStage + ' as of ' + (policyStageDate | date: 'MMM dd,yyyy' : 'UTC' )}}
          </p>
        }
        @else if (!latestNote && !policyStage) {
          <p class="my-auto break-words whitespace-pre-line">
            Policy status is unavailable
          </p>
        }
      </div>
      @if (fileArray.value.length) {
        <ul class="flex flex-col p-20 m-10 gap-y-20">
          @for (item of fileArray.value; track item; let i = $index) {
            <li class="flex items-center mb-10 text-sm font-medium">
              <span class="mr-auto">{{item.file.name}}</span>
              <button type="button" mat-button class="ml-10 icon-button is-waring is-inverted is-sm"
                      (click)="removeFile(i)" aria-label="Remove file" matTooltip="Remove File">
                <span class="material-icons mi-18">
                  close
                </span>
              </button>
            </li>
          }
        </ul>
      }
    </section>
    }
    <section class="flex flex-col flex-none border-t border-solid p-30 border-gray-lighter" [hidden]="hideResponseSubmission">
      <div class="flex items-center flex-none">
        <button mat-button type="button" aria-label="Attach Files"
                class="relative m-10 icon-button is-primary is-inverted text-gray">
          <span class="material-icons">
            attach_file
          </span>
          <input type="file" multiple accept=".pdf,.png,.jpg,.jpeg" (change)="onFileUpload($event)"
                class="absolute top-0 left-0 w-full h-full opacity-0" />
          @if (fileArray.value.length) {
            <span
                  class="absolute top-0 right-0 flex items-center justify-center -m-5 text-xs leading-none text-white rounded-full w-18 h-18 bg-blue">
              <span>{{fileArray.value.length}}</span>
            </span>
          }
        </button>
        <input formControlName="message" type="text" class="flex-grow m-10 input is-inverted bg-gray-lightest"
              placeholder="Enter a message" />
        <button mat-button type="submit"
                [disabled]="(caseStatus && !formMessage.value && !fileArray.value.length) || (!caseStatus && !fileArray.value.length)  || isUploading"
                aria-label="Send message" class="m-10 is-primary">
          <span class="material-icons">
            send
          </span>
        </button>
      </div>
      @if (showFeedback) {
        <div class="flex-none p-10 m-10 rounded" [class]="isSuccess ? 'text-green bg-green-lightest': 'text-red bg-red-lightest'" @fadeIn>{{feedbackMessage}}</div>
      }
    </section>
  </form>
}

<!-- Inline View -->
@if (!isModalView && !loading && (showCaseStatusView || showPolicyStageView)) {
  <section class="flex flex-col" @fadeIn>
    @if (loading) {
      <div class="relative flex items-center justify-center p-20 m-auto loader-wrap">
        <div class="loader-sm is-blue"></div>
      </div>
    }
    <div class="flex flex-col p-10 md:px-40">
      <div class="flex flex-col m-10 mt-0 mb-40 text-center md:m-20" [ngClass]="{'text-orange': caseStatus && caseStatus.ActionNeeded}">
        @if (caseStatus && caseStatus.ActionNeeded) {
          <i class="mb-10 fig-48px fig-alert-triangle"></i>
        }
        <h2 class="mb-10 text-xl font-bold">{{caseStatus ? caseStatus.TaskName : policyStage}}</h2>
        <span class="mb-5 text-sm font-bold uppercase label text-gray-dark">{{latestNote ? (latestNote.CreatedOn | date: 'MMM dd,yyyy | hh:mma' : 'UTC') : (policyStageDate | date: 'MMM dd,yyyy | hh:mma' : 'UTC') }}</span>
        @if (caseStatus && latestNote && latestNote.CreatedBy) {
          <span class="text-xs font-bold uppercase label">By
            {{latestNote?.CreatedBy}}</span>
        }
        @if (caseStatus && caseStatus.Notes.length && !isModalView) {
          <button
                  mat-button
                  class="mx-auto mt-10 button is-primary is-inverted"
                  type="button"
                  (click)="openUpdatesHistoryModal()">View Past Updates</button>
        }
      </div>
      <!-- Upload Form If has Case Status -->
      <form class="flex flex-col flex-none m-10 text-center md:m-20 min-h-256"
            [formGroup]="agentResponseForm" (ngSubmit)="resolveRequirement(caseStatus ? caseStatus.TaskId : undefined)">
        <div
            class="flex flex-col flex-grow p-20 border border-solid rounded-t-lg bg-opacity-10 bg-blue-lighter border-gray-lighter">
          @if (latestNote || policyStage) {
            <span class="my-auto break-words whitespace-pre-line">{{latestNote ? latestNote.Note : 'Policy is ' +
              policyStage + ' as of ' + (policyStageDate | date: 'MMM dd,yyyy' : 'UTC' )}}</span>
          }
          @else if (!latestNote && !policyStage) {
            <span class="my-auto break-words whitespace-pre-line">Policy status unavailable</span>
          }
        </div>
        <div
            class="flex items-start flex-none mb-10 border-b border-l border-r border-solid rounded-b-lg border-gray-lighter" [ngClass]="{'hidden': hideResponseSubmission}">
          <button mat-button type="button" aria-label="Attach files"
                  class="relative m-10 mr-0 icon-button is-primary is-inverted text-gray">
            <span class="material-icons">
              attach_file
            </span>
            <input type="file" multiple accept=".pdf,.png,.jpg,.jpeg" (change)="onFileUpload($event)"
                  class="absolute top-0 left-0 w-full h-full opacity-0" />
            @if (fileArray.value.length) {
              <span
                    class="absolute top-0 right-0 flex items-center justify-center -m-5 text-xs leading-none text-white rounded-full w-18 h-18 bg-blue">
                <span>{{fileArray.value.length}}</span>
              </span>
            }
          </button>
          <input formControlName="message" type="text" class="flex-grow m-10 input is-inverted"
                placeholder="Enter a message" />
          <button mat-button type="submit"
                  [disabled]="(caseStatus && !formMessage.value && !fileArray.value.length) || (!caseStatus && !fileArray.value.length)  || isUploading"
                  aria-label="Send message" class="m-10 ml-0 icon-button is-primary is-inverted">
            <span class="material-icons">
              send
            </span>
          </button>
        </div>
        @if (fileArray.value.length) {
          <ul class="flex flex-col p-10 border border-solid rounded-lg border-gray-lighter gap-y-10">
            @for (item of fileArray.value; track item; let i = $index) {
              <li class="flex items-center text-sm font-medium">
                <span class="mr-auto">{{item.file.name}}</span>
                <button type="button" mat-button class="ml-10 icon-button is-warning is-inverted is-sm"
                        (click)="removeFile(i)" aria-label="Remove file" matTooltip="Remove File">
                  <span class="material-icons mi-18">
                    close
                  </span>
                </button>
              </li>
            }
          </ul>
        }
      </form>
      @if (showFeedback) {
        <div class="p-10 m-10 mt-0 rounded md:mt-0 md:m-20" [class]="isSuccess ? 'text-green bg-green-lightest': 'text-red bg-red-lightest'" @fadeIn>{{feedbackMessage}}</div>
      }
    </div>
  </section>
}

<!-- TODO: For life/ltc, may need refactoring -->
@if (showRequirementView && !showCaseStatusView && !showPolicyStageView) {
  <section [ngClass]="{'p-20 gap-y-30' : !isModalView}" class="flex flex-col h-full overflow-hidden" @fadeIn>
    <div class="flex" [class]="isModalView ? 'flex-col' : 'justify-between'">
      <div class="flex items-center flex-shrink-0" [ngClass]="{'p-30 border-b border-solid border-gray-lighter': isModalView}">
        <h3 class="text-lg font-bold">Case Requirements</h3>
        @if (isModalView) {
          <button mat-button tabindex="-1" type="button" mat-dialog-close
                  class="ml-auto icon-button is-primary is-inverted"><i class="material-icons">close</i></button>
        }
      </div>
      <div class="flex justify-end flex-none" [ngClass]="{'py-10 border-b border-solid px-30 border-gray-lighter' : isModalView}">
        <div class="flex items-center flex-none ml-auto gap-x-10">
          <span class="text-sm text-gray">Filter by Status: </span>
          <mat-button-toggle-group [hideSingleSelectionIndicator]="true" class="is-sm" [(ngModel)]="selectedRequirementStatus" (ngModelChange)="filterRequirementByStatus(selectedRequirementStatus.ID)">
            @for (item of requirementStatusList; track item) {
              <mat-button-toggle class="w-96" [value]="item">{{item.Name}}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
    <ul class="flex flex-col gap-y-10" [ngClass]="{'flex-grow overflow-auto p-30': isModalView}">
      @for (item of filteredRequirements | orderBy: ['-ActionNeeded', '-ModifiedOn']; track item) {
        <li
            @fadeIn
            class="flex flex-col p-20 rounded gap-y-10"
            [class]="item.ActionNeeded ? 'bg-orange-lightest' : 'bg-gray-lightest'">
          <div class="flex">
            <i class="flex-shrink-0 mt-4 mr-10 material-icons mi-18"
              [class]="item.ActionNeeded ? 'text-orange' : 'text-blue-light'">{{item.ActionNeeded ? 'warning' :
              'info'}}</i>
            <div class="flex-grow">
              <h3 class="font-bold" [class]="item.ActionNeeded ? 'text-orange' : ''">{{item.TaskName}}</h3>
              <p class="text-sm text-gray">{{item.ModifiedOn | date}}</p>
            </div>
            @if (item.Notes.length) {
              <button mat-button
                      class="ml-20 button is-primary is-sm is-inverted" type="button" (click)="showCaseStatusNotes(item.Notes)">View Past Updates</button>
            }
          </div>
          <div class="flex flex-col ml-30 gap-y-10">
            @for (note of item.Notes | orderBy: '-ModifiedOn'; track note; let first = $first) {
              @if (first) {
                <div class="flex flex-shrink-0">
                  <p class="my-auto mr-20 break-words whitespace-pre-line">
                    {{note.Note}}
                  </p>
                  <span class="flex flex-col flex-shrink-0 ml-auto text-sm text-right text-gray w-96">
                    <span>{{note.CreatedBy}}</span>
                    <span>{{note.ModifiedOn | date}}</span>
                  </span>
                </div>
              }
            }
          </div>
          <div [hidden]="hideResponseSubmission">
            <form #form="ngForm" (ngSubmit)="resolveLifeLtcRequirement(item, form)" class="flex mt-10 gap-x-10">
              <button mat-button type="button" aria-label="Attach files" [disabled]="isUploading"
                      class="relative icon-button is-primary is-inverted text-gray">
                <span class="material-icons">
                  attach_file
                </span>
                <input type="file" multiple accept=".pdf,.png,.jpg,.jpeg" (change)="onFileUpload($event, item)"
                      name="FileArray" class="absolute top-0 left-0 w-full h-full opacity-0" />
                @if (item.FileArray.length) {
                  <span
                        class="absolute top-0 right-0 flex items-center justify-center -m-5 text-xs leading-none text-white rounded-full w-18 h-18 bg-blue">
                    <span>{{item.FileArray.length}}</span>
                  </span>
                }
              </button>
              <input type="text" class="flex-grow input" name="Message" [(ngModel)]="item.Message"
                    [required]="item.FileArray.length === 0 ? true : false" />
              <button mat-button type="submit" class="button is-primary" [disabled]="form.invalid || isUploading">Send Message</button>
            </form>
          </div>
          @if (item.FileArray.length) {
            <ul class="flex flex-wrap border-t border-solid border-gray-lighter">
              @for (file of item.FileArray; track file; let fileIndex = $index) {
                <li class="flex items-center w-1/3 p-10 text-sm">
                  <span class="mr-auto">{{file.name}}</span>
                  <button type="button" mat-button class="ml-10 icon-button is-warning is-inverted is-sm" [disabled]="isUploading"
                          (click)="removeFile(fileIndex, item)" aria-label="Remove file" matTooltip="Remove File">
                    <span class="material-icons mi-18">
                      close
                    </span>
                  </button>
                </li>
              }
            </ul>
          }
          @if (form.submitted && showFeedback) {
            <div class="p-10 rounded" [class]="isSuccess ? 'text-green bg-green-lightest': 'text-red bg-red-lightest'" @fadeIn>{{feedbackMessage}}</div>
          }
        </li>
      }
    </ul>
  </section>
}