
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionService } from '../../auth/permission.service';
import { LifeInsuranceResources, LifeInsuranceResourcesCategory, LifeInsuranceResourcesSubCategory } from 'src/app/shared/models/life-insurance-resources.models';
// import { FigPlatformCategory, FigPlatformTile } from 'src/app/shared/models/figplatform.models';

@Injectable({
  providedIn: 'root'
})
export class LifeInsuranceResourcesApiService {

  constructor(
    private http: HttpClient,
    private permissionService: PermissionService
  ) { }

  getCategories(): Observable<LifeInsuranceResourcesCategory[]> {
    return this.http.get<LifeInsuranceResourcesCategory[]>('/api/AirTable/LifeInsuranceResources/Categories')
      .pipe(
        map(x => x.filter(category => this.hasRequiredPermission(category)))
      );
  }

  getSubCategories(): Observable<LifeInsuranceResourcesSubCategory[]> {
    return this.http.get<LifeInsuranceResourcesSubCategory[]>('/api/AirTable/LifeInsuranceResources/SubCategories');
  }

  getUserPermittedTiles(): Observable<LifeInsuranceResources[]> {
    return this.http.get<LifeInsuranceResources[]>('/api/AirTable/LifeInsuranceResources/Tiles')
      .pipe(
        map(x => x.filter(tile => this.hasRequiredPermission(tile)))
      );
  }


  hasRequiredPermission(resource: LifeInsuranceResources|LifeInsuranceResourcesCategory): boolean {
    if (resource.Fields.ViewPermission === null || this.permissionService.hasAllPermissions(resource.Fields.ViewPermission))
      return true;
    else
      return false;
  }
}