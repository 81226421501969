import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { getAverage } from 'src/app/shared/helpers/average.helper';
import { getSum } from 'src/app/shared/helpers/sum.helper';
import { InformalPolicy, PolicyList, PolicyListTypeEnum } from 'src/app/shared/models/policy.models';
import { fadeIn } from 'src/app/animations';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { InformalTablePolicy } from './models/informalTablePolicy.model';
import { Subscription } from 'rxjs';
import { hasAll } from 'src/app/shared/helpers/search.helpers';
import { downloadFile } from 'src/app/shared/helpers/filedownloader.helper';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { BusinessRouteParamReportTypeEnum } from '../../enums/policy-v2.enums';

@Component({
  selector: 'app-informal-policy-list',
  templateUrl: './informal-policy-list.component.html',
  animations: [fadeIn]
})
export class InformalPolicyListComponent implements OnInit, PolicyList {
  allPolicies: InformalTablePolicy[] = [];
  filteredPolicies: InformalTablePolicy[] = [];
  isError = false;
  isLoading = false;
  getSum = getSum;
  getAverage = getAverage;
  reportType?: BusinessRouteParamReportTypeEnum;
  searchText: string | null = null;
  policyListType = PolicyListTypeEnum.Informal;
  isExporting = false;
  routeTitle?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private amplitudeEventService: AmplitudeEventService,
    private reportsApiService: ReportsApiService,
    private snackbar: SnackbarService,
  ) {
    this.routeTitle = this.activatedRoute.snapshot.data['title'] as string | undefined;
  }

  ngOnInit(): void {
    this.getPolicies();
  }

  searchTextFilter(policy: InformalPolicy): boolean {
    if (!this.searchText) return true;
    return hasAll(policy, this.searchText);
  }

  setSearchText(searchText: string | null): void {
    this.searchText = searchText;
    this.setTable();
  }


  getPolicies(): Subscription {
    this.isLoading = true;

    return this.reportsApiService.getInformalPolicies()
      .subscribe({
        next: policies => {
          this.allPolicies = policies;
          this.amplitudeEventService.logBusinessReporting(this.reportType || 'undefined', this.policyListType, this.allPolicies.length);
          this.setTable();
          this.isError = false;
        },
        error: () => {
          this.isError = true;
          this.snackbar.openSnackbar('Sorry, an error has occurred. Please try again later.', ToastClassEnum.warning);
        }
      }).add(() => this.isLoading = false);
  }

  setTable(): void {
    this.filteredPolicies = this.getFilteredPolicies();
  }

  getFilteredPolicies(): InformalTablePolicy[] {
    return this.allPolicies.filter(policy => {
      return this.searchTextFilter(policy);
    });
  }

  export(): void {
    if (this.filteredPolicies.length === 0) {
      this.snackbar.openSnackbar('No policies. Please select different filters.', ToastClassEnum.default);
    }
    this.reportsApiService.exportInformalPolicies().subscribe({
      next: blob => {
        downloadFile(blob, 'PolicyData.xlsx');
        this.snackbar.closeSnackbar();
      },
      error: () => {
        this.snackbar.openSnackbar('Sorry, an error has occurred. Please try again later.', ToastClassEnum.warning);
      }
    }).add(() => {
      this.isExporting = false;
    });
  }


  // Not available for this type of Policy List
  updateReportType(): void {
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as BusinessRouteParamReportTypeEnum;
    this.getPolicies();
  }

  // This needs to be on the class but can remain empty here since there's no sidenav on this page
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleSideNavMenu(): void {}
}
