import orderBy from 'lodash-es/orderBy';
import { Component, OnInit } from '@angular/core';
import { LeaderboardApiService } from 'src/app/core/services/http/leaderboard-api.service';
import { Leaderboard } from 'src/app/shared/models/leaderboard.model';
import { fadeInOut, fadeIn, slideInOut, routerTransition } from 'src/app/animations';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  animations: [fadeInOut, fadeIn, slideInOut, routerTransition],
})
export class LeaderboardComponent implements OnInit {
  activeLeaderboard!: Leaderboard[];
  activeSubCategory = 'Overall';
  disclaimer!: string;
  filterByGroup = false;
  leaderboards!: Leaderboard[];
  loading = false;
  orderBy = orderBy;
  orderByField = '';
  reverseSort = true;
  readonly subCategories: string[] = ['Overall', 'Annuity', 'Life', 'Long-Term Care'];

  constructor(private leaderboardApiService: LeaderboardApiService) { }

  ngOnInit(): void {
    this.loading = true;
    this.leaderboardApiService.get()
      .subscribe(res => {
        this.activeLeaderboard = orderBy(res.Leaderboards.filter(x => !x.Name.includes('Office') && x.Name.includes(this.activeSubCategory)), ['Index']) as Leaderboard[];
        this.leaderboards = res.Leaderboards;
        this.disclaimer = res.Disclaimer;
      })
      .add(() => this.loading = false);
  }

  toggleSort(name: string): void {
    if (this.orderByField === name) this.reverseSort = !this.reverseSort;
    else this.orderByField = name;
  }

  filterLeaderboard(filterByGroup: boolean, activeSubCategory: string): void {
    this.filterByGroup = filterByGroup;
    this.activeSubCategory = activeSubCategory;
    this.activeLeaderboard = filterByGroup ? orderBy(this.leaderboards.filter(x => x.Name.includes('Office') && x.Name.includes(activeSubCategory)), ['Index']) as Leaderboard[] : orderBy(this.leaderboards.filter(x => !x.Name.includes('Office') && x.Name.includes(activeSubCategory)), ['Index']) as Leaderboard[];
  }
}
