<cdk-virtual-scroll-viewport itemSize="51" class="w-full h-full min-h-0 px-30" #virtualScroll @fadeIn>
  <table class="w-full custom-table cpc-policy-table">
    <thead>
      <tr>
        @for (cell of table.Header; track cell) {
          <td class="{{cell.WidthClass}}">
            <span (click)="orderTableBy(cell)" (keypress)="orderTableBy(cell)" class="flex items-center cursor-pointer min-h-24 focus:outline-none"
              attr.aria-label="Sort by {{cell.DisplayName}}">
              <span class="mr-5" [ngClass]="{'text-right flex-grow': cell.DisplayName === CpcPolicyPropertyNameEnum.Premium || cell.DisplayName === CpcPolicyPropertyNameEnum.CPCs}">{{cell.DisplayName}}</span>
              @if (cell === table.OrderByColumn) {
                <span class="material-icons text-blue-dark">
                  {{table.AscendingOrder ? 'expand_less' : 'expand_more'}}
                </span>
              }
            </span>
          </td>
        }
      </tr>
    </thead>
    <tbody>
      @if (!isError) {
        @for (row of table.Body; track row) {
          <tr id="policyRow" class=""
            ><!--
            [ngClass]="{'status-pending': row.StatementStatus === CpcPolicyStatusEnums.Pending}"-->
            @if (showAgentColumn) {
              <td>
                {{row.AgentName}}
              </td>
            }
            <td class="font-bold" [ngClass]="{'text-blue-dark': row.StatementStatus === CpcPolicyStatusEnums.Pending}"
              matTooltip="TBD indicates policy has been placed inforce, however, FIG has yet to receive payment from the carrier. Once payment has been received, the premium and CPC may be updated."
              [matTooltipDisabled]="!!row.StatementDate">
              {{row.StatementDate ? (row.StatementDate | date: 'MM/dd/yyyy' : 'UTC') : 'TBD'}}
            </td>
            <td>
              {{row.ClientName}}
            </td>
            <td>
              {{row.Carrier}}
            </td>
            <td>
              {{ row.PolicyNumber }}
            </td>
            <td>
              <div class="flex flex-col w-full">
                <span class="font-bold text-right">{{row.Premium | currency}}</span>
              </div>
            </td>
            <td class="text-right">
              {{row.CPCs | number: '1.2-2'}}
            </td>
          </tr>
        }
      }
      @if (isError) {
        <tr id="policyErrorRow" class="" aria-label="Error getting policies">
          <td colspan="8"><span class="text-red">Sorry, an error has occurred. Please
          try again later.</span></td>
        </tr>
      }
      @if (!table.Body.length && !isError) {
        <tr>
          <td colspan="100" class="p-20">
            No CPC policies found.
          </td>
        </tr>
      }
    </tbody>
  </table>
</cdk-virtual-scroll-viewport>