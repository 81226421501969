import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SupportComponent } from 'src/app/portal/modals/support/support.component';

@Component({
  selector: 'app-wealthbox-auth-error',
  templateUrl: './wealthbox-auth-error.component.html'
})
export class WealthboxAuthErrorComponent {
  constructor(
    private dialog: MatDialog,
  ) {

  }
  openSupportModal(): void {
    this.dialog.open(SupportComponent, {
      height: '74rem',
    });
  }
}
