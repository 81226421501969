import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderModel } from 'src/app/shared/models/order.models';
import { PurchaseApiService } from 'src/app/core/services/http/purchase-api.service';

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
})
export class OrderCompleteComponent implements OnInit {

  order!: OrderModel;


  constructor(private activatedRoute: ActivatedRoute, private purchaseApiService: PurchaseApiService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const orderId = params['orderId'];
      this.purchaseApiService.getOrder(orderId).subscribe(result => {
        this.order = result;
      });
    });
  }


  openDocusignLink(): void {
    window.open(this.order.MarketingStrategyDocusignUrl);
  }

}

